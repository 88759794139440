import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators  } from '@angular/forms';
import { ServicedataService , AuthService} from './../../services/service.index';
import Swal from 'sweetalert2';
// import { Crespuestas, Catrespuestas } from '../../model/usuario.model';
import * as moment from 'moment';
import { Catrespuestas } from 'src/app/model/usuario.model';



@Component({
  selector: 'app-captura',
  templateUrl: './captura.component.html',
  styleUrls: ['./captura.component.css']
})
export class CapturaComponent implements OnInit {
  txtref = this.dataservice.foliocaptu ;
  forma: FormGroup;
  formacaptura: FormGroup;
  estatussalud = '';
  textcomen = '';
  entrega = '';
  Sinexito = '';
  textrecibe = '';
  estatusfecha = '';
  lositem = '';

  constructor(public activatedRoute: ActivatedRoute,
              public dataservice: ServicedataService,
              public sercli: AuthService,
              private router: Router  ) {

    this.forma = new FormGroup({
      fref: new  FormControl( null , [
        Validators.minLength(5), Validators.pattern('[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9 ,.#]*')])
       }  );

    this.formacaptura = new FormGroup({
          estatussalud: new FormControl( null , [Validators.required ]),
          textcomen: new FormControl( null , [Validators.maxLength(250),
               Validators.pattern('[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9 ,.#_&]*')]),
          textrecibe: new FormControl( null , [Validators.maxLength(250), Validators.pattern('[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9 ,.#_&]*')]),
          estatusfecha: new FormControl( null , [Validators.required ])
                });
              }

  ngOnInit() {
  }


  onClickzoom(id: string) {
    this.dataservice.numid = id ;
    this.router.navigate(['/zoom']);
  }



async onClickdetalle2() {
    if (this.forma.invalid) { return; }

    this.dataservice.foliocaptu = this.forma.value.fref;

    Swal.fire({
      title: 'Espere',
      allowOutsideClick: false,
      icon: 'info'
    });
    Swal.showLoading();

    const valido = await  this.dataservice.seguimientobuscar(this.dataservice.foliocaptu );
    Swal.close();

    switch ( valido ) {
      case true:
        if (this.dataservice.lasfilacaptura[0].macrodescripcion.toLocaleLowerCase() === 'entregada' ) {
          Swal.fire({
            text: 'Entregada, imposible modificar',
            icon: 'warning',
            confirmButtonText: 'Cerrar'
          }).then((result) => {
          });
          this.dataservice.lasfilacaptura = [];
        }
        break;
      case 'No hay datos':
        Swal.fire({
          text: 'No hay coincidencias',
          icon: 'warning',
          confirmButtonText: 'Cerrar'
        }).then((result) => {
        });
        break;
      default:
          Swal.fire({
            text: String(valido),
            icon: 'warning',
            confirmButtonText: 'Cerrar'
          }).then((result) => {
          });
          break;
    }

  }


  async Procesarsalud() {
    let erroform = '';
    let txtcomentarios = '';
    let txtObservacionEntrega = '';


    switch (this.estatussalud) {
      case '15':
        if (this.textrecibe === null || this.textrecibe ===  '') {
           erroform = 'Indica quien recibe';
        }
        if (this.textrecibe.trim() === '') {
           erroform = 'Indica nombre de quien recibe';
        } else {
          this.textrecibe = this.textrecibe.trim() ;
        }

        txtcomentarios = this.entrega;
        txtObservacionEntrega = this.formacaptura.value.textcomen;
        break;
      default:
        this.textrecibe = '';
        txtObservacionEntrega = this.formacaptura.value.textcomen;
        break;
    }

    const fecha1 = moment(this.estatusfecha);
    const fecha2 = moment(this.dataservice.lasfilacaptura[0].fsolicitud);

    if (fecha2.diff(fecha1, 'days') > 0) {
      Swal.fire({
        text: String('Verifica la fecha, no puede ser pasada'),
        icon: 'warning',
        confirmButtonText: 'Cerrar'
      }).then((result) => {
      });
      return;
    }



    if (erroform !== '') {
      Swal.fire({
        text: erroform,
        title: 'Verifica',
        icon: 'warning',
        confirmButtonText: 'Cerrar'
      }).then((result) => {
      });
      return;
    }

    const confirmar = await Swal.fire({
      title: 'Confirma',
      text:  'Actualizar folio',
      icon: 'warning',
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      showConfirmButton: true,
      showCancelButton: true
    });

    if (confirmar.dismiss === Swal.DismissReason.cancel) {
      return;
    }

    let lositemsupdate: string;
    lositemsupdate  =  this.dataservice.lasfilacaptura[0].id + '|' +
      this.textrecibe + '|' +
      txtObservacionEntrega  + '|' +
      this.estatussalud + '|' +
      this.formacaptura.value.estatusfecha + '|' +
      this.dataservice.lasfilacaptura[0].folio  + '|' +
      this.dataservice.lasfilacaptura[0].remitente  + '|' +
      this.dataservice.lasfilacaptura[0].destinatario + '|' +
      this.dataservice.lasfilacaptura[0].confirmaremail  + '|' +
      this.dataservice.lasfilacaptura[0].correodestinatario  + '|' +
      this.estatussalud ;

    Swal.fire({
        allowOutsideClick: false,
        icon: 'info',
        text: 'Espere por favor...'
      });
    Swal.showLoading();
    const valido = await this.dataservice.seguimientoupdate( lositemsupdate  );
    Swal.close();
    if (!valido) {
        return;
      }
    this.estatussalud = '';
    this.dataservice.lasfilacaptura = [];
    this.dataservice.foliocaptu = '';
    }






}
