import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType, HttpHeaders, HttpRequest} from '@angular/common/http';
import { environment } from '../../../environments/environment.prod';
import { AuthService } from '../apirest/auth.service';
import { Campofile } from '../../interfaces/campos';

const URL = environment.sitiores;

@Injectable({
  providedIn: 'root'
})
export class ImportfileService {
respuesta: string;
lasfilafile: Campofile[] = [];
  constructor(private http: HttpClient,
              private USERSERVICE: AuthService
             ) { }

  public uploadFile(filedata: File) {

    const formData: FormData = new FormData();
    formData.append( 'avatar',  filedata);
    formData.append('idusuario', this.USERSERVICE.idusuario);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');

    return new Promise( resolve => {
      this.http.post<any>(`${ URL }/importfile/${ this.USERSERVICE.token }`, formData , { headers})
        .subscribe( async resp => {
         if ( resp.error === false ) {
              this.respuesta =  resp.respuesta.mensaje ;
              resolve(true);
          } else {
            resolve(false);
            this.respuesta =  resp.respuesta.mensaje ;
          }
        }, error => {
          resolve(false);
          // this.iuservce.alertaInformativa('Existe un error: ' + error, 'Falla de aplicacion servicio filatrabajo');
          this.respuesta = JSON.parse (error);
        });

      });

  }


  public insertdb() {
    const data = { idusuario: this.USERSERVICE.idusuario   };
    return new Promise( resolve => {
      this.http.post<any>(`${ URL }/importfile/insertdb/${ this.USERSERVICE.token }`, data)
        .subscribe( async resp => {
         if ( resp.error === false ) {
              // console.log(resp.respuesta.filas);
              this.lasfilafile = resp.respuesta.filas;
              resolve(true);
            }  else {
              this.respuesta = 'Error al insertar';
              resolve(false);
          }
        }, error => {
          resolve(false);
          this.respuesta = JSON.stringify(error);
        });
  });

  }

}

