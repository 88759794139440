import { Pipe, PipeTransform } from '@angular/core';
// https://www.youtube.com/watch?v=NiI3LYlEpQs

@Pipe({
  name: 'filterfactu'
})
export class FilterfactuPipe implements PipeTransform {

  transform(value: any,  arg: any): any {
    const resultPost = [];
    for (const post of value) {
      if (post.destinatario.toLowerCase().indexOf(arg.toLowerCase()) > -1 ) {
      // if (post.destinatario.indexOf(arg) > -1 ) {
        resultPost.push(post);
      }
    }
    return resultPost;
  }

}
