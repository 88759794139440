import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl,   Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { ServicedataService} from '../../services/apirest/servicedata.service';
// https://ng-bootstrap.github.io/#/getting-started
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { Campos } from '../../interfaces/campos';
declare function init_plugins();
@Component({
  selector: 'app-buscar',
  templateUrl: './buscar.component.html',
  styleUrls: ['./buscar.component.css']
})
export class BuscarComponent implements OnInit {
  elprefijo = '';
  lafactura = '';
  recordarme: boolean;
  forma: FormGroup;
  loscampos: Campos[] = [];
  closeResult: string;
  txtstatus: 'Factura';
  imagen64: string;
  constructor(public serdata: ServicedataService,
              private modalService: NgbModal) {


              }

  ngOnInit() {
    init_plugins();

    this.forma = new FormGroup({
      prefijo: new FormControl('', Validators.required),
      factura: new FormControl('', Validators.required),
      festatus: new FormControl('', Validators.required),
      recordar: new FormControl('', )
    });
    if ( localStorage.getItem('prefijo') ) {
      this.recordarme = true;
      this.forma.get('recordar').setValue(true);
      this.elprefijo = localStorage.getItem('prefijo');
      this.forma.get('prefijo').setValue(this.elprefijo);
    }
  }



  async  Onsubmit() {

    if (this.forma.value.recordar) {
      localStorage.setItem('prefijo', this.forma.value.prefijo);
    } else {
      if ( localStorage.getItem('prefijo') ) {
        localStorage.removeItem('prefijo');
      }
    }



  }


  actualizarImagen(content, loscampos: Campos , numimg: string) {
    // https://ng-bootstrap.github.io/#/components/modal/examples
       // this.modalService.mostrarModal( 'loscampos.Imagen' );
       switch (numimg) {
        case '1':
          // this.imagen64 = loscampos.Imagen;
          // break;
          // case '2':
          //   this.imagen64 = loscampos.Imagen2;
          //   break;
          //   case '3':
          // this.imagen64 = loscampos.Imagen3;
          break;
       }

       this.modalService.open(content, { size: 'lg' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
   }

      private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
        } else {
          return  `with: ${reason}`;
        }
      }

      selsel(event) {
        // console.log(event, this.txtstatus);
      }

}
