import { Routes, RouterModule } from '@angular/router';
import { PagesComponent } from './pages.component';
import { ProgressComponent } from './progress/progress.component';
import { BusquedaComponent } from './busqueda/busqueda.component';
import { LoginGuardGuard, AdminGuard, DepurarGuardGuard , VerifiGuardGuard } from '../services/service.index';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { ZoomComponent } from './zoom/zoom.component';
import { DepuraComponent } from './depura/depura.component';
import { BienvenidaComponent } from './bienvenida/bienvenida.component';
import { CapturaComponent } from './captura/captura.component';
import { InvisiblesGuard } from '../services/guards/invisibles.guard';
import { ImportarComponent } from '../pages/importar/importar.component';
import { EstatusComponent } from '../pages/estatus/estatus.component';
import { AsignarComponent } from './asignar/asignar.component';
import { IngresoComponent } from './ingreso/ingreso.component';

// crear paginas: ng g c pages/bienvenida -is --spec=false
// crae guard ng g g services/guards/depurarGuard --spec=false
// el menu esta en auth.service async elmenu(elrol: string) {
const pagesRoutes: Routes = [
    {path: '',
    component: PagesComponent,
    canActivate: [LoginGuardGuard],
    children: [
      {
        path: 'servicio',
        component: BienvenidaComponent,
        canActivate: [LoginGuardGuard],
        data: { titulo: 'Solicitud de Servicio'}
    },
      {path: 'progress', component: ProgressComponent, canActivate: [VerifiGuardGuard ],   data: { titulo: 'Detalle'} },
      {path: 'estatus', component: EstatusComponent , canActivate: [DepurarGuardGuard], data: { titulo: 'Estatus'} },
      {path: 'busqueda/:termino', component: BusquedaComponent, canActivate: [VerifiGuardGuard ], data: { titulo: 'Buscador'} },
      {path: 'zoom', component: ZoomComponent,   data: { titulo: 'Zoom'} },
      {path: 'recepcionc', component: IngresoComponent, canActivate: [AdminGuard ], data: { titulo: 'Recepción Correspondencia'} },
      {path: 'importar', component: ImportarComponent  , canActivate: [AdminGuard ], data: { titulo: 'Captura por archivo'} },
      {path: 'captura', component: CapturaComponent, canActivate: [AdminGuard ], data: { titulo: 'Captura'} },
      {path: 'capturamasiva', component: AsignarComponent,  canActivate: [AdminGuard ], data: { titulo: 'Captura Masiva'} },
      // invisibles son de otra aplicaicon

      // mantenimiento:
      {
          path: 'usuarios',
          component: UsuariosComponent,
          canActivate: [AdminGuard ],
          data: { titulo: 'Mantenimiento de Usuarios'}
      },



      // depuracion
      {
        path: 'registrocontable',
        component: DepuraComponent,
        canActivate: [DepurarGuardGuard],
        data: { titulo: 'Registro Contable'}
      },
      //  otros
      {path: '', redirectTo: '#/registrocontable', pathMatch: 'full'}

    ]
}
];

export const PAGES_ROUTES =  RouterModule.forChild( pagesRoutes);
