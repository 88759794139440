import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
// import { Observable } from 'rxjs';
import { AuthService } from '../apirest/auth.service';

@Injectable({
  providedIn: 'root'
})
export class DepurarGuardGuard implements CanActivate {

  constructor( public userser: AuthService,
               public router: Router ) {
}


canActivate() {
  switch (this.userser.usuario.rol) {
    case '1':
       return true;
    default:
     this.router.navigate(['/login']);
     return false;
  }
}


}
