export const environment = {
  production: true,
  sitiores: 'https://gestor.operacionpibelogistic.com/rest/index.php',
  sitioweb: 'https://gestor.operacionpibelogistic.com/',
  version: '1.0.1',
  apikeymaps: 'pk.eyJ1IjoidXNlcm1hcHNpbWFuIiwiYSI6ImNrZ25wcHhnajAzbXYyenBnMnYycWpoaDgifQ.QUqL0oSEYLu_rAbCggRSmg',
  defaultpass: 'G3estor%&' 


};
