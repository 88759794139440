import { Component, OnInit } from '@angular/core';
// https://valor-software.com/ng2-charts/


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styles: []
})
export class DashboardComponent implements OnInit {
  graficos: any = {
    grafico1: {
      labels: ['Ingreso', 'Captura', 'Documentos', 'Concluidos'],
      data:  [24, 30, 46, 10],
      type: 'doughnut',
      leyenda: 'Estatus'
    },
    grafico2: {
      labels: ['Hombres', 'Mujeres'],
      data:  [4500, 6000],
      type: 'doughnut',
      leyenda: 'Entrevistados'
    },
    grafico3: {
      labels: ['Menor a 20', 'de 20 a 30', 'mas de 30'],
      data:  [10, 50, 15],
      type: 'doughnut',
      leyenda: 'Edad'
    },
    grafico4: {
      labels: ['Rechazados', 'En analisis', 'Contratados'],
      data:  [85, 15, 10],
      type: 'doughnut',
      leyenda: 'Conclusion'
    },
  };

  constructor() {


   }

  ngOnInit() {

  }

}
