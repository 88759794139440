import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ModalUuserService } from './modal-user.service';
import { ServicedataService } from '../../services/apirest/servicedata.service';
import Swal from 'sweetalert2';
import { AuthService } from '../../services/apirest/auth.service';

@Component ({
  selector: 'app-modal-user',
  templateUrl: './modal-user.component.html',
  styles: []
})
export class ModalUserComponent implements OnInit {
  elreset: false;
  public ngactivo: boolean;
  usernuevo: string;

  constructor(public servmodaluser: ModalUuserService,
              public servData: ServicedataService,
              public ususervrce: AuthService) { }

  ngOnInit() {
   this.elreset = false;

  }



  async onClickOK(form: NgForm) {
      if (form.invalid) { return; }

      


      this.servmodaluser.Precerrarmomodal();
      const confirmar = await Swal.fire({
        title: 'Continuar',
        icon: 'warning',
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        showConfirmButton: true,
        showCancelButton: true
      });

      if (confirmar.dismiss === Swal.DismissReason.cancel) {
        this.servmodaluser.verultimomodal();
        return;
      }


      let activovar = '1';
      let resetpass = '';
      if (this.servmodaluser.confirmado === '1') {
        if (form.value.reset === true) {
          resetpass = 'siman';
         }

        if (form.value.activo === false) {
          activovar = '0';
        }
      }

      const items = this.servmodaluser.iduser + '|' +
      form.value.inputNombre + '|' +
      form.value.inputtelefono + '|' +
      form.value.inputext + '|' +
      form.value.inputtelefono1 + '|' +
      form.value.inputcalle + '|' +
      form.value.inputcol + '|' +
      form.value.inputdelmun + '|' +
      form.value.inputedo + '|' +
      form.value.inputcp + '|' +
      form.value.inputarea + '|' +
      form.value.inputreferencia + '|' +
      activovar + '|' +
      resetpass + '|' +
      this.servmodaluser.rol +  '|' + 'llaveok' ;


      Swal.fire({
        title: 'Espere',
        allowOutsideClick: false,
        icon: 'info'
      });
      Swal.showLoading();
      const valido = await  this.ususervrce.tablauserroldatosdatos(items);


      Swal.close();
      switch ( valido ) {
        case true:
          if ( this.servmodaluser.confirmado !== '1' && this.servmodaluser.iduser !== '') {

            Swal.fire({
              title: 'Procesado',
              text: 'Se requiere reingresar',
              icon: 'success',
              confirmButtonText: 'Cerrar'
            }).then((result) => {
              this.ususervrce.logout();
              window.location.reload();
            });

            } else {
              Swal.fire({
                title: 'Procesado',
                text: 'Ingresado',
                icon: 'success',
                confirmButtonText: 'Cerrar'
              }).then((result) => {
              });

            }

          break;
        case false:
          Swal.fire({
            text: 'No se actualizo',
            icon: 'warning',
            confirmButtonText: 'Cerrar'
          }).then((result) => {
            this.servmodaluser.verultimomodal();
          });
          break;
        default:
            Swal.fire({
              text: String(valido),
              icon: 'warning',
              confirmButtonText: 'Cerrar'
            }).then((result) => {
            });
            break;
      }
      this.elreset = false;
      this.servmodaluser.notificaion.emit(true);
      this.servmodaluser.cerrarModal();
  }

  cerrarm(form: NgForm) {
    form.resetForm();
    this.elreset = false;
    this.servmodaluser.cerrarModal();
    if ( this.ususervrce.usuario.confirmado === '0') {
      this.ususervrce.logout();
    }

  }



}

