import { Component,  OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServicedataService } from './../../services/service.index';
import Swal from 'sweetalert2';
import { AuthService } from './../../services/service.index';
// import { Filatrabajo } from '../../interfaces/interfasefilas';
import * as xlsx from 'xlsx';
// solo se intala el NMP: npm install moment --save
import * as moment from 'moment';

@Component ({
  selector: 'app-busqueda',
  templateUrl: './busqueda.component.html',
  styles: []
})
export class BusquedaComponent implements OnInit {
  @ViewChild('epltable', { static: false }) epltable: ElementRef;
existe: number;
elcampo: string;
filterPost = '';
  constructor(public activatedRoute: ActivatedRoute,
              public dataservice: ServicedataService,
              private SERVUSER: AuthService,
              private router: Router
              ) {

    activatedRoute.params
    .subscribe( params => {
      const termino = params.termino;
// si marca errro add import { CommonModule } from '@angular/common';
      if ( this.dataservice.campobusqueda === 'folio') {
        if (termino !== '') {
          this.buscarprospecto(termino);
        }
      } else {
        this.buscarestatus(termino);
      }

    });

   }


async buscarestatus(items: string) {
      Swal.fire({
        title: 'Espere',
        allowOutsideClick: false,
        icon: 'info'
      });
      Swal.showLoading();

      const valido = await  this.dataservice.verstatusdetalle(items);
      Swal.close();

      switch ( valido ) {
        case true:
          break;
        case 'No hay datos':
          Swal.fire({
            text: 'No hay coincidencias',
            icon: 'warning',
            confirmButtonText: 'Cerrar'
          }).then((result) => {
          });
          break;
        default:
            Swal.fire({
              text: String(valido),
              icon: 'warning',
              confirmButtonText: 'Cerrar'
            }).then((result) => {
            });
            break;
      }
}

async buscarprospecto(termino: string) {

    if (termino.length < 3) {
      Swal.fire({
        text: 'Verifica minimo 3 caracteres',
        icon: 'warning',
        confirmButtonText: 'Cerrar'
      }).then((result) => {
      });
      return;
    }

    // console.log(termino.substr(2, termino.length - 2 ));
    // termino =  termino.substr(2, termino.length - 2 );
    Swal.fire({
      title: 'Espere',
      allowOutsideClick: false,
      icon: 'info'
    });
    Swal.showLoading();

    const valido = await  this.dataservice.buscarfolio(termino);
    Swal.close();

    switch ( valido ) {
      case true:
        break;
      case 'No hay datos':
        Swal.fire({
          text: 'No hay coincidencias',
          icon: 'warning',
          confirmButtonText: 'Cerrar'
        }).then((result) => {
        });
        break;
      default:
          Swal.fire({
            text: String(valido),
            icon: 'warning',
            confirmButtonText: 'Cerrar'
          }).then((result) => {
          });
          break;
    }


   }


  ngOnInit() {

  }



  onClickzoom(id: string, folio: string) {
    this.dataservice.numid = id ;
    this.dataservice.bfolio = folio ;
    this.router.navigate(['/zoom']);
  }


  exportToExcel() {

    const ws: xlsx.WorkSheet =
    // xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    xlsx.utils.json_to_sheet(this.dataservice.lasfilasbucafolio);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'Busqueda' + moment(new Date()).format('YYYYMMDDhhmmss') + '.xlsx');
   }
}
