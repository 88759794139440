
                <div class="container"  >
                  <div class="row">
                    <div class="col ">
                      <button (click)="onClickback()" class="btn btn-primary">
                          <i class="fa fa-arrow-left pointer"></i> Regresar
                        </button>                     
                    </div>
                    <div class="col ">
                      <h2 color="primary">{{ dataservice.Elid.folio  }} 
                          <button type="button" 
                         (click)="Prinmt( dataservice.Elid )" class="btn btn-link" data-toggle="tooltip" data-placement="top" title="Reimprimir" >
                              <i class="fa fa-print fa-lg" aria-hidden="true"></i></button>
                        </h2>
                    </div>
                    <div class="col ">
                      <h5 color="primary"><strong>Estatus:</strong> {{ dataservice.Elid.descripcion }}
                                <button type="button"  data-toggle="tooltip" data-placement="top" title="Ver mapa"  
                                       *ngIf=" dataservice.Elid.coordenadas"  (click)="VeronClick2( dataservice.Elid.coordenadas)" class="btn btn-link">
                                      <i class="fa fa-map-marker fa-lg" aria-hidden="true"></i>
                                </button>
                                <button type="button"  data-toggle="tooltip" data-placement="top" title="Ver firma"  
                                       *ngIf="dataservice.Elid.firma"  (click)="VeronClick( dataservice.Elid)" class="btn btn-link">
                                      <i class="fa fa-pencil fa-lg" aria-hidden="true"></i>
                                </button>
                       </h5> 
                    </div>
                    
                  </div>
                  <div class="row">                    
                    <div class="col">
                        <h5 color="primary"><strong>Actualizado:</strong> {{ dataservice.Elid.frespuesta| date:'yyyy-MM-dd HH:mm'  }}</h5>
                    </div>
                  
                    <div class="col">
                        <h5 color="primary"><strong>Solicitado:</strong> {{ dataservice.Elid.FSolicitud | date:'yyyy-MM-dd HH:mm' }}</h5>
                    </div>
                 
                    </div>
                </div>
                
                <div class="card-header" > 
                    <ul class="nav nav-pills" id="pills-tab" role="tablist">
                      <li class="nav-item">
                        <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Servicio</a>
                      </li>
                       
                       <li class="nav-item">
                        <a class="nav-link" id="pills-profile-mvtos" data-toggle="pill" href="#pills-mvtos" role="tab" aria-controls="pills-mvtos" aria-selected="false">Historia</a>
                      </li>
                    </ul>
              </div>


  

<div class="card-body">
  <div class="tab-content" id="pills-tabContent">
      <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
        <form>
          <div class="row">
            <div class="col-md-6 mb-3">
                <label for="formGroupExampleInput" ><Strong>Remitente</Strong></label>
                <input type="text" class="form-control" value="{{ dataservice.Elid.NombreR}}" id="formGroupExampleInput" readonly>                     
            </div>

            <div class="col-md-6 mb-3">
                <label for="formGroupExampleInputD" ><Strong>Destinatario</Strong></label>
                <input type="text" class="form-control" value="{{ dataservice.Elid.nombreD}}" id="formGroupExampleInputD" readonly>                     
            </div>
          </div>
          
          <div class="row">
            <div class="col-md-6 mb-3">
                <label for="formGroupExampleInputdr" >Domicilio</label>
                <input type="text" class="form-control" value="{{ dataservice.Elid.calleR}}" id="formGroupExampleInputdr" readonly>                                                                     
            </div>

            <div class="col-md-6 mb-3">
                <label for="formGroupExampleInputDdd" >Domicilio</label>
                <input type="text" class="form-control" value="{{ dataservice.Elid.calleD}}" id="formGroupExampleInputDdd" readonly>                                        
            </div>
          </div>
           

          <div class="row">
            <div class="col-md-6 mb-3">                                            
                <input type="text"  class="form-control"  value="{{ dataservice.Elid.colD}}"  readonly>                     
            </div>

            <div class="col-md-6 mb-3">               
                <input type="text" class="form-control"   value="{{ dataservice.Elid.colD}}"  readonly>                                        
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 mb-3">                                            
                <input type="text"  class="form-control"  value="{{ dataservice.Elid.DelR}}"  readonly>                     
            </div>

            <div class="col-md-6 mb-3">               
                <input type="text" class="form-control"   value="{{ dataservice.Elid.DelD}}"  readonly>                                        
            </div>
          </div>

          
          <div class="row">
            <div class="col-md-4 mb-3">                                            
                <input type="text"  class="form-control"  value="{{ dataservice.Elid.edoR}}"  readonly>                     
            </div>

            <div class="col-md-2 mb-3">                                            
                <input type="text"  class="form-control"  value="{{ dataservice.Elid.CpR}}"  readonly>                     
            </div>
            <div class="col-md-4 mb-3">               
                <input type="text" class="form-control"   value="{{ dataservice.Elid.edoD}}"  readonly>                                        
            </div>
            <div class="col-md-2 mb-3">                                            
                <input type="text"  class="form-control"  value="{{ dataservice.Elid.cpD}}"  readonly>                     
            </div>
          </div>
         
          <div class="row">
            <div class="col-md-6 mb-3">                                            
                <input type="text"  class="form-control"  value="{{ dataservice.Elid.telRemi}}"  readonly>                     
            </div>

            <div class="col-md-6 mb-3">               
                <input type="text" class="form-control"   value="{{ dataservice.Elid.telDestina }}"  readonly>                                        
            </div>
          </div>
          

          <div class="row">
            <div class="col-md-4 mb-3">
                <label for="fTipoenvio" >Tipo</label>
                <input type="text" class="form-control" value="{{ dataservice.Elid.Tipoenvio}}" id="fTipoenvio" readonly>                                                                     
            </div>

            <div class="col-md-4 mb-3">
                <label for="freferencia" >Referencia</label>
                <input type="text" class="form-control" value="{{ dataservice.Elid.referencia}}" id="freferencia" readonly>                                        
            </div>

            <div class="col-md-4 mb-3">
                <label for="fcomentarios" >Comentarios</label>
                <input type="text"  class="form-control auto_height" value="{{ dataservice.Elid.comentarios}}" id="fcomentarios" readonly>                                        
            </div>
          </div>

 
 
          <div class="row">
            <div class="col-md-4 mb-3">
                <label for="frecibio" >Recibio</label>
                <input type="text" class="form-control" value="{{ dataservice.Elid.recibio}}" id="frecibio" readonly>                                                                     
            </div>

            <div class="col-md-4 mb-3">
                <label for="fpuesto" >Puesto</label>
                <input type="text" class="form-control" value="{{dataservice.Elid.puesto}}" id="fpuesto" readonly>                                        
            </div>

            <div class="col-md-4 mb-3">
                <label for="fObserva" >Observación</label>
                <input type="text"  class="form-control auto_height" value="{{ dataservice.Elid.observacion}}" id="fObserva" readonly>                                        
            </div>
          </div>

        
      </form>  
   
      </div>
 

      <div class="tab-pane fade" id="pills-mvtos" role="tabpanel" aria-labelledby="pills-profile-mvtos">
          
        <button type="button"   class="btn btn-primary" (click)="verhostoria(dataservice.Elid.folio)">Mostrar</button>

        
 
          <div *ngIf="dataservice.ArrayLositems.length>0">
              <table class="table table-hover">
                  <thead>
                      <tr>  
                          <th>Fecha</th>
                          <th>Estatus</th>
                          <th>Observación</th>
                      </tr>
                  </thead>
                  <tbody>

                  
                      <tr *ngFor="let doc of dataservice.ArrayLositems">
                             
                                  <td>
                                          {{ doc.fec }}
                                      </td>
                                  <td>
                                          {{ doc.descripcion }}
                                  </td> 
                                  <td>
                                      {{ doc.Obser_mov}}
                                  </td> 
                                 
                                                                        
                          
                      </tr>
                  </tbody>
              </table>
          </div>

            
      </div> 

  </div>
</div>



            <div class="fondo-negro animated fadeIn" [ngStyle]="{'display':display}">
              <div class="modal bd-example-modal-lg"   tabindex="-1" role="dialog"   style="display:block;" role="dialog">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                          <button type="button" class="btn btn-primary" (click)="cerrarModal()" >Cerrar</button>
                          
                        </div>
                       <div class="modal-body" align="center">
                          <img [src]="imagensel"  >
                      </div> 

                   
                      
                    </div>
                  </div>
                </div>
              </div>


              <div class="fondo-negro animated fadeIn" [ngStyle]="{'display':displaymap}">
                  <div class="modal bd-example-modal-lg"   tabindex="-1" role="dialog"   style="display:block;" role="dialog">
                      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="modal-content">
                          <div class="modal-header">
                              <button type="button" class="btn btn-primary" (click)="cerrarModalmap()" >Cerrar</button>
                              
                            </div>
                         
                          <div  id="mapa-mapbox"   class="img-400"></div>
                          
                        </div>
                      </div>
                    </div>
                  </div>