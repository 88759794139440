<div class="fondo-negro animated fadeIn" [ngClass]="autser.oculto">
    
    <div class="modal" style="display:block;" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Bienvenido Version {{ verisoon }}</h5>
              <button type="button" class="close" (click)="autser.oculto='oculto';" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
            </div>

            <div class="modal-body">
                    <div class="container-login100 container-fluid"> 
                      <!-- <ul class="nav nav-pills mb-3 nav-fill" id="pills-tab" role="tablist">
                        <li class="nav-item" >
                          <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Registrado</a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" id="pills-nuevo-tab" data-toggle="pill" href="#pills-nuevo" role="tab" aria-controls="pills-nuevo" aria-selected="false">Nuevo</a>
                        </li>                         
                      </ul> -->
                      <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                <div class="wrap-login100 p-t-50 p-b-90">
                                    <form ngNativeValidate (ngSubmit)="Onsubmit(f)" 
                                            #f="ngForm"
                                    class="login100-form validate-form flex-sb flex-w">
                        
                                        <span class="login100-form-title p-b-51">
                                                <img src="../../../assets/images/imglogin.png"   class="img-fluid" />
                                        </span>
                                        <br>
                                        <span *ngIf="f.submitted && f.controls['Username'].errors"
                                        class="text-danger animated fadeIn">El usuario es obligatorio</span>
                                        <div class="wrap-input100 m-b-16">
                                            <input class="input100" 
                                                  type="text" 
                                                  name="Username" 
                                                  required
                                                  minlength="4"
                                                  [(ngModel)]="elusuario"
                                                  placeholder="usuario">
                                                    
                                            <span class="focus-input100"></span>
                                        </div>
                                        <br>
                                        <span *ngIf="f.submitted && f.controls['pass'].errors"
                                                  class="text-danger animated fadeIn">La contraseña es obligatorio</span>
                                        <div class="wrap-input100 m-b-16" data-validate = "Password is required">
                                            <input class="input100"
                                                  type="password" 
                                                  name="pass" 
                                                  minlength="5"
                                                  required
                                                  [(ngModel)]="elpass"
                                                  placeholder="Contraseña">
                                                  
                                            <span class="focus-input100"></span>
                                        </div>
                    
                        
                                    <br>
                                    <div class="row">
                                            <div class="col">
                                            <button class="btn login100-form-btn" type="submit" >
                                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                <span class="sr-only">Loading...</span>
                                                Aceptar
                                            </button>                              
                                        </div>
                                    
                                    </div>
                                    </form>
                                
                                </div>
                                <div class="container-login1"> 
                                  <form class="login100-form validate-form flex-sb flex-w">
                                  <button type="button" *ngIf="elusuario.length>4" (click)="recordar()" class="btn btn-link"><i class="fa fa-commenting-o" aria-hidden="true"></i> No recuerdo mi contraseña</button>
                                </form>
                              </div>
                            </div>

                            <div class="tab-pane fade" id="pills-nuevo" role="tabpanel" aria-labelledby="pills-nuevo-tab">
                             
                                <div class="wrap-login100 p-t-50 p-b-90">
                                  <form class="login100-form validate-form flex-sb flex-w" ngNativeValidate 
                                  [formGroup]="formanvo" (ngSubmit)="registrarUsuario()"  id="loginformnva">
                                    <div class="wrap-input100 m-b-8">
                                      <input class="input100" 
                                            formControlName="correo"
                                            type="email" 
                                            [email]="true"
                                            pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"                                             
                                            placeholder="correo de sura">                                              
                                      <span class="focus-input100"></span>
                                    </div>
                                  <br>
                                  <div class="wrap-input100 m-b-16">
                                    <input class="input100" 
                                          formControlName="registro"
                                          type="password"                                            
                                          placeholder="Nomina">                                            
                                    <span class="focus-input100"></span>
                                </div>
                                <br>
                                <div class="wrap-input100 m-b-16">
                                  <input class="input100" 
                                        type="password" 
                                        formControlName="registro2"
                                        placeholder="Confirma nomina">
                                          
                                  <span class="focus-input100"></span>
                              </div>
                              <br>
                              <br>
                             

                              <div class="row">
                                <div class="col">
                                <button class="btn login100-form-btn" type="submit" [disabled]="!formanvo.valid" >
                                    
                                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                  <span class="sr-only">Loading...</span>
                                  Alta 
                                </button>                              
                            </div>
                        
                            </div>
                            <br>
                                  </form>
                                </div>
                                <div class="container-login1"> 
                                  <form class="login100-form validate-form flex-sb flex-w">
                                  
                                  <label >Contraseña predeterminada : siman</label>
                                </form>
                              </div>
                            </div>
                      </div>
                   </div>
                     
            </div>
          
             
          </div>
        </div>
      </div>
    </div>
