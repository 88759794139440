import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModalUuserService } from '../components/modal-user/modal-user.service';



import {
  AuthService,
  ServicedataService,
  SharedService,
  SidebarService,
  LoginGuardGuard,
  AdminGuard,
  DepurarGuardGuard
} from './service.index';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    AuthService,
    ServicedataService,
    SharedService,
    SidebarService,
    ModalUuserService,
    AdminGuard,
    LoginGuardGuard,
    DepurarGuardGuard
  ]
})
export class ServiceModule { }
