import { Component, OnInit } from '@angular/core';
import { SidebarService, AuthService } from '../../services/service.index';
import { Usuario } from '../../model/usuario.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { environment } from '../../../environments/environment.prod';
import { ModalUuserService } from '../../components/modal-user/modal-user.service';
// declare function init_plugins();




@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styles: [`.backdrop{
    background-color:rgba(0,0,0,0.6);
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100vh;
    }`]
})
export class SidebarComponent implements OnInit {
  display = 'none';
  verisoon = '';
  Usuario: Usuario;
  forma: FormGroup;
  public message: string;
  public imagePath;
  imgURL: any;

  constructor(  public _SIDEBAR: SidebarService,
                private servmodaluser: ModalUuserService,
                public ususervrce: AuthService) {
                  this.verisoon = environment.version;
                  this.Usuario = ususervrce.usuario;
                }

  ngOnInit() {
    // init_plugins();
    this.forma = new FormGroup({
      passwordini: new FormControl( null , [Validators.required ]),
      password: new FormControl( null , [Validators.required , Validators.minLength(5)] ),
      password2: new FormControl( null , [Validators.required , Validators.minLength(5)]),
      condiciones: new FormControl( false )
    }, { validators: this.sonIguales( 'password', 'password2' )  } );
  }

  sonIguales( campo1: string, campo2: string ) {

    return ( group: FormGroup ) => {

      const pass1 = group.controls[campo1].value;
      const pass2 = group.controls[campo2].value;

      if ( pass1 === pass2 ) {
        return null;
      }

      return {
        sonIguales: true
      };

    };

  }


  cambiarPass() {
    this.display = 'block';

  }



  cambiardatos() {

    this.servmodaluser.MostrarModal(this.ususervrce.usuario);
  }

  cerrarModal() {
    this.forma.setValue({
      passwordini: null,
      password: null,
      password2: null,
      condiciones: false
    });
    this.display = 'none';
  }



  async registrarUsuario() {
      this.display = 'none';
      const confirmar = await Swal.fire({
        title: 'Actualizar',
        icon: 'warning',
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        showConfirmButton: true,
        showCancelButton: true
      });

      if (confirmar.dismiss === Swal.DismissReason.cancel) {
        this.display = 'block';
        return;
      }



      Swal.fire({
        title: 'Espere',
        allowOutsideClick: false,
        icon: 'info'
      });
      Swal.showLoading();

      const valido = await  this.ususervrce.update_pass(this.forma.value.passwordini, this.forma.value.password);
      Swal.close();

      switch ( valido ) {
        case true:
            Swal.fire({
              text: 'Cambio realizado correctamente, requiere reingresar',
              icon: 'success',
              confirmButtonText: 'Cerrar'
            }).then((result) => {
              this.ususervrce.logout();
            });
            break;
        case false:
          Swal.fire({
            text: 'No hay coincidencias',
            icon: 'warning',
            confirmButtonText: 'Cerrar'
          }).then((result) => {
            this.display = 'block';
          });
          break;
        default:
            Swal.fire({
              text: String(valido),
              icon: 'warning',
              confirmButtonText: 'Cerrar'
            }).then((result) => {
            });
            break;
      }
  }

  preview(files) {
    if (files.length === 0) {
      return;
    }

    // tslint:disable-next-line: prefer-const
    let mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      // this.message = 'Solo imagenes.';
      return;
    }

    // tslint:disable-next-line: prefer-const
    let reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    // tslint:disable-next-line: variable-name
    reader.onload = (_event) => {
      // console.log( reader.result);
      this.imgURL = reader.result;
    };
  }

}
