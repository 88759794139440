import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/apirest/auth.service';
import { ServicedataService } from './../../services/service.index';

@Component ({
  selector: 'app-modal-zoom',
  templateUrl: './modal-zoom.component.html',
  styles: []
})
export class ModalZoomComponent implements OnInit {

  constructor(public autser: AuthService,
              public serviced: ServicedataService) { }

  ngOnInit() {
  }

cerrarModal() {
  this.autser.ocultoMZoom = 'oculto';

}


}
