import { Component, OnInit  } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/apirest/auth.service';

declare function init_plugins();

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  constructor(public router: Router,
              private servAut: AuthService ) { }

  ngOnInit() {
    init_plugins();
  }

  ingresar() {
  //  this.router.navigate(['/dashboard']);
  }
  modallogin() {    
    this.servAut.login();
  }

  Buscar() {
    this.router.navigate(['/buscar']);
  }

}
