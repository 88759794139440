import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../../services/apirest/auth.service';


@Injectable({
  providedIn: 'root'
})
export class LoginGuardGuard implements CanActivate {
  constructor(public _USUARIO: AuthService,
              public router: Router) {

  }

  canActivate() {
    // console.log('log', this._USUARIO.estaloguado());
    if (this._USUARIO.estaloguado()) {
          return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }

  }

}
