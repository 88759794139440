import { Component, OnInit } from '@angular/core';
import { Qusuario } from '../../model/usuario.model';
import { ServicedataService } from '../../services/apirest/servicedata.service';
import Swal from 'sweetalert2';
import { Usuariointerfase } from '../../interfaces/intersaseusuario';
import { ModalUuserService } from '../../components/modal-user/modal-user.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/apirest/auth.service';
import { Cbstatus  } from '../../model/usuario.model';

@Component ({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styles: []
})

export class UsuariosComponent implements OnInit {
  losusuario: Qusuario[] = [];
  desde = 0;
  valorlimite: 5;
  cargando = true;
  nameuser = 'X';
  display = 'none';
  txtbusca = 'u.username';
  public Arraystatus: Cbstatus[] = [
    {id: 'u.username', descripcion: 'usuario'},
    {id: 'u.nombre', descripcion: 'nombre'}
  ];
  formanvo: FormGroup;


  constructor(private SERVDATA: ServicedataService,
              public autser: AuthService,
              private servmodaluser: ModalUuserService,
              public modalUserSer: ModalUuserService) { }

  ngOnInit() {
      this.modalUserSer.notificaion
      .subscribe( resp => {
        this.cargarUsuarios(this.nameuser);
      });
      this.cargarUsuarios(this.nameuser);

      this.formanvo = new FormGroup({
        correo: new FormControl( null , [Validators.required ]),
        correo2: new FormControl( null , [Validators.required ]),
        registro: new FormControl( null , [Validators.required , Validators.minLength(3)] ),
        registro2: new FormControl( null , [Validators.required , Validators.minLength(3)]),
        condiciones: new FormControl( false )
      }, { validators: this.sonIguales( 'registro', 'registro2' ) && this.sonIguales( 'correo', 'correo2' ) } );
  }

  sonIguales( campo1: string, campo2: string ) {

    return ( group: FormGroup ) => {

      const pass1 = group.controls[campo1].value;
      const pass2 = group.controls[campo2].value;
     // console.log(pass1 , pass2);
      if ( pass1 === pass2 ) {
        return null;
      }

      return {
        sonIguales: true
      };

    };

  }
  cambiarDesde( valor: number ) {

    const desde = this.desde + valor;

    if ( desde >= this.SERVDATA.numregisUsers ) {
       return;
     }

    if ( desde < 0 ) {
      return;
    }

    this.desde += valor;
    this.cargarUsuarios(this.nameuser);

  }

  async cargarUsuarios(buscar: string) {
    this.cargando = true;
    const valido = await  this.SERVDATA.mostrarUsuarios( this.desde , buscar);



    this.cargando = false;
    switch ( valido ) {
      case true:
        this.losusuario = this.SERVDATA.losusuarios;
        break;
      case 'No hay datos':
        Swal.fire({
          text: 'No hay coincidencias',
          icon: 'warning',
          confirmButtonText: 'Cerrar'
        }).then((result) => {
        });

        break;
      default:
          Swal.fire({
            text: String(valido),
            icon: 'warning',
            confirmButtonText: 'Cerrar'
          }).then((result) => {
          });
          break;
    }
  }

  async  buscarUsuario( termino: string ) {
    if (this.txtbusca === '' ) {
      Swal.fire({
        text: 'indica campo',
        icon: 'warning',
        confirmButtonText: 'Cerrar'
      }).then((result) => {
      });
      return;
    }


    this.desde = 0;
    if ( termino.length <= 0 ) {
      this.nameuser = 'X';
      this.cargarUsuarios(this.nameuser);
      return;
    } else { this.nameuser = termino; }

    this.cargando = true;
    const valido = await  this.SERVDATA.mostrarUsuarios( this.desde , this.nameuser + '|' + this.txtbusca);

    this.cargando = false;
    switch ( valido ) {
      case true:
        this.losusuario = this.SERVDATA.losusuarios;
        break;
      case 'No hay datos':
        this.losusuario = [];
        Swal.fire({
          text: 'No hay coincidencias',
          icon: 'warning',
          confirmButtonText: 'Cerrar'
        }).then((result) => {
        });
        break;
      default:
          Swal.fire({
            text: String(valido),
            icon: 'warning',
            confirmButtonText: 'Cerrar'
          }).then((result) => {
          });
          break;
    }

  }


  onClick(unUsuario: Usuariointerfase ) {
    // console.log(unUsuario);
    this.servmodaluser.MostrarModal(unUsuario);
  }


  onClicknvo() {
      this.servmodaluser.MostrarModalNuevo();
    // console.log(this.servmodaluser.iduser);
   // this.display = 'block';
  }


userexterno() {
  this.display = 'block';
}



cerrarModalux() {
  // this.forma.setValue({
  //   passwordini: null,
  //   password: null,
  //   password2: null,
  //   condiciones: false
  // });

  this.formanvo.reset();
  this.display = 'none';
}


async registrarUsuario() {
  this.display = 'none';


  const elcorreo = this.formanvo.value.correo.trim();




  const confirmar = await Swal.fire({
    title: 'Continuar alta de usuario externo',
    icon: 'warning',
    confirmButtonText: 'Si',
    cancelButtonText: 'No',
    showConfirmButton: true,
    showCancelButton: true
  });

  if (confirmar.dismiss === Swal.DismissReason.cancel) {
    this.display = 'none';
    return;
  }



  Swal.fire({
    title: 'Espere',
    allowOutsideClick: false,
    icon: 'info'
  });
  Swal.showLoading();

  const valido = await  this.autser.nuevousu(elcorreo, this.formanvo.value.registro);
  Swal.close();


  Swal.fire({
          text: String(valido),
          icon: 'warning',
          confirmButtonText: 'Cerrar'
        }).then((result) => {
          this.display = 'none';
        });
}


}
