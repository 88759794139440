import { Component,  OnInit , ViewChild, ElementRef  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators   } from '@angular/forms';
import { ServicedataService } from './../../services/service.index';
import Swal from 'sweetalert2';
import { AuthService } from './../../services/service.index';
// import { Filatrabajo } from '../../interfaces/interfasefilas';
import { Cbstatus  } from '../../model/usuario.model';

// solo se intala el NMP: npm install moment --save
import * as moment from 'moment';

// exportar a excel:
// npm install xlsx --save
// añadir 2 valores , ViewChild, ElementRef en @angular/core
// añadir  @ViewChild('epltable', { static: false }) epltable: ElementRef; despues del oninit{
// añadir div en la tabla  <div class="col-md-12 table-responsive" #epltable>
import * as xlsx from 'xlsx';



@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styles: []
})
export class ProgressComponent implements OnInit {
  @ViewChild('epltable', { static: false }) epltable: ElementRef;
  filterPost = '';

 public Arraystatus: Cbstatus[] = [
    {id: 'folio', descripcion: 'Folio'},
    {id: 'fechasol', descripcion: 'Fecha de solicitud'}
  ];
 varadci: string;
 varadci2: string;
 txtfini = this.dataservice.loscrit.fini;
 txtffin = this.dataservice.loscrit.ffin;
 txtstatus =  this.dataservice.loscrit.id;
 txtprefijo =  this.dataservice.loscrit.fprefijo;
 forma: FormGroup;
//  public loscrit: Criteriosprogress; // = new Criteriosprogress('', '', '',  );

  constructor(public activatedRoute: ActivatedRoute,
              public dataservice: ServicedataService,
              private SERVUSER: AuthService,
              private router: Router   ) {
                this.forma = new FormGroup({
                  ffini: new FormControl( null  ),
                  fffin: new FormControl( null   ),
                  festatus: new FormControl( this.dataservice.loscrit.fprefijo  ),
                  fprefijo: new FormControl( null , [])
                }  );
              }

  ngOnInit() {


  }

  async onClickdetalle2() {
    if (this.forma.invalid) { return; }

    if (this.txtstatus === 'fechasol') {
            const fecha1 = moment(this.forma.value.ffini);
            const fecha2 = moment(this.forma.value.fffin);

            if (fecha2.diff(fecha1, 'days') > 30) {
              Swal.fire({
                text: String('Los peridos son maximo 30 dias'),
                icon: 'warning',
                confirmButtonText: 'Cerrar'
              }).then((result) => {
              });
              return;
            }

            if (fecha2.diff(fecha1, 'days') < 0 ) {
              Swal.fire({
                text: String('Verifica las fechas'),
                icon: 'warning',
                confirmButtonText: 'Cerrar'
              }).then((result) => {
              });
              return;
            }
    }

    this.dataservice.loscrit.fini = this.forma.value.ffini;
    this.dataservice.loscrit.ffin = this.forma.value.fffin;
    this.dataservice.loscrit.id = this.forma.value.festatus;

    const items = this.forma.value.ffini + '|' +
    this.forma.value.fffin + '|' +
    this.forma.value.fprefijo ;

    Swal.fire({
      title: 'Espere',
      allowOutsideClick: false,
      icon: 'info'
    });
    Swal.showLoading();
    const valido = await  this.dataservice.verdetallestatus(items, this.forma.value.festatus);
    Swal.close();

    switch ( valido ) {
      case true:
        break;
      case 'No hay datos':
        Swal.fire({
          text: 'No hay coincidencias',
          icon: 'warning',
          confirmButtonText: 'Cerrar'
        }).then((result) => {
        });
        break;
      default:
          Swal.fire({
            text: String(valido),
            icon: 'warning',
            confirmButtonText: 'Cerrar'
          }).then((result) => {
          });
          break;
    }

  }




  async buscarstatus(items: string, tipoconsulta: string) {


   }


  onClickzoom(id: string , folio: string) {
    this.dataservice.numid = id ;
    this.dataservice.bfolio = folio ;
    this.router.navigate(['/zoom']);
  }


  exportToExcel() {

    const ws: xlsx.WorkSheet =
    // xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    xlsx.utils.json_to_sheet(this.dataservice.lasfilasprogress);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'Reporte' + moment(new Date()).format('YYYYMMDDhhmmss') + '.xlsx');
   }


}
