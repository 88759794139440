import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { DashboardComponent } from './dashboard/dashboard.component';
import { ProgressComponent } from './progress/progress.component';
import { Graficas1Component } from './graficas1/graficas1.component';
import { PAGES_ROUTES } from './pages.routes';
import { PagesComponent } from './pages.component';
import { BusquedaComponent } from './busqueda/busqueda.component';
import { CommonModule } from '@angular/common';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { ModalUserComponent } from '../components/modal-user/modal-user.component';
import { ModalZoomComponent } from '../components/modal-zoom/modal-zoom.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// charts
import { ChartsModule } from 'ng2-charts'; // npm install --save ng2-charts  y npm install --save chart.js
import { GraficoDonaComponent } from '../components/grafico-dona/grafico-dona.component';
import { ZoomComponent } from './zoom/zoom.component';
import { DepuraComponent } from './depura/depura.component';
import { BienvenidaComponent } from './bienvenida/bienvenida.component';
import { IngresoComponent } from './ingreso/ingreso.component';
import { CapturaComponent } from './captura/captura.component';
import { AsignarComponent } from './asignar/asignar.component';
import { ModalSelectuserComponent } from '../components/modal-selectuser/modal-selectuser.component';
import { ImportarComponent } from './importar/importar.component';
import { FilternamePipe } from './../pipes/filtername.pipe';
import { FilterfactuPipe } from '../pipes/filterfactu.pipe';
import { AsignarcvComponent } from './asignarcv/asignarcv.component';
import { EstatusComponent } from './estatus/estatus.component';

@NgModule({
    declarations: [
        PagesComponent,
        DashboardComponent,
        ProgressComponent,
        Graficas1Component,
        BusquedaComponent,
        UsuariosComponent,
        ModalUserComponent,
        ModalZoomComponent,
        GraficoDonaComponent,
        ZoomComponent,
        DepuraComponent,
        BienvenidaComponent,
        IngresoComponent,
        CapturaComponent,
        AsignarComponent,
        ModalSelectuserComponent,
        ImportarComponent,
        FilternamePipe,
        FilterfactuPipe,
        AsignarcvComponent,
        EstatusComponent
    ],
    exports: [
        DashboardComponent,
        ProgressComponent,
        Graficas1Component,
        BusquedaComponent
    ],
    imports: [
        SharedModule,
        PAGES_ROUTES,
        CommonModule,
        ChartsModule,
        FormsModule,
        ReactiveFormsModule
    ]
})

export class PagesModule { }
