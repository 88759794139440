import { Injectable, EventEmitter } from '@angular/core';


@Injectable({
    providedIn: 'root'
  })

  export class ModalSelectuserService {
    public ocultoMUserselect = 'oculto';
    public notificaion: EventEmitter<any> = new EventEmitter<any>();


    constructor() {

    }


    cerrarModal() {
        this.ocultoMUserselect = 'oculto';
    }

    MostrarModalNuevo() {
        this.ocultoMUserselect = '';
    }


  }

