import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../../services/apirest/auth.service';


@Injectable({
  providedIn: 'root'
})
export class VerifiGuardGuard implements CanActivate {
  constructor(public _USUARIO: AuthService,
              public router: Router) {

}

canActivate() {
  // console.log('veri');
  if (this._USUARIO.estaloguado()) {
    if ( this._USUARIO.usuario.confirmado === '1') {
      return true;
    } else  {
      this.router.navigate(['/login']);
      return false;
    }
  } else {
    this.router.navigate(['/login']);
    return false;
  }

}
}
