import { Component, OnInit, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators  } from '@angular/forms';
import { ServicedataService } from './../../services/service.index';
import { Camposestatus  } from '../../interfaces/campos';
import Swal from 'sweetalert2';
import { AuthService } from '../../services/apirest/auth.service';

// npm install moment --save
import * as moment from 'moment';

@Component({
  selector: 'app-estatus',
  templateUrl: './estatus.component.html',
  styles: []
})
export class EstatusComponent implements OnInit {
  formae: FormGroup;
  txtfini = this.dataservice.loscritestatus.fini;
  txtffin = this.dataservice.loscritestatus.ffin;
  txtverdias = this.dataservice.loscritestatus.dias;
  txtversitios = this.dataservice.loscritestatus.sitios;
  lositem = '';

  constructor(public activatedRoute: ActivatedRoute,
              public dataservice: ServicedataService,
              public router: Router,
              public SERVUSER: AuthService ) {

    this.formae = new FormGroup({
      ffini: new FormControl( null , [Validators.required]),
      fffin: new FormControl( null , [Validators.required] ),
      verdias: new FormControl( false  ),
      versitios: new FormControl( false  )
    }  );
   }

  ngOnInit() {
    // console.log(this.SERVUSER.usuario.rol);
  }

  onClickdetalle2e() {
    if (this.formae.invalid) { return; }


    const fecha1 = moment(this.formae.value.ffini);
    const fecha2 = moment(this.formae.value.fffin);

    if (fecha2.diff(fecha1, 'days') > 31) {
      Swal.fire({
        text: String('Los peridos son maximo 31 dias'),
        icon: 'warning',
        confirmButtonColor: '#006837',
        confirmButtonText: 'Cerrar'
      }).then((result) => {
      });
      return;
    }

    if (fecha2.diff(fecha1, 'days') < 0 ) {
      Swal.fire({
        text: String('Verifica las fechas'),
        icon: 'warning',
        confirmButtonColor: '#006837',
        confirmButtonText: 'Cerrar'
      }).then((result) => {
      });
      return;
    }


    this.dataservice.loscritestatus.fini = this.formae.value.ffini ;
    this.dataservice.loscritestatus.ffin = this.formae.value.fffin;
    this.dataservice.loscritestatus.sitios = this.formae.value.versitios ;
    this.dataservice.loscritestatus.dias = this.formae.value.verdias;

    this.lositem = this.formae.value.ffini + '|' +
                   this.formae.value.fffin  + '|' +
                   this.formae.value.verdias + '|' +
                   this.formae.value.versitios;

    this.verstatus( this.lositem);

  }



  async verstatus(items: string ) {

    Swal.fire({
      title: 'Espere',
      allowOutsideClick: false,
      icon: 'info'
    });
    Swal.showLoading();

    const valido = await  this.dataservice.verstatus(items );
    Swal.close();

    switch ( valido ) {
      case true:
        break;
      case 'No hay datos':
        Swal.fire({
          text: 'No hay coincidencias',
          icon: 'warning',
          confirmButtonColor: '#006837',
          confirmButtonText: 'Cerrar'
        }).then((result) => {
        });
        break;
      default:
          Swal.fire({
            text: String(valido),
            icon: 'warning',
            confirmButtonColor: '#006837',
            confirmButtonText: 'Cerrar'
          }).then((result) => {
          });
          break;
    }


   }

   verdatelle(taxista: Camposestatus, campo: string) {

     const item =  this.dataservice.loscritestatus.fini + '|' +
     this.dataservice.loscritestatus.ffin + '|' +
     taxista.fecha + '|' +
     campo;
     this.dataservice.campobusqueda = 'estatus';
     this.router.navigate(['/busqueda', item]);
   }

   FieldsChange(evt) {

        this.dataservice.lasfilaslestatus = [];
   }

}
