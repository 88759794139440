
<!-- <form ngNativeValidate #f="ngForm"> -->

  <div class="row" *ngIf="dataservice.selidempresa == '0'">
    <div class="col-md-6 mb-3"> 
        <select #empresel  class="form-control" 
            (change)="buscarempresa(empresel.value)"
            name="bcampoempresa" 
                [(ngModel)] = "dataservice.selidempresa" >                          
                <option *ngFor="let e of ususervrce.catarrayempresas" [value]="e.idempresa">
                {{ e.empresa }} 
                </option>                                 
        </select>      
    </div>

    <div class="col-sm"> 
        <button type="button" 
          (click)="Prinmt( this.dataservice.parapdf)" class="btn btn-link" data-toggle="tooltip" data-placement="top" *ngIf="(flagprinter == true)" title="Reimprimir" >
          <i class="fa fa-print fa-lg" aria-hidden="true"></i> Folio servicio: 
          {{dataservice.folioasignado}}</button> 
    </div>
    
  </div>


    <form [formGroup]="forma"   *ngIf="dataservice.selidempresa != '0'">
        <div class="row">
            <div class="col-md-6 mb-3"> 
                            <ul class="nav nav-tabs" id="pills-tab" role="tablist">
                                <li class="nav-item" >
                                <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Destinatario</a>
                                </li>
                                <li class="nav-item">
                                <a class="nav-link" id="pills-nuevo-tab" data-toggle="pill" href="#pills-nuevo" role="tab" aria-controls="pills-nuevo" aria-selected="false">Tipo de servicio</a>
                                </li>                         
                            </ul>
                            <div class="tab-content" id="pills-tabContent">
                                <!-- tipo INI -->
                                <div class="tab-pane fade" id="pills-nuevo" role="tabpanel" aria-labelledby="pills-nuevo-tab"> 
                                    
                                    <!-- ini card tipo -->
                                        <div class="card">
                                        <div class="card-header">
                                            <div class="container">
                                              <div class="row">
                                                  <div class="col-sm-4">
                                                    <button [disabled]="(dataservice.lasdescripciones.length!=0)" (click)="btnbuscar('tipo')"class="btn btn-primary">Buscar tipo</button>
                                                  </div>

                                             
                                              </div>
                                            
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="form-group row">
                                            <label for="formGroupExampleInputD" class="col-sm-2 col-form-label">Clave Catalogo</label>
                                            <div class="col-sm-10">                                         
                                                <input type="text" 
                                                       class="form-control"
                                                       maxlength ="100"
                                                       formControlName="txttipocuenta"  
                                                       [(ngModel)]= "dataservice.Elid.cuenta" 
                                                       id="formGroupExampleInputD"  readonly required>                     
                                            </div>
                                            </div>
                                            
                                            <div class="form-group row">
                                            <label for="formGroupExampleInputCalle" class="col-sm-2 col-form-label">Descripcion</label>
                                            <div class="col-sm-10">                                         
                                                <input type="text" 
                                                       class="form-control"
                                                       maxlength ="250"
                                                       formControlName="txttipodescripcion"  
                                                       [(ngModel)]= "dataservice.Elid.Descripcioncta"
                                                       
                                                       id="formGroupExampleInputcalle" readonly  required>                     
                                            </div>
                                            </div>
                             
                                            
                                        </div>
                                        </div>
                                    <!-- ----------------------tipo card  fin-->
                                </div>
                                <!-- tipo FIN -->

                                <!-- destinatario INI -->
                                <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                    <!-- CARD destinatario INI -->
                                    <div class="card">
                                        <div class="card-header">
                                            <div class="container">
                                            <div class="row">
                                                <div class="col-sm-4">
                                                    <button  [disabled]="(dataservice.lasdescripciones.length!=0)"
                                                    (click)="btnbuscar('destinatario')"class="btn btn-primary">Buscar destinatario</button>
                                                </div>

                                                <div class="col-sm"> 
                                                    <button type="button" 
                                                          (click)="Prinmt( this.dataservice.Elid)" 
                                                          class="btn btn-link" 
                                                          data-toggle="tooltip" 
                                                          data-placement="top" 
                                                          *ngIf="(flagprinter == true)" 
                                                          title="Reimprimir" >
                                                          <i class="fa fa-print fa-lg" aria-hidden="true"></i>
                                                    </button>
                                                </div>
                                                
                                            </div>
                                            </div>
                                        </div>


                                        <div class="card-body">
                                            <div class="form-group row">
                                                  <label for="formGroupExampleInputR" class="col-sm-2 col-form-label">Nombre</label>
                                                  <div class="col-sm-10">                                         
                                                      <input type="text" 
                                                            class="form-control" 
                                                            formControlName="txtdestinatarionombre"  
                                                            maxlength ="100"
                                                            [(ngModel)]= "dataservice.ElidRemitente.nombre"
                                                            id="formGroupExampleInputR"   readonly 
                                                            required>                     
                                                  </div>
                                            </div>
                                            
                                            <div class="form-group row">
                                                  <label for="formGroupExampleInputCalleR" class="col-sm-2 col-form-label">Tipo cuenta</label>
                                                          <div class="col-sm-10">                                         
                                                              <input type="text"
                                                              maxlength ="200"
                                                                    class="form-control" 
                                                                    formControlName="txtdestinatariotipocuenta"  
                                                                    [(ngModel)]= "dataservice.ElidRemitente.tipocuenta"
                                                                    id="formGroupExampleInputcalleR"  readonly  required>                     
                                                          </div>
                                            </div>
                            
                                            <div class="form-group row">
                                                  <label for="formGroupExampleInputColR" class="col-sm-2 col-form-label">Cuenta</label>
                                                  <div class="col-sm-10">                                         
                                                      <input type="text" 
                                                            class="form-control" 
                                                            maxlength ="100"
                                                            formControlName="txtdestinatariocuenta"  
                                                              [(ngModel)]= "dataservice.ElidRemitente.cuenta" 
                                                            id="formGroupExampleInputColR"  readonly  required>                     
                                                  </div>
                                            </div>
                            
                                            
                                            
                                            <div class="row">
                                            <div class="col-md-6 mb-3">   
                                                    <div class="form-group row">
                                                    <label for="formGroupExampleInputedoR" class="col-sm-4 col-form-label">Banco</label>
                                                    <div class="col-sm-8">                                         
                                                        <input type="text"
                                                        maxlength ="100"
                                                               class="form-control" 
                                                               formControlName="txtdestinatariobanconombre"  
                                                               [(ngModel)]= "dataservice.ElidRemitente.banconombre" 
                                                               id="formGroupExampleInputedoR"  readonly  required>                     
                                                    </div>
                                                    </div>
                                            </div>
                                     
                                            </div>
                                            
                                    
                                            
                                        </div>
                                        </div>
                                    <!-- CARD destinatario FIN -->
                                </div>
                                <!-- destinatario FIN -->
                            </div>
          </div>


          <!--    >>>>>>>>>>>>>>>>>>>>>>>>>>>>> INICIA SIG CAMPOA complementarios >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>INI-->
          <div class="col-md-6 mb-3">
            <div class="card" >
                <h5 class="card-header">
                  Datos Complementarios
                </h5>
                <div class="card-body">
                    <div class="row">
                      <div class="col-md-6 mb-3">
                                

                         
                                <label for="inputedo2propo" class="col-form-label">
                                    <div *ngIf="forma.controls['txtproposito'].invalid && 
                                                (forma.controls['txtproposito'].dirty || forma.controls['txtproposito'].touched)" class="text-danger">                                            
                                      <div *ngIf="forma.controls['txtproposito'].errors.pattern || forma.controls['txtproposito'].errors.required">
                                        Proposito del gasto* (requerido)
                                      </div>
                                    </div>
                                    <div *ngIf="forma.controls['txtproposito'].valid || (!forma.controls['txtproposito'].dirty && !forma.controls['txtproposito'].touched)"  >
                                      Proposito del gasto</div> 
                                </label>
                                 
                                          
                                        
                                                  <select class="form-control" *ngIf="(dataservice.lasdescripciones.length==0)"
                                                          formControlName="txtproposito"  
                                                          [(ngModel)]="txtproposito"  
                                                          id="inputedo2propo"   
                                                            >
                                                    <option value="">Selecciona...</option>
                                                    <option value="Reembolso">Reembolso</option>
                                                    <option value="Pago Anticipado">Pago Anticipado</option>                      
                                                  </select>
                                                  
                                           
                                                  <input 
                                                  type="text"  
                                                  class="form-control" 
                                                  *ngIf="(dataservice.lasdescripciones.length!=0)" 
                                                  value="{{forma.controls['txtproposito'].value}}" 
                                                  readonly="false" >  
                                   
                                
                      </div>
                      <div class="col-md-6  mb-3">
                                
                               
                                <label for="inputedo2rfc" class="col-form-label">
                                    <div *ngIf="forma.controls['txtrfc'].invalid && 
                                                (forma.controls['txtrfc'].dirty || forma.controls['txtrfc'].touched)" class="text-danger">                                            
                                      <div *ngIf="forma.controls['txtrfc'].errors.pattern || forma.controls['txtrfc'].errors.required">
                                        RFC de quien factura* (requerido)
                                      </div>
                                    </div>
                                    <div *ngIf="forma.controls['txtrfc'].valid || (!forma.controls['txtrfc'].dirty && !forma.controls['txtrfc'].touched)"  >
                                      RFC de quien factura</div>
                                </label>
                                  
                                    <input type="text" 
                                        [(ngModel)]="txtrfc"
                                          class="form-control"
                                          maxlength="13"
                                          [readonly]="(dataservice.lasdescripciones.length!=0)"
                                          formControlName ="txtrfc"                           
                                          id="inputedo2rfc">
              
                                   
                               

                      </div>
                    </div>
                    <div class="row">
                            <div class="col-md-6  mb-3">
                              <label for="inputedo2folfatu" class="col-form-label">
                                <div *ngIf="forma.controls['txtofoliofactura'].invalid && 
                                (forma.controls['txtofoliofactura'].dirty || forma.controls['txtofoliofactura'].touched)" class="text-danger">                                            
                                <div *ngIf="forma.controls['txtofoliofactura'].errors.pattern  ">
                                  Folio Factura* sin caracteres extraños
                                </div>
                              </div>
                              <div *ngIf="forma.controls['txtofoliofactura'].valid || (!forma.controls['txtofoliofactura'].dirty && !forma.controls['txtofoliofactura'].touched)"  >
                                Folio Factura</div>
                              </label>
                              <div >                                         
                                  <input type="text" 
                                  [(ngModel)]="txtofoliofactura"
                                        class="form-control"
                                        maxlength="20"
                                        [readonly]="(dataservice.lasdescripciones.length!=0)"
                                        formControlName ="txtofoliofactura"                           
                                        id="inputedo2folfatu" >    
                            </div>
                          </div>


                          <div class="col-md-6  mb-3">
                            <label for="inputedo2folfatumonto" class="col-form-label">
                              <div *ngIf="forma.controls['txtmontofactura'].invalid && 
                              (forma.controls['txtmontofactura'].dirty || forma.controls['txtmontofactura'].touched)" class="text-danger">                                            
                              <div *ngIf="forma.controls['txtmontofactura'].errors.pattern  ">
                                Monto Solicitado* sin caracteres extraños
                              </div>
                            </div>
                            <div *ngIf="forma.controls['txtmontofactura'].valid || (!forma.controls['txtmontofactura'].dirty && !forma.controls['txtmontofactura'].touched)"  >
                              Monto Solicitado</div>
                            </label>
                            <div >                                         
                                <input type="text" 
                                [(ngModel)]="txtmontofactura"
                                      class="form-control"
                                      maxlength="50"
                                      [readonly]="(dataservice.lasdescripciones.length!=0)"
                                      formControlName ="txtmontofactura"                           
                                      id="inputedo2folfatumonto" >    
                          </div>
                        </div>

                   </div>


                   <div class="row"> 
                    <div class="col-md-12 mb-3">
                        <div class="card">
                          <div class="card-header">
                            Clasificacion del gasto                              
                          </div>
                          <div class="card-body">
          
                                  <div class="row"> 
                                                  <div class="col-md-6  mb-3"> 
                                                                    <label for="inputeproeyy" class="col-form-label">
                                                                      <div *ngIf="forma.controls['txtproyecto'].invalid && 
                                                                                  (forma.controls['txtproyecto'].dirty || forma.controls['txtproyecto'].touched)" class="text-danger">                                            
                                                                        <div *ngIf="forma.controls['txtproyecto'].errors.pattern || forma.controls['txtproyecto'].errors.required">
                                                                          Proyecto* (requerido)
                                                                        </div>
                                                                      </div>
                                                                      <div *ngIf="forma.controls['txtproyecto'].valid || (!forma.controls['txtproyecto'].dirty && !forma.controls['txtproyecto'].touched)"  >
                                                                        Proyecto* <i *ngIf="cargafull == true" class="fa fa-circle-o-notch fa-spin fa-1x fa-fw"></i></div>
                                                                       
                                                                  </label>
                                                
                                                          <!-- // combo.options[combo.selectedIndex].text -->
                                                        
                                                                  <select #proysel class="form-control" 
                                                                          *ngIf="(dataservice.lasdescripciones.length==0)"
                                                                          formControlName="txtproyecto"  
                                                                          [(ngModel)]="txtproyecto"  
                                                                          id="inputeproeyy"
                                                                          (change)="buscarProyecto(proysel.value, $event.target.options[$event.target.selectedIndex].text)"  >
                                                                    <option selected value="">Selecciona...</option>
                                                                    <option *ngFor="let e of ususervrce.catarrayproyectos" [value]="e.idcatdetalle">
                                                                      {{ e.descripcion }} 
                                                                      </option>                      
                                                                  </select>

                                                                  <input type="text"  class="form-control" *ngIf="(dataservice.lasdescripciones.length!=0)" value="{{dataservice.txtformproyecto}}" readonly="false" >  
                                                          
                                                </div>
        
        
                                                <div class="col-md-6  mb-3">                                    
                                                      <label for="inputeproeyyfullff" class="col-form-label">
                                                        <div *ngIf="forma.controls['txtfullfill'].invalid && 
                                                                    (forma.controls['txtfullfill'].dirty || forma.controls['txtfullfill'].touched)" class="text-danger">                                            
                                                          <div *ngIf="forma.controls['txtfullfill'].errors.pattern || forma.controls['txtfullfill'].errors.required">
                                                            Full Filment* (requerido)
                                                          </div>
                                                        </div>
                                                        <div *ngIf="forma.controls['txtfullfill'].valid || (!forma.controls['txtfullfill'].dirty && !forma.controls['txtfullfill'].touched)"  >
                                                          Full Filment  <i *ngIf="cargaplaza == true" class="fa fa-circle-o-notch fa-spin fa-1x fa-fw"></i> 
                                                        </div>
                                                  
                                                    </label>
                                                     
                                                    <select #fullsel class="form-control" formControlName="txtfullfill"  [(ngModel)]="txtfullfill"  id="inputeproeyyfullff"
                                                          *ngIf="(dataservice.lasdescripciones.length==0)"
                                                            (change)="buscarfull(fullsel.value, $event.target.options[$event.target.selectedIndex].text)" >    
                                                      <option selected value="">Selecciona...</option>
                                                      <option *ngFor="let e of dataservice.catalogoFullFilment" [value]="e.id">
                                                        {{ e.descripcion }} 
                                                        </option>                      
                                                    </select>
                                                    <input type="text"  class="form-control" *ngIf="(dataservice.lasdescripciones.length!=0)" value="{{dataservice.txtformfullfilment}}" readonly="false" >  

                                              </div>
          
                                  </div>


                                  <div class="row"> 
                                    <div class="col-md-6  mb-3"> 
                                                      <label for="inputepalza" class="col-form-label">
                                                        <div *ngIf="forma.controls['txtplaza'].invalid && 
                                                                    (forma.controls['txtplaza'].dirty || forma.controls['txtplaza'].touched)" class="text-danger">                                            
                                                          <div *ngIf="forma.controls['txtplaza'].errors.pattern || forma.controls['txtplaza'].errors.required">
                                                            Plaza* (requerido)
                                                          </div>
                                                        </div>
                                                        <div *ngIf="forma.controls['txtplaza'].valid || (!forma.controls['txtplaza'].dirty && !forma.controls['txtplaza'].touched)"  >
                                                          Plaza*</div>
                                                  
                                                    </label>
                                  
                                            
                                          
                                                    <select #selectpalza class="form-control" formControlName="txtplaza"  [(ngModel)]="txtplaza"  id="inputepalza"
                                                        (change)="buscarplaza(  $event.target.options[$event.target.selectedIndex].text)" 
                                                        *ngIf="(dataservice.lasdescripciones.length==0)">   
                                                      <option selected value="">Selecciona...</option>                                                    
                                                      <option *ngFor="let e of dataservice.catalogoplaza" [value]="e.id" >
                                                        {{ e.descripcion }} 
                                                        </option>                      
                                                    </select>
                                                    <input type="text"  class="form-control" *ngIf="(dataservice.lasdescripciones.length!=0)" value="{{dataservice.txtformfplaza}}" readonly="false" >  
                                            
                                  </div>


                                  <div class="col-md-6  mb-3">                                    
                                        <label for="inputetxtruta" class="col-form-label">
                                          <div *ngIf="forma.controls['txtruta'].invalid && 
                                                      (forma.controls['txtruta'].dirty || forma.controls['txtruta'].touched)" class="text-danger">                                            
                                            <div *ngIf="forma.controls['txtruta'].errors.pattern">
                                              Ruta* sin caracteres extraños
                                            </div>
                                          </div>
                                          <div *ngIf="forma.controls['txtruta'].valid || (!forma.controls['txtruta'].dirty && !forma.controls['txtruta'].touched)"  >
                                            Ruta (en caso de ser gasto operativo)</div>
                                    
                                      </label>
                    
                              
                            
                     


                                      <input type="text" 
                                      [(ngModel)]="txtruta"
                                            class="form-control"
                                            maxlength="20"
                                            [readonly]="(dataservice.lasdescripciones.length!=0)"
                                            formControlName ="txtruta"                           
                                            id="inputetxtruta" >    
                                </div>

                    </div>

                                    
                          </div>
                        </div>
                    </div>
          
                </div>

                <div class="row"  *ngIf="this.forma.valid"> 
                  <div class="col-md-12 mb-3">
                    <div class="card">
                      <div class="card-header">
                        Descripcion de Servicio/Producto
                      </div>
                      <div class="card-body">
                        <form [formGroup]="formadetalle" >
                                  <div class="row"> 
                                                  <div class="col-md-6  mb-3"> 
                                                                    <label for="inputtxtcantidad" class="col-form-label">
                                                                      <div *ngIf="formadetalle.controls['txtcantidad'].invalid && 
                                                                                  (formadetalle.controls['txtcantidad'].dirty || formadetalle.controls['txtcantidad'].touched)" class="text-danger">                                            
                                                                        <div *ngIf="formadetalle.controls['txtcantidad'].errors.pattern || formadetalle.controls['txtcantidad'].errors.required">
                                                                          Cantidad* (requerido)
                                                                        </div>
                                                                      </div>
                                                                      <div *ngIf="formadetalle.controls['txtcantidad'].valid || (!formadetalle.controls['txtcantidad'].dirty && !formadetalle.controls['txtcantidad'].touched)"  >
                                                                        Cantidad </div> 
                                                                  </label>
                                                
                                                                  <input type="text" 
                                                                  [(ngModel)]="txtcantidad"
                                                                        class="form-control"
                                                                        maxlength="10"
                                                                        formControlName ="txtcantidad"                           
                                                                        id="inputtxtcantidad" >    
                                                        
                                                          
                                                </div>
        
        
                                                <div class="col-md-6  mb-3">                                    
                                                      <label for="inpitprecio" class="col-form-label">
                                                        <div *ngIf="formadetalle.controls['txtprecio'].invalid && 
                                                                    (formadetalle.controls['txtprecio'].dirty || formadetalle.controls['txtprecio'].touched)" class="text-danger">                                            
                                                          <div *ngIf="formadetalle.controls['txtprecio'].errors.pattern || formadetalle.controls['txtprecio'].errors.required">
                                                            Precio* (requerido)
                                                          </div>
                                                        </div>
                                                        <div *ngIf="formadetalle.controls['txtprecio'].valid || (!formadetalle.controls['txtprecio'].dirty && !formadetalle.controls['txtprecio'].touched)"  >
                                                          Precio 
                                                        </div>
                                                  
                                                    </label>

                                                    <input type="text" 
                                                    [(ngModel)]="txtprecio"
                                                          class="form-control"
                                                          maxlength="10"
                                                          formControlName ="txtprecio"                           
                                                          id="inpitprecio" > 
                                                    
                                              </div>
          
                                  </div>


                                  <div class="row"> 
                                          <div class="col-md-6  mb-3"> 
                                                            <label for="inputdescip" class="col-form-label">
                                                              <div *ngIf="formadetalle.controls['txtdescripcion'].invalid && 
                                                                          (formadetalle.controls['txtdescripcion'].dirty || formadetalle.controls['txtdescripcion'].touched)" class="text-danger">                                            
                                                                <div *ngIf="formadetalle.controls['txtdescripcion'].errors.pattern || formadetalle.controls['txtdescripcion'].errors.required">
                                                                  Descripción* (requerido)
                                                                </div>
                                                              </div>
                                                              <div *ngIf="formadetalle.controls['txtdescripcion'].valid || (!formadetalle.controls['txtdescripcion'].dirty && !formadetalle.controls['txtdescripcion'].touched)"  >
                                                                Descripción</div>
                                                        
                                                          </label>
                                                          <input type="text" 
                                                          [(ngModel)]="txtdescripcion"
                                                                class="form-control"
                                                                maxlength="100"
                                                                formControlName ="txtdescripcion"                           
                                                                id="inputdescip" > 
                                                  
                                        </div>


                                        <div class="col-md-6  mb-3">                                    
                                          <label for="inputbtuno" class="col-form-label">
                                                Acción
                                          </label>
                                          <button 
                                            type="submit" 
                                            class="btn btn-primary btn-block"
                                            [disabled]="!formadetalle.valid" 
                                            id="inputbtuno"    
                                            (click)="onClickingresar()" >Ingresar registro</button>
                                    
                                  
                          


                                          
                                        </div>

                              </div>
                        </form>
                                
                      </div>
                    </div>
                </div>


                </div>


                    <div class="row"> 
                        <div class="col-md-12 mb-3">
                            <div class="card">
                              <div class="card-header">
                                <div *ngIf="forma.controls['txtobservacion'].invalid && 
                                                (forma.controls['txtobservacion'].dirty || forma.controls['txtobservacion'].touched)" class="text-danger">                                            
                                      <div *ngIf="forma.controls['txtobservacion'].errors.pattern">
                                        Observación* sin caracteres extraños
                                      </div>
                                    </div>
                                    <div *ngIf="forma.controls['txtobservacion'].valid || (!forma.controls['txtobservacion'].dirty && !forma.controls['txtobservacion'].touched)"  >
                                      Observación</div>
                                  
                              </div>
                              <div class="card-body">
              
                                        <textarea type="text"                    
                                        class="form-control" 
                                        formControlName="txtobservacion"                  
                                        maxlength="150"     
                                        [(ngModel)]="txtobservacion"            
                                        rows="2"></textarea>

                                        
                              </div>
                            </div>
                        </div>
              
                    </div>
              
                  
                </div>
            </div>
          </div> 
          <!--    >>>>>>>>>>>>>>>>>>>>>>>>>>>>> INICIA SIG CAMPOAS complementarios >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>FIN-->

        </div>
       


      </form>

          <!--    >>>>>>>>>>>>>>>>>>>>>>>>>>>>> BOTONES de envio >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>INI-->
      <div class="row" *ngIf="dataservice.selidempresa != '0'">
        <div class="col-md-6 mb-3">
          
        </div>
        <div class="col-md-3 mb-3">
          <button type="submit" class="btn btn-primary btn-block"   
          (click)="onClickcancel()" >Cancelar</button>
        </div>

        <div class="col-md-3 mb-3">
          <button type="submit" class="btn btn-primary btn-block"  *ngIf="(flagprinter == false)" 
          [disabled]="(!forma.valid  )"  
          (click)="onClickOK()" >Enviar</button>
        </div>
      </div>
      <!--    >>>>>>>>>>>>>>>>>>>>>>>>>>>>> BOTONES de envio >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>FIN-->
      
      <!-- >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>   detalle trx INI -->
      <div class="table-responsive" *ngIf="(dataservice.lasdescripciones.length!=0)" >
        <table  class="table table-striped table-bordered " >
        <thead class="thead-dark">            
          <tr>
             <th></th> 
             <th>Cantidad</th>
             <th>Precio</th>
             <th>Descripcion</th>
             <th>Importe</th>
            </tr>
        </thead>

        <tbody>
          <tr *ngFor="let item of dataservice.lasdescripciones; let  i = index">
                    <td>
                      <button (click)="borraritem(i)" class="btn btn-danger">
                    <i class="fa fa-trash-o"></i>
                    </button> 
                  </td> 
                    <td>
                      {{ item.cantidad    }}
                    </td>

                    <td>
                      {{ item.precio    }}
                    </td>

                    <td>
                      {{ item.descripcion    }}
                    </td>

                    <td>
                      {{ item.importe    }}
                    </td>
                  
          </tr>
        </tbody>
      </table>

      </div>

      <!-- >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>   detalle trx FIN -->


<!--    >>>>>>>>>>>>>>>>>>>>>>>>>>>>> INICIA MODA >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>INI-->            
      <div class="fondo-negro animated fadeIn" [ngStyle]="{'display':display}">
        <div class="modal bd-example-modal-lg"   tabindex="-1" role="dialog"   style="display:block;"  >
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="btn btn-primary" (click)="cerrarModal()" >Cerrar</button>
                     
                  </div>
                <div class="modal-body"> 
                      <div class="container">
                          <div class="row">  
                   
                            
                           <div [formGroup]="formabuscar" class="col-12" >
                                <div class="row">

                                      <div class="col-4">
                                        <select class="form-control" 
                                              name="bcampo" 
                                              [(ngModel)] = "dataservice.elcamposel" 
                                              formControlName="combobuscatr" 
                                              (change)="resetdata($event)" >                          
                                              <option *ngFor="let rol3 of ArrayRespuesta" [value]="rol3.id">
                                                {{ rol3.descripcion }} 
                                              </option>                                 
                                        </select>
                                      </div>
                                      
                                      <div class="col-6">
                                          <input type="text" 
                                          class="form-control"  
                                          formControlName="txttextbuscar"                         
                                          placeholder="Captura... despues Enter" 
                                          #txttermino
                                          (keyup.enter) ="buscarbd(txttermino.value)"
                                          [(ngModel)]="txtqbusco" />
                                      </div>

                                      <div class="col-2">
                                            <i *ngIf="iconbusc != ''" class="fa fa-circle-o-notch fa-spin fa-1x fa-fw"></i>
                                            <label *ngIf="iconbusc == ''">
                                                    <div *ngIf="formabuscar.controls['txttextbuscar'].invalid && 
                                                    (formabuscar.controls['txttextbuscar'].dirty || formabuscar.controls['txttextbuscar'].touched)" class="text-danger">                                            
                                                      <div *ngIf="formabuscar.controls['txttextbuscar'].errors.pattern  ">
                                                          Verifica
                                                      </div>
                                                    </div>
                                                    <div *ngIf="formabuscar.controls['txttextbuscar'].valid || (!formabuscar.controls['txttextbuscar'].dirty && !formabuscar.controls['txttextbuscar'].touched)"  >{{dataservice.lasfilasBlosomtermino.length }}</div>
                                            </label>
                                             
                                      </div> 
                                 </div> 
                                
                            </div>

                             </div>
                       </div>  


                      <div class="row"><br></div>
                        <div class="row align-items-center">
                          <div class="col-12">
                                      <input type="text" class="form-control" 
                                          name="filterPost" 
                                          placeholder="Filtrar..."   [(ngModel)] = "filterPost">
                          </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                              <div id="div1580px"> 
                                    <table  class="table table-striped table-bordered" >
      
                                        <thead class="thead-light">
                                        
                                        <tr>      
                                          <th scope="col"></th>  
                                                <th scope="col" *ngIf="seleccion=='destinatario'">Nombre</th>
                                                <th scope="col">Cuenta</th>            
                                                <th scope="col" *ngIf="seleccion=='destinatario'">Banco</th>
                                                <th scope="col" *ngIf="seleccion!='destinatario'">Descripcion</th>
                                               
                                          </tr>
                                        </thead>
                                                    <tbody> 
                                                            <!-- si marca errro add import { CommonModule } from '@angular/common'; -->
                                                            <tr *ngFor="let item of dataservice.lasfilasBlosomtermino | filtername: filterPost : compofilter">
                                                              <!-- <tr *ngFor="let item of dataservice.lasfilasBlosomtermino  ">    -->
                                                                 <td>
                                                                  <button type="button" (click)="onClickzoom( item )" class="btn btn-link"><i class="fa fa-chevron-right fa-lg" aria-hidden="true"></i></button>
                                                                </td>  
                                                                  <td *ngIf="seleccion=='destinatario'">
                                                                 {{ item.nombre}} 
                                                              </td>                         
                                                              <td>
                                                                  {{ item.cuenta}}
                                                              </td>
                                                              <td *ngIf="seleccion=='destinatario'">
                                                                  {{ item.banconombre}}
                                                              </td> 
                                                              <td *ngIf="seleccion!='destinatario'">
                                                                {{ item.Descripcioncta}}
                                                            </td> 
                                                       
                                                          
                                                          </tr>  
                                                    </tbody>
                                    </table>
                                  </div>
                          </div>
                      </div>
                    
               
                </div>
            </div>
        </div>
        </div>

<!--    >>>>>>>>>>>>>>>>>>>>>>>>>>>>> INICIA MODA >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>FIN-->                    