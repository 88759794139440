import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServicedataService } from './../../services/service.index';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: []
})
export class HeaderComponent implements OnInit {

  constructor(public router: Router,
              public dataservice: ServicedataService
  ) { }

  ngOnInit() {
  }

  buscar( termino: string ) {
    if (termino.length > 0 ) {
         this.dataservice.campobusqueda =  'folio';
         this.router.navigate(['/busqueda', termino]);
    }
  }
}

