import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/apirest/auth.service';
import { NgForm } from '@angular/forms';
import { UsuarioLogin, Usuario } from '../../model/usuario.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { ModalUuserService } from '../../components/modal-user/modal-user.service';
import { UsuariointerfaseUNO} from '../../interfaces/intersaseusuario';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-modallogin',
  templateUrl: './modallogin.component.html',
  styleUrls: ['./modallogin.component.css']
})
export class ModalloginComponent implements OnInit {
 elusuario = ''; // adminmaster
 elpass = ''; // Admin2018
 verisoon = environment.version;
 formanvo: FormGroup;
  constructor(public autser: AuthService,
              private router: Router,
              private servmodaluser: ModalUuserService) {

   }

  ngOnInit() {
    this.formanvo = new FormGroup({
      correo: new FormControl( null , [Validators.required ]),
      registro: new FormControl( null , [Validators.required , Validators.minLength(3)] ),
      registro2: new FormControl( null , [Validators.required , Validators.minLength(3)]),
      condiciones: new FormControl( false )
    }, { validators: this.sonIguales( 'registro', 'registro2' )  } );

    //console.log(environment.production);
    if (environment.production === false){

      this.elusuario = 'gabe'; // adminmaster
      this.elpass = 'gabe2001'; // Admin2018
    }


  }

  sonIguales( campo1: string, campo2: string ) {

    return ( group: FormGroup ) => {

      const pass1 = group.controls[campo1].value;
      const pass2 = group.controls[campo2].value;
     // console.log(pass1 , pass2);
      if ( pass1 === pass2 ) {
        return null;
      }

      return {
        sonIguales: true
      };

    };

  }

  async  Onsubmit(form: NgForm) {
    if (form.invalid) { return; }
    this.autser.oculto = 'oculto';
    Swal.fire({
      title: 'Espere',
      allowOutsideClick: false,
      icon: 'info'
    });
    Swal.showLoading();

    const elusuario = new UsuarioLogin(form.value.Username, form.value.pass);
    const valido = await  this.autser.ProcesarLogin(elusuario);
    Swal.close();

    switch ( valido ) {
      case true:
      
        this.router.navigate(['/registrocontable']);
      

        break;
      default:
          Swal.fire({
            text: String(valido),
            icon: 'warning',
            confirmButtonText: 'Cerrar'
          }).then((result) => {
              this.autser.oculto = '';
          });
          break;
    }


  }

  async recordar() {
    this.autser.oculto = 'oculto';
    const confirmar = await Swal.fire({
      title: '¿Enviar al correo registrado?',
      icon: 'warning',
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      showConfirmButton: true,
      showCancelButton: true
    });

    if (confirmar.dismiss === Swal.DismissReason.cancel) {
      this.autser.oculto = '';
      return;
    }


    Swal.fire({
      title: 'Espere',
      allowOutsideClick: false,
      icon: 'info'
    });
    Swal.showLoading();

    const valido = await  this.autser.resetpass(this.elusuario);

    Swal.close();


    Swal.fire({
            // tslint:disable-next-line: max-line-length
            text: 'Se envio clave de acceso a tu correo registrado, remitente: avisogruposiman@operacionsiman.com, verifica en correo basura y confirma que es un remitente permitido',
            icon: 'warning',
            confirmButtonText: 'Cerrar'
          }).then((result) => {
              this.autser.oculto = '';
          });



  }

  async registrarUsuario() {
    this.autser.oculto = 'oculto';


    const elcorreo = String(this.formanvo.value.correo.trim()).toLowerCase();


    if (elcorreo.includes('@suramexico.com') === false && elcorreo.includes('@sura-im.com') === false) {

      Swal.fire({
        text: 'Correo no permitido, verifica',
        icon: 'warning',
        confirmButtonText: 'Cerrar'
      }).then((result) => {
        this.autser.oculto = '';
      });
      return;
    }



    const confirmar = await Swal.fire({
      title: 'Solicitar ingreso',
      icon: 'warning',
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      showConfirmButton: true,
      showCancelButton: true
    });

    if (confirmar.dismiss === Swal.DismissReason.cancel) {
      this.autser.oculto = '';
      return;
    }



    Swal.fire({
      title: 'Espere',
      allowOutsideClick: false,
      icon: 'info'
    });
    Swal.showLoading();

    const valido = await  this.autser.nuevousu(elcorreo, this.formanvo.value.registro);
    Swal.close();


    Swal.fire({
            text: String(valido),
            icon: 'warning',
            confirmButtonText: 'Cerrar'
          }).then((result) => {
            this.autser.oculto = '';
          });
  }


}
