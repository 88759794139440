         <!-- Left Sidebar - style you can find in sidebar.scss  -->
        <!-- ============================================================== -->
        <aside class="left-sidebar">
            <!-- Sidebar scroll-->
            <div class="scroll-sidebar">
                <!-- Sidebar navigation-->
                <nav class="sidebar-nav">
                    <ul id="sidebarnav">
                        <li class="user-profile">
                            <a class="has-arrow waves-effect waves-dark" href="#" aria-expanded="false"><img src="../assets/images/users/profile.png" alt="user" /><span class="hide-menu"> {{ Usuario.username}} </span></a>
                            <ul aria-expanded="false" class="collapse">                                 
                                <li><a (click)="cambiarPass()" class="pointer">Cambiar contraseña</a></li>
                                <li><a (click)="cambiardatos()" class="pointer">Cambiar datos</a></li>
                                <li><a routerLinkActive="active"  (click)="ususervrce.logout()" class="pointer"><i class="fa fa-sign-out" aria-hidden="true"></i> Salir</a></li>                                
                            </ul>
                        </li>
                        <li class="nav-devider"></li>
                        <li class="nav-small-cap">OPCIONES</li>

                           <li *ngFor="let menu of _SIDEBAR.menu" > 
                         <a class="has-arrow waves-effect waves-dark"  aria-expanded="false"><i [class]="menu.icono"></i><span class="hide-menu">{{ menu.titulo }} <span class="label label-rouded label-themecolor pull-right">{{ menu.submenu.length}}</span></span></a>
                            <ul aria-expanded="false" class="collapse">
                                <li *ngFor="let submenu of menu.submenu">
                                    <a routerLinkActive="active" [routerLink]="[ submenu.url ]">{{ submenu.titulo}}</a>
                                </li>
                                
                            </ul>
                        </li>   

                        <li class="nav-small-cap">Version {{ verisoon }}</li>
                        
                    </ul>

                    
                </nav>
                <!-- End Sidebar navigation -->
            </div>
            <!-- End Sidebar scroll-->
          
              
        </aside>
        <!-- ============================================================== -->
        <!-- End Left Sidebar - style you can find in sidebar.scss  -->
        <!-- ============================================================== -->




        <!-- modal cambio passs -->
        <div class="fondo-negro animated fadeIn" [ngStyle]="{'display':display}">
        <div class="modal"   tabindex="-1" role="dialog"   style="display:block;" role="dialog">
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title"  ><strong>Cambiar contraseña</strong></h5>
                  
                </div>
                <div class="modal-body">
                  <form ngNativeValidate [formGroup]="forma" (ngSubmit)="registrarUsuario()"   id="loginform" >
                       
                    <div class="form-group ">
                        
                                <label for="pwi">Contraseña Actual</label>
                            <input formControlName="passwordini" name="passwordini" id="pwi" class="form-control" type="password" required >
                         
                    </div>
               
                    <div class="form-group">
                      
                            <label for="pwi1">Nueva contraseña</label>
                          <input formControlName="password" name="password" class="form-control" id="pwi1" type="password" minlength="6" required >
                       
                      <div class="form-group" *ngIf="forma.get('password').hasError('required') && (forma.get('password').dirty  ) || forma.get('password').hasError('minlength')">
                        <p class="text-danger">
                            Mínimo 6 caracteres
                        </p>
                    </div>
                  </div>
                
                    <div class="form-group">
                         
                                <label for="pwi2">Confirma contraseña</label>
                            <input formControlName="password2" name="password2" class="form-control" id="pwi2" type="password" minlength="6" required  >
                       
                        <div class="form-group" *ngIf="forma.get('password2').hasError('required') && (forma.get('password2').dirty ) || forma.get('password2').hasError('minlength')">
                            <p class="text-danger">
                                Mínimo 6 caracteres
                            </p>
                        </div>
                    </div>
                
                    <div class="form-group" *ngIf="forma.errors && !forma.pristine">
                        <p class="text-danger">
                            Las contraseñas deben ser iguales
                        </p>
                    </div>
                </form>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" (click)="cerrarModal()" >Cancelar</button>
                  <button type="button" [disabled]="!forma.valid"  class="btn btn-primary" (click)="registrarUsuario()">Aceptar</button>
                </div>
              </div>
            </div>
          </div>
        </div>


