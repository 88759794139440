import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UsuarioLogin,  Usuario, Catrespuestas , Losroles, Catempresas, Catproyecto } from './../../model/usuario.model';
import { environment } from '../../../environments/environment.prod';
import { HttpClient } from '@angular/common/http';
import { SidebarService } from '../shared/sidebar.service';
import * as moment from 'moment';
const URL = environment.sitiores;


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  oculto = 'oculto';

  ocultoMZoom = 'oculto';
  usuario: Usuario ;
  catresp: Catrespuestas;
  catarrayempresas: Catempresas[] = []; 
  catarrayproyectos:  Catproyecto[] = []; 
  catrol: Losroles;
  token: string;
  idusuario: string;
  rolename: string;
  lositems: string;


  constructor( private router: Router,
               private http: HttpClient,
               private sideb: SidebarService) {
                this.cargarStorage();
                }


  login() {
    // muestra el modal
    // <app-modallogin>
    this.oculto = '';
  }



estaloguado() {
  return (this.token.length > 5 ) ? true : false;
}

cargarStorage() {
  if (localStorage.getItem('token')) {
      // this.token = localStorage.getItem('token');
      // this.idusuario = localStorage.getItem('idusuario');
      // this.catresp = JSON.parse(localStorage.getItem('respuestas')); // que paso
      // this.catrol = JSON.parse(localStorage.getItem('roles'));
      // this.usuario = JSON.parse(localStorage.getItem('usuario'));
      // this.elmenu(this.usuario.rol, this.usuario.confirmado);
      // this.router.navigate(['/servicio']);
  } else {
      this.token = '';
      this.idusuario = null;
      this.usuario = null;
  }
}


   ProcesarLogin(usuario: UsuarioLogin) {

    const data = { username: usuario.Username, pass: usuario.pass} ;
 
    return new Promise( resolve => {
      this.http.post<any>(`${ URL }/Loginweb`, data )
        .subscribe( async resp => {
          if ( resp.error === false ) {

            this.guardarStorage( resp.idusuario, resp.token, resp.usuario, resp.catempresa.filas, resp.catproy.filas);
            resolve(true);
          } else {
            resolve( resp.mensaje);
          }
        }, error => {
          resolve(error);
        });

});
   }

resetpass(userid: string) {
  const data = {  idusuario: userid} ;
  const datatiem = 'C3dis1967' + moment().format('YYYYHHMMmmDD');

  return new Promise( resolve => {
    this.http.post<any>(`${ URL }/loginweb/obtenerpas/${ datatiem}`, data)
      .subscribe( async resp => {
       if ( resp.error === false  ) {
            resolve(true);
         } else {
            resolve(false);
        }
      }, error => {
        resolve(JSON.stringify(error));
      });
  });
}


   async guardarStorage( id: string, token: string, usuario: Usuario ,catemp: Catempresas[], catproy:Catproyecto[] ) {
    //if (JSON.parse(JSON.stringify(usuario)).confirmado === '1') {
            // localStorage.setItem('idusuario', id );
            // localStorage.setItem('token', token );
            // localStorage.setItem('usuario', JSON.stringify(usuario) );
            // // localStorage.setItem('respuestas', JSON.stringify(catresp) );
            // localStorage.setItem('roles', JSON.stringify(catrol) );
    //}
    // console.log( JSON.parse(JSON.stringify(usuario)).rol);
    await this.elmenu(JSON.parse(JSON.stringify(usuario)).rol, JSON.parse(JSON.stringify(usuario)).confirmado);
    this.idusuario = id;
    this.usuario = usuario;
    this.token = token;

    const customObj = new Catempresas();
    customObj.empresa ='Selecciona Empresa';
    customObj.idempresa ='0';
    this.catarrayempresas = catemp;
    this.catarrayempresas.push(customObj);

    this.catarrayproyectos = catproy;
    
    // this.catresp = catresp;
    // this.catrol = catrol;
    return;
  }

  async logout() {
    this.usuario = null;
    this.token = '';
    // localStorage.removeItem('idusuario');
    // localStorage.removeItem('token');
    // localStorage.removeItem('usuario');
    // localStorage.removeItem('respuestas');
    // localStorage.removeItem('roles');
    this.sideb.menu = [];
    this.router.navigate(['/login']);
  }



update_pass(previo: string, nuevo: string) {
  this.lositems  =   previo + '|' + nuevo ;
  const data = {  items: this.lositems, idusuario: this.idusuario } ;

  return new Promise( resolve => {
    this.http.post<any>(`${ URL }/loginweb/actualizar_pass/${ this.token }`, data)
      .subscribe( async resp => {
       if ( resp.error === false && resp.filasafectadas === 1 ) {
            resolve(true);
         } else {
            resolve(false);
        }
      }, error => {
        resolve(JSON.stringify(error));
      });
  });
}

nuevousu(correo: string, registro: string) {
  const data = { email: correo, idusuario: registro} ;
  const datatiem = 'C3dis1967' + moment().format('YYYYHHMMmmDD');

  console.log(`${ URL }/loginweb/altauser/${ datatiem}`);
  console.log(data);
 

  return new Promise( resolve => {
    this.http.post<any>(`${ URL }/loginweb/altauser/${ datatiem}`, data)
      .subscribe( async resp => {
       console.log(resp);
       if ( resp.error === true  ) {
            resolve(resp.respuesta.mensaje);
         } else {
            resolve(false);
        }
      }, error => {
        resolve(JSON.stringify(error));
      });
  });
}


tablauserrol( lositems: string ) {

  const data = {  items:  lositems, idusuario: this.idusuario } ;

  return new Promise( resolve => {
    this.http.post<any>(`${ URL }/loginweb/updateuser/${ this.token }`, data)
      .subscribe( async resp => {
       if ( resp.error === false  ) {
            resolve(true);
            } else if (resp.error === true && resp.respuesta.mensaje === 'existe') {
            resolve('existe');
        } else {
          resolve(false);
        }
      }, error => {
        resolve(JSON.stringify(error));
      });
  });
}


tablauserroldatosdatos( lositems: string ) {

  const data = {  items:  lositems, idusuario: this.idusuario } ;
  return new Promise( resolve => {
    this.http.post<any>(`${ URL }/loginweb/updateuserdatos/${ this.token }`, data)
      .subscribe( async resp => {
       if ( resp.error === false  ) {
            resolve(true);
        } else {
          resolve(false);
        }
      }, error => {
        resolve(JSON.stringify(error));
      });
  });
}


async elmenu(elrol: string, confirmado: string) {
// 1 admin
// 2 remitente
// 4 captura
// 10 reposrte
if (confirmado === '0') {
        elrol = 'No confirmado';
}

switch (elrol) {
  case '1': // admin
  this.sideb.menu = [
    {
      titulo: 'Reporte',
      icono: 'mdi mdi-chart-timeline',
        submenu: [
          { titulo: 'Detalle', url: '/progress'},
          { titulo: 'Estatus', url: '/estatus'}
        ]
    },
    // {
    //   titulo: 'Seguimiento',
    //   icono: 'mdi mdi-gauge',
    //     submenu: [
    //       { titulo: 'Servicio', url: '/servicio'},
    //       { titulo: 'Correspondencia', url: '/recepcionc'},
    //       { titulo: 'Captura', url: '/captura'},
    //       { titulo: 'Captura masiva', url: '/capturamasiva'},
    //       { titulo: 'Captura por archivo', url: '/importar'},
    //       { titulo: 'Guia especial', url: '/guiaespecial'}
    //     ]
    // } ,
    {
      titulo: 'Administración',
      icono: 'mdi mdi-eye',
        submenu: [
          { titulo: 'Usuarios', url: '/usuarios'}
        ]
    }
  ];
  break;
  case '2': // remitente
    this.sideb.menu = [
      {
        titulo: 'Acceso a',
        icono: 'mdi mdi-chart-timeline',
          submenu: [
            { titulo: 'Servicio', url: '/servicio'},
            { titulo: 'Buscar', url: '/progress'}

          ]
      } ];
    break;
  case '10': // reporte
    this.sideb.menu = [
      {
        titulo: 'Reporte',
        icono: 'mdi mdi-chart-timeline',
          submenu: [
            { titulo: 'Detalle', url: '/progress'},
            { titulo: 'Estatus', url: '/estatus'}
          ]
      },
      {
        titulo: 'Seguimiento',
      icono: 'mdi mdi-gauge',
        submenu: [
          { titulo: 'Servicio', url: '/servicio'}
        ]
      } ];
    break;
  default:
    this.sideb.menu = [];
    break;
}

}


}
