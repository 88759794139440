<div id="main-wrapper">
        <app-header></app-header>
        <app-sidebar></app-sidebar>
         <!-- Page wrapper  -->
              <!-- ============================================================== -->
              <div class="page-wrapper">
                  <!-- ============================================================== -->
                  <!-- Container fluid  -->
                  <!-- ============================================================== -->
                  <div class="container-fluid">
                      <app-breadcrumbs></app-breadcrumbs>
                      
                      
                      <router-outlet></router-outlet>
                      <!-- ============================================================== -->
                      <!-- Start Page Content -->
                      <!-- ============================================================== -->
                      <!-- <div class="row">
                          <div class="col-12">
                              <div class="card">
                                  <div class="card-body">
                                      This is some text within a card block.
                                  </div>
                              </div>
                          </div>
                      </div> -->
                      <!-- ============================================================== -->
                      <!-- End PAge Content -->
                      <!-- ============================================================== -->
      
      
                  </div>
                    <!-- EDN Container fluid  -->
                  <!-- ============================================================== -->
              </div>
              <!-- END Page wrapper  -->
              <!-- ============================================================== -->
      </div>
<!-- MOdals -->
<app-modal-user></app-modal-user>      
<app-modal-zoom></app-modal-zoom>
<app-modal-selectuser></app-modal-selectuser>