import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
// iconos Material-de-la-seccion-3/main/icon-material.html
menu: any = [];
  // menu: any = [
  //   {
  //     titulo: 'Prospectos',
  //     icono: 'mdi mdi-account-card-details',
  //       submenu: [
  //         { titulo: 'Reporte', url: '/dashboard'},
  //         { titulo: 'Reporte detallado', url: '/progress'},
  //         { titulo: 'Rechazos', url: '/graficas1'}
  //       ]
  //   },
  //   {
  //     titulo: 'Estatus',
  //     icono: 'mdi mdi-gauge',
  //       submenu: [
  //         { titulo: 'Capturados', url: '/dashboard'},
  //         { titulo: 'Con documentación', url: '/progress'}
  //       ]
  //   },
  //   {
  //     titulo: 'Administración',
  //     icono: 'mdi mdi-eye',
  //       submenu: [
  //         { titulo: 'Usuarios', url: '/usuarios'}
  //       ]
  //   }
  // ];




  constructor() { }
}
