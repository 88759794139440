<div class="row animated fadeIn">
    <div class="col-12">
        <div class="card">
            <div class="card-header">
                    <div class="text-right">
                            <button type="button"  (click)="onClicknvo()" class="btn waves-effect waves-light btn-rounded btn-primary">
                            <i class="fa fa-plus"></i> 
                            Crear Usuario externo
                          </button>
                        </div>

            </div>
            <div class="card-body">

                
                <div class="row">
                    <div class="col-2">
                            <select   [(ngModel)]="txtbusca" class="form-control" id="st"  >
                                        
                                <option *ngFor="let rol of Arraystatus" [value]="rol.id">
                                {{ rol.descripcion }} 
                                </option>

                            </select>
                    </div>
                    <div class="col-8">
                            <input #input (keyup.enter)="buscarUsuario( input.value )" type="text" class="form-control" placeholder="Buscar...">
                    </div>
                 </div>
                
            </div>
        </div>
    </div>
</div>

<div class="row animated fadeIn" *ngIf="cargando">

    <div class="col-sm-12">

        <div class="alert text-center">
            <strong>Cargando</strong>
            <br>
            <i class="fa fa-refresh fa-spin fa-2x"></i>
            <br>
            <span>Espere por favor</span>
        </div>
    </div>
</div>


<div class="row animated fadeIn" *ngIf="!cargando">
    <div class="col-12">
        <div class="card">
            <div class="card-body">

                <!-- <h3 class="card-title">Usuarios registrados ( <small>{{ totalRegistros }}</small> )</h3> -->


                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th>Usuario</th>
                            <th>Nombre</th>
                            <th>Correo</th>
                            <th>Estatus</th>                            
                            <th>Rol</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let usuario of losusuario">
                            <td >
                                 <button type="button"  (click)="onClick(usuario)" class="btn btn-link">{{ usuario.username }}</button>
                            </td>
                             
                            <td>

                                {{ usuario.nombre }}

                            </td>
  
                            <td>

                                {{ usuario.correo }}

                            </td>
                            
                            <td>
                                 <label *ngIf="usuario.vigente !='1'" class="label label-danger">Bloqueado</label>
                                <label *ngIf="usuario.vigente == '1'" class="label label-info">Activo</label>  
                            </td>
                            <td>

                                {{ usuario.roldescrip }}

                            </td>
                            <!-- <td>
                                <button (click)="guardarUsuario( usuario )" class="btn btn-primary">
                                <i class="fa fa-save"></i>
                              </button>

                                <button (click)="borrarUsuario( usuario )" class="btn btn-danger">
                                  <i class="fa fa-trash-o"></i>
                                </button>
                            </td>
                        </tr> -->

                    </tbody>

                </table>

                <button *ngIf="this.losusuario.length>0" (click)="cambiarDesde(-5)" class="btn btn-secondary">
                  Anteriores
                </button>

                <button *ngIf="this.losusuario.length>0" (click)="cambiarDesde(5)" class="btn btn-secondary">
                    Siguientes
                </button>



            </div>
        </div>
    </div>
</div>



        <!-- modal cambio usuario externo -->
        <div class="fondo-negro animated fadeIn" [ngStyle]="{'display':display}">
            <div class="modal"   tabindex="-1" role="dialog"   style="display:block;" role="dialog">
                <div class="modal-dialog modal-dialog-centered" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title"  ><strong>Usuario Externo</strong></h5>
                      
                    </div>
                    <div class="modal-body">


                        <form class="login100-form validate-form flex-sb flex-w" 
                              ngNativeValidate 
                              [formGroup]="formanvo" 
                              (ngSubmit)="registrarUsuario()"  
                              id="loginformnva">
                                            <div class="wrap-input100 m-b-8">
                                                <input class="input100" 
                                                    formControlName="correo"
                                                    class="form-control" 
                                                    type="email" 
                                                    [email]="true"
                                                    pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"                                                
                                                    placeholder="correo">                                              
                                                <span class="focus-input100"></span>
                                            </div>
                                            <br>
                                            <div class="wrap-input100 m-b-8">
                                                <input class="input100" 
                                                    formControlName="correo2"
                                                    class="form-control" 
                                                    type="email" 
                                                    [email]="true"
                                                    pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"                                                
                                                    placeholder="correo confirma">                                              
                                                <span class="focus-input100"></span>
                                            </div>
                                            <br>
                                            <div class="wrap-input100 m-b-16">
                                            <input class="input100" 
                                            class="form-control" 
                                                    formControlName="registro"
                                                    type="password"                                            
                                                    placeholder="Nomina">                                            
                                            <span class="focus-input100"></span>
                                        </div>
                                        <br>
                                        <div class="wrap-input100 m-b-16">
                                            <input class="input100" 
                                            class="form-control" 
                                                type="password" 
                                                formControlName="registro2"
                                                placeholder="Confirma nomina">
                                                    
                                            <span class="focus-input100"></span>
                                        </div>
                                        <br>
                                        <br>
                                    

                                        <div class="row">
                                        
              
                                </div>
                        </form>
                        <div class="container-login1"> 
                            <form class="login100-form validate-form flex-sb flex-w">
                            
                            <label >Contraseña predeterminada : siman</label>
                          </form>
                        </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" (click)="cerrarModalux()" >Cancelar</button>
                      <button type="button" [disabled]="!formanvo.valid"  class="btn btn-primary" (click)="registrarUsuario()">Aceptar</button>  
                    </div>
                  </div>
                </div>
              </div>
            </div>