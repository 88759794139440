<div class="row animated fadeIn">
    <div class="col-12">
        <div class="card">            
            <div class="card-body text-center">
                <div class="row">
                    <div class="col">

                        <form ngNativeValidate [formGroup]="formacv"   id="loginformCaptu" >
                            <div class="form-row align-items-center">

                               
                                <div class="col-6"  *ngIf ="(dataservice.ResultINgresoBlosomcv.length==0)" >   
                                    <input type="text"   
                                           formControlName="ffactucv" 
                                           #input (keyup.enter)="ingresar(input.value)"    
                                           name="ffactu" 
                                           placeholder="Captura CV..."
                                           class="form-control" 
                                           id="f1"  
                                           required >
                                </div>
                                <div class="col-4">
                                    <label >Registros: {{ INgresoBlosomcv.length }}</label>
                                </div>                    
                                
                            </div>
                        </form>   
                    </div>
                    <div class="col">
                        <div class="col-auto" *ngIf="INgresoBlosomcv.length>0">
                            <button id="buttonbc2"
                            class="btn btn-primary mb-2 align-self-end" 
                            (click)="btnasig()"
                            >Asignar</button> 
                        </div>
                    </div>
                    <div class="col">
                        <button id="buttonbc"
                        class="btn btn-primary mb-2 align-self-end" 
                        (click)="resetgrid()"
                        >Limpiar rejilla</button>    
                    </div>
                </div>
            </div> 
        </div>       
    </div>    
</div>
 
<table  class="table table-striped table-bordered" >
             
    <thead class="thead-light">
    
    <tr>
        <th scope="col">CV</th>       
        <th scope="col">Prefijo</th>    
            <th scope="col">Factura</th>
            <th scope="col">EstatusPrevio</th>            
            <th scope="col">Mensajero</th>
            <th scope="col">Analisis</th>   
      </tr>
    </thead>
                <tbody> 
                        <!-- si marca errro add import { CommonModule } from '@angular/common'; -->
                        <tr *ngFor="let item of INgresoBlosomcv">
                            <td>
                                {{ item.cv}}
                            </td>
                            <td>
                                {{ item.Prefijo}}
                            </td>
                            <td>
                                {{ item.Factura}}
                            </td>
                          <td>
                              {{ item.estatus }}
                          </td>                         
                          <td>
                              {{ item.idmensajero}}
                          </td>
                          <td>
                              {{ item.descripcion}}
                          </td>      
                      </tr>  
                </tbody>
            </table>


            <div class="fondo-negro animated fadeIn" [ngStyle]="{'display':display}">
                <div class="modal bd-example-modal-lg"   tabindex="-1" role="dialog"   style="display:block;" role="dialog">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="btn btn-primary" (click)="cerrarModal()" >Cerrar</button>
                            <strong>Asignar</strong>
                          </div>
                        <div class="modal-body">

                            <input type="text" class="form-control" name="filterPost" placeholder="Buscar nombre..."   [(ngModel)] = "filterPost">

                        <br>


                            <table  class="table table-striped table-bordered" >
             
                                <thead class="thead-light">
                                
                                <tr>        
                                        <th scope="col">usuario</th>
                                        <th scope="col">nombre</th>            
                                        <th scope="col">apellidop</th>
                                        <th scope="col">iduser</th>
                                  </tr>
                                </thead>
                                            <tbody> 
                                                    <!-- si marca errro add import { CommonModule } from '@angular/common'; -->
                                                    <!-- <tr *ngFor="let item of dataservice.userasug | filtername: filterPost"> -->
                                                        <tr *ngFor="let item of dataservice.userasug  ">
                                                        <td>
                                                            <button type="button" (click)="onClickzoom( item )" class="btn btn-link">{{ item.usuario}}</button>
 
                                                        </td>
                                                      <td>
                                                          {{ item.nombre }}
                                                      </td>                         
                                                      <td>
                                                          {{ item.apellidop}}
                                                      </td>
                                                      <td>
                                                          {{ item.id}}
                                                      </td>      
                                                  </tr>  
                                            </tbody>
                                </table>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>


