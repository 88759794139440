<div class="row animated fadeIn">
    <div class="col-12">
        <div class="card">
 
            <div class="card-body text-center">
                
                
                  <form ngNativeValidate [formGroup]="formae"   id="loginformBuscar" >
                    <div class="form-row align-items-center">
 
                        <div class="col-auto">                        
                            <input type="date" formControlName="ffini"  [(ngModel)]="txtfini"  name="ffini" class="form-control" id="f1"  required placeholder="AAAA-MM-DD">
                          </div>
                          <div class="col-auto">                                                    
                            <input type="date" formControlName="fffin" [(ngModel)]="txtffin"  name="fffin" class="form-control" id="f2" required placeholder="AAAA-MM-DD">                            
                          </div>                         
                          <div class="col-auto">
                            <button type="submit" [disabled]="!formae.valid" class="btn btn-primary mb-2" (click)="onClickdetalle2e()">Mostrar</button>
                          </div>
                          <div class="col-auto">
                          <input name ="verdias" (change)="FieldsChange($event)" id="reset1" formControlName="verdias" 
                          [(ngModel)]="txtverdias" class="form-check-input" type="checkbox" id="gridCheckReset"  >
                          <label class="form-check-label" for="gridCheckReset" data-toggle="tooltip" data-html="true" title="Mostrar los dias en la rejilla">
                            Días
                          </label>
                        </div>  
                         
                        <div class="col-auto">
                          <input name ="versitios" (change)="FieldsChange($event)" id="reset3" formControlName="versitios"  
                          [(ngModel)]="txtversitios" class="form-check-input" type="checkbox" id="gridCheckResetRS"  >
                          <label class="form-check-label" for="gridCheckResetRS" data-toggle="tooltip" data-html="true" title="Mostrar intervalos en rejilla">
                            Intervalos
                          </label>
                      </div>

                    </div>
                </form>                
            </div>
        </div>
    </div>
</div>

<div id="div1600px">
<table  class="table table-striped table-bordered" >
             
    <thead class="thead-light">
    
    <tr>

            <th scope="col" *ngIf="txtverdias">Fecha</th>                     
            <th scope="col">Servicios</th>  
            <th scope="col">Captura</th>   
            <th scope="col">Correspondencia</th>   
            <th scope="col">Entrega</th>  
            <th scope="col">Entrega con firma</th>          
            <th scope="col">No Entrega</th>            
            <th scope="col">Cancelada</th>
            <th scope="col" *ngIf="txtversitios">Hallazgo</th>                        
            <th scope="col" *ngIf="txtversitios">Mismo día</th>
            <th scope="col" *ngIf="txtversitios">Día1</th>
            <th scope="col" *ngIf="txtversitios">Día2</th>
            <th scope="col" *ngIf="txtversitios">Día3</th>
            <th scope="col" *ngIf="txtversitios">Día4</th>
            <th scope="col" *ngIf="txtversitios">Día5</th>
            <th scope="col" *ngIf="txtversitios">Día5+</th>            
            <th scope="col">Indice Éxito</th>   
      </tr>
    </thead>
                <tbody> 
                        <!-- si marca errro add import { CommonModule } from '@angular/common'; -->
                        <tr *ngFor="let item of dataservice.lasfilaslestatus">
                          <td *ngIf="txtverdias">
                            {{ item.fecha | date:'yyyy-MM-dd'  }}
                          </td>
                        
                          <td>
                            <button (click)="verdatelle(item, 'servicios')" class="btn btn-link" [disabled]="(item.servicios == '0')">
                              {{item.servicios}}
                             </button>                          
                          </td>
                          
                        <td>
                          <button (click)="verdatelle(item, 'captura')" class="btn btn-link" [disabled]="(item.captura == '0')">
                            {{item.captura}}
                           </button>                          
                        </td>

                        <td>
                          <button (click)="verdatelle(item, 'correspondencia')" class="btn btn-link" [disabled]="(item.correspondencia  == '0')">
                            {{item.correspondencia}}
                           </button>
                          
                        </td>
                        <td>
                          <button (click)="verdatelle(item, 'entrega')" class="btn btn-link" [disabled]="(item.entrega=='0')">
                            {{item.entrega}}
                           </button>
                        
                      </td>
                      <td>
                        <button (click)="verdatelle(item, 'entregaws')" class="btn btn-link" [disabled]="(item.entregaws=='0')">
                          {{item.entregaws}}
                         </button>
                      
                    </td>
                        <td>
                          <button (click)="verdatelle(item, 'noentrega')" class="btn btn-link" [disabled]="(item.noentrega=='0')">
                            {{item.noentrega}}
                           </button>
                        
                      </td>
                      
                      <td>
                        <button (click)="verdatelle(item, 'cancelada')" class="btn btn-link" [disabled]="(item.cancelada=='0')">
                          {{item.cancelada}}
                         </button>
                       
                    </td>
                    <td *ngIf="txtversitios">
                      <button (click)="verdatelle(item, 'hallazgo')" class="btn btn-link" [disabled]="(item.hallazgo=='0')">
                        {{item.hallazgo}}
                       </button>
                      
                  </td>
                  <td *ngIf="txtversitios">
                    <button (click)="verdatelle(item, 'mismodia')" class="btn btn-link" [disabled]="(item.mismodia=='0')">
                      {{item.mismodia}}
                     </button>                    
                   </td>
    
                   <td *ngIf="txtversitios">
                    <button (click)="verdatelle(item, '0dia1')" class="btn btn-link" [disabled]="(item.dia1=='0')">
                      {{item.dia1}}
                     </button>                    
                   </td>
                   <td *ngIf="txtversitios">
                    <button (click)="verdatelle(item, '0dia2')" class="btn btn-link" [disabled]="(item.dia2=='0')">
                      {{item.dia2}}
                     </button>                    
                   </td>
                   <td *ngIf="txtversitios">
                    <button (click)="verdatelle(item, '0dia3')" class="btn btn-link" [disabled]="(item.dia3=='0')">
                      {{item.dia3}}
                     </button>                    
                   </td>
                   <td *ngIf="txtversitios">
                    <button (click)="verdatelle(item, '0dia4')" class="btn btn-link" [disabled]="(item.dia4=='0')">
                      {{item.dia4}}
                     </button>                    
                   </td>
                   <td *ngIf="txtversitios">
                    <button (click)="verdatelle(item, '0dia5')" class="btn btn-link" [disabled]="(item.dia5=='0')">
                      {{item.dia5}}
                     </button>                    
                   </td>
                   <td *ngIf="txtversitios">
                    <button (click)="verdatelle(item, '0dia5mas')" class="btn btn-link" [disabled]="(item.dia5mas=='0')">
                      {{item.dia5mas}}
                     </button>                    
                   </td>                                                                                                
                <td>
                  {{ item.promexito | percent }}
              </td>
              
                         
                           
                </tbody>
            </table>
          </div>
