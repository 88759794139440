<link rel="stylesheet" href="https://unpkg.com/@coreui/coreui@3.0.0-beta.1/dist/css/coreui.min.css">

 
<div class="navbar-header" >
  <a  href="#/">
      <!-- Logo icon --><b>
          <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
          <!-- Dark Logo icon -->
          <img width="300" src="assets/images/logosololetras1.png"  />
          <!-- Light Logo icon -->
           
      </b>
      </a>
</div>
  <div class="content"   >
  
    <div class="card highlight-card card-small"> 
 
    <form class="form-inline" ngNativeValidate [formGroup]="forma" id="buscarform"  >
     
        <div class="col-auto">              
              <input class="form-control mb-2"
              type="text" 
              name="prefijo" 
              id  ="prefijo" 
              formControlName="prefijo"               
              placeholder = "prefijo"   
              required>
        </div>
      
      <div class="col-auto">          
        <select formControlName="festatus" name="festatus" [(ngModel)]="txtstatus" class="form-control mb-2" id="st"  (change)="selsel($event.target.value)" required>
          <option value="Factura" selected>Factura</option>
          <option value="cv">CV</option> 
        </select>
    </div>
 
        <div class="col-auto">          
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <div class="input-group-text">-</div>
                </div>
                <input class="form-control"
                       type="text" 
                       id="factura"    
                       name="factura"    
                       formControlName="factura"               
                       placeholder="captura" required>
              </div>
        </div>
       
         
          <div class="form-check">
                <input class="form-check-input" 
                      type="checkbox" 
                      name="recordar"
                      id="cbrecordar"
                       formControlName="recordar" >
                <label  class="form-check-label" for="cbrecordar">
                       Recordar<br>Prefijo
                </label>
              </div>
       
     
        <div class="col-auto">
              <button id="buttonbc"
                      class="btn btn-light btn-block"
                      [disabled]="forma.invalid"  (click)="Onsubmit()"
                       >Buscar</button> 
        </div>
       
    </form >
  
   
  
     </div>
   
   
 
  
    </div>

    <ng-template #content let-modal>
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">Zoom</h4>
          <button type="button" class="close" aria-label="Cerrar" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div  >
            <img  [src]="imagen64"  class="img-fluid img-thumbnail rounded float-left" alt="Responsive image">
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cerrar</button>
        </div>
      </ng-template>
    
