<h3>Actualización de registros DHL Entregados
<button type="button"
class="btn btn-primary btn-xs"
            (click)="layot()"
          > Layout de archivo
          </button></h3>
<br>
<div>


  <form [formGroup]="form" (ngSubmit)="onSubmit()">

    <input type="file" 
           #fileInput    
           (change)="seleccionImage($event.target.files)" />
    <!-- <button type="submit" *ngIf="this.imagenSubir">Upload</button> -->

  </form>


<br>
  <div style="margin-bottom: 40px">
    <table class="table">
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Tipo</th>
          <th>Tamaño</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="imagenSubir != null">
          <td>
            <div *ngIf="['image/gif','image/png','image/jpeg'].indexOf(files.type)>=0">
              
            </div>
            <strong>{{ files.name }}</strong>
          </td>
          <td nowrap>
            {{ files.type }}
          </td>
          <td nowrap>
            {{ files.size/1024/1024 | number:'.2' }} MB
          </td>
          <td nowrap>
            <button type="button"
              class="btn btn-primary btn-xs"
              (click)="onSubmit()"
            > importar
              <!-- <span class="glyphicon glyphicon-trash"></span> -->
            </button>
           
            <button type="button"
              class="btn btn-danger btn-xs"
              (click)="delete()"
            > eliminar
              <!-- <span class="glyphicon glyphicon-trash"></span> -->
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  
<br>


<button type="button" class="btn btn-primary" (click)="cargar()" *ngIf="cifracontorl !=''">
  Importado con exito, actualizar Base de Datos, cifra control:  <span class="badge badge-light">  {{cifracontorl}}   </span>
</button>