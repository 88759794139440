
export class Usuario {
    constructor(
        public nombre?: string,
        public rol?: string,
        public username?: string,
        public namer?: string,
        public area?: string,
        public jefedirecto?: string,
        public confirmado?: string,
        public roldescrip?: string,
        public finanzas?: string,
    ) { }
}

export class UsuarioLogin {
    constructor(
        public Username?: string,
        public pass?: string
    ) { }
}

export class Catrespuestas {
    constructor(
        public clave?: string,
        public descripcion?: string
    ) { }
}

export class Catempresas {    
        public idempresa?: string;
        public empresa?: string;    
        public prefijo?: string;  
}

export class Catiddescrip {    
    public id?: string;
    public cantidad?: string;    
    public precio?: string;    
    public importe?: string;    
    public descripcion?: string;    
}

export class catdescripcion {    
    public id?: string;
    public descripcion?: string;    
}



export class Catproyecto {    
    public descripcion?: string;
    public idcatdetalle?: string;    
    public aplicafull?: string; 
}


export class Qusuario {
    constructor(
        public nombre?: string,
        public rol?: string,
        public iduser?: string,
        public roldescrip?: string,
        public username?: string,
        public referencia?: string,
        public correo?: string,
        public area?: string,
        public calle?: string,
        public col?: string,
        public edo?: string,
        public cp?: string,
        public delmun?: string,
        public vigente?: string,
        public tel?: string,
        public extencion?: string,
        public tel1?: string,
        public celular?: string,
        public llave?: string,
        public confirmado?: string
    ) { }
}

export class Losroles {
    constructor(
        public id?: number,
        public descripcion?: string
    ) { }
}

export class Cbstatus {
    constructor(
        public id?: string,
        public descripcion?: string
    ) { }
}

export class Crespuestas {
    constructor(
        public clave?: string,
        public descripcion?: string
    ) { }
}

export class Criteriosprogress {
    constructor(
        public id?: string,
        public fini?: string,
        public ffin?: string,
        public fprefijo?: string
    ) { }
}

export class Criteriosestatus {
    constructor(
        public fini?: string,
        public ffin?: string,
        public sitios?: boolean,
        public dias?: boolean,

    ) { }
}
