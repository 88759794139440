
<!-- <form ngNativeValidate #f="ngForm"> -->
  <form [formGroup]="forma" >
  <div class="row">
    <div class="col-md-6 mb-3">
          <div class="card">
            <div class="card-header">
              <div class="container">
                <div class="row">
                  <div class="col-sm"><strong>Destinatario<button type="button" 
                    (click)="Prinmt( this.dataservice.Elid)" class="btn btn-link" data-toggle="tooltip" data-placement="top" *ngIf="(flagprinter == true)" title="Reimprimir" >
                         <i class="fa fa-print fa-lg" aria-hidden="true"></i></button></strong></div>
                  <div class="col-sm-2">
                    <button (click)="btnbuscar()"class="btn btn-primary">Buscar</button>
                  </div>
                </div>
               </div>
            </div>
            <div class="card-body">
              <div class="form-group row">
                <label for="formGroupExampleInputD" class="col-sm-2 col-form-label">Nombre</label>
                <div class="col-sm-10">                                         
                    <input type="text" class="form-control" value="{{ dataservice.Elid.nombreD}}"  
                    id="formGroupExampleInputD" readonly required>                     
                </div>
              </div>
              
              <div class="form-group row">
                <label for="formGroupExampleInputCalle" class="col-sm-2 col-form-label">Calle</label>
                <div class="col-sm-10">                                         
                    <input type="text" class="form-control" value="{{ dataservice.Elid.calleD}}" id="formGroupExampleInputcalle" readonly>                     
                </div>
              </div>

              <div class="form-group row">
                <label for="formGroupExampleInputCol" class="col-sm-2 col-form-label">Colonia</label>
                <div class="col-sm-10">                                         
                    <input type="text" class="form-control" value="{{ dataservice.Elid.colD}}" id="formGroupExampleInputCol" readonly>                     
                </div>
              </div>

              <div class="form-group row">
                <label for="formGroupExampleInputdel" class="col-sm-2 col-form-label">Alcaldía</label>
                <div class="col-sm-10">                                         
                    <input type="text" class="form-control" value="{{ dataservice.Elid.DelD}}" id="formGroupExampleInputdel" readonly>                     
                </div>
              </div>
              
              <div class="row">
                <div class="col-md-6 mb-3">   
                      <div class="form-group row">
                        <label for="formGroupExampleInputedo" class="col-sm-4 col-form-label">Estado</label>
                        <div class="col-sm-8">                                         
                            <input type="text" class="form-control" value="{{ dataservice.Elid.edoD}}" id="formGroupExampleInputedo" readonly>                     
                        </div>
                      </div>
                </div>
                <div class="col-md-6 mb-3">   
                      <div class="form-group row">
                        <label for="formGroupExampleInputCP" class="col-sm-4 col-form-label">CP</label>
                        <div class="col-sm-8">                                         
                            <input type="text" class="form-control" value="{{ dataservice.Elid.cpD}}" id="formGroupExampleInputCP" readonly>                     
                        </div>
                      </div>
              </div>
              </div>
              
              <div class="form-group row">
                <label for="formGroupExampleInputarea" class="col-sm-2 col-form-label">Área</label>
                <div class="col-sm-10">                                         
                    <input type="text" class="form-control" value="{{ dataservice.Elid.areaD}}" id="formGroupExampleInputarea" readonly>                     
                </div>
              </div>
               
            </div>
          </div>
    </div>
    <div class="col-md-6 mb-3">
      <div class="row">
        <div class="col-md-6 mb-3">
                  <div class="card" style="height:  11rem;">
                    <div class="card-header">
                      <div *ngIf="forma.controls['txtformato'].invalid && 
                                  (forma.controls['txtformato'].dirty || forma.controls['txtformato'].touched)" class="text-danger">                                            
                        <div *ngIf="forma.controls['txtformato'].errors.pattern || forma.controls['txtformato'].errors.required">
                          Formato* (requerido)
                        </div>
                      </div>
                      <div *ngIf="forma.controls['txtformato'].valid || (!forma.controls['txtformato'].dirty && !forma.controls['txtformato'].touched)"  >
                        Formato</div>
                 
                    </div>
                    <div class="card-body">
                      <div class="form-group row"> 
                        <div class="col-sm-12">
                              <select class="form-control" formControlName="txtformato"  [(ngModel)]="txtformato"  >
                                <option value="">Selecciona...</option>
                                <option value="Paquete">Paquete</option>
                                <option value="Sobre">Sobre</option>                      
                              </select>
                        </div>
                    </div>

                      <div class="form-group row" *ngIf="txtformato=='Paquete'"> 
                        <label for="formGroupExampleInputpaq" class="col-sm-8 col-form-label">Núm. Paquetes</label>
                        <div class="col-sm-4">
                            <input   type="number" maxlength="2" class="form-control" id="formGroupExampleInputpaq" min="1"                            
                            [(ngModel)]="txtnumpaquete"
                            id="formGroupExampleInputpaq" 
                            formControlName="txtnumpaquete"   > 
                        </div>
                    </div>

                 




                    </div>
                  </div>
        </div>
        <div class="col-md-6  mb-3">
                  <div class="card" style="height:  11rem;">
                    <div class="card-header">
                      <div *ngIf="forma.controls['txttipoenvio'].invalid && 
                                  (forma.controls['txttipoenvio'].dirty || forma.controls['txttipoenvio'].touched)" class="text-danger">                                            
                        <div *ngIf="forma.controls['txttipoenvio'].errors.pattern || forma.controls['txttipoenvio'].errors.required">
                          Tipo Servicios* (requerido)
                        </div>
                      </div>
                      <div *ngIf="forma.controls['txttipoenvio'].valid || (!forma.controls['txttipoenvio'].dirty && !forma.controls['txttipoenvio'].touched)"  >
                        Tipo Servicios</div>
                    </div>
                    <div class="card-body">
                      <select class="form-control" name="tipo"  [(ngModel)]="txttipoenvio" formControlName="txttipoenvio" >
                        <option value="">Selecciona...</option>
                        <option value="Siguiente día">Siguiente día</option>
                        <option value="Dos días">Dos días</option>
                        <option value="Cinco días">Cinco días</option>
                      </select >

                    </div>
                  </div>
        </div>
      </div>
      <div class="row"> 
          <div class="col-md-12 mb-3">
              <div class="card">
                <div class="card-header">
                  <div *ngIf="forma.controls['txtcomentarios'].invalid && 
                                  (forma.controls['txtcomentarios'].dirty || forma.controls['txtcomentarios'].touched)" class="text-danger">                                            
                        <div *ngIf="forma.controls['txtcomentarios'].errors.pattern  ">
                          Comentarios* sin caracteres extraños
                        </div>
                      </div>
                      <div *ngIf="forma.controls['txtcomentarios'].valid || (!forma.controls['txtcomentarios'].dirty && !forma.controls['txtcomentarios'].touched)"  >
                        Comentarios</div>
                     
                </div>
                <div class="card-body">

                          <textarea type="text"                    
                          class="form-control" 
                          formControlName="txtcomentarios"                  
                          maxlength="300"     
                          [(ngModel)]="txtcomentarios"            
                          rows="2"></textarea>

                          
                          <div class="form-group row">
                                <label for="inputedo2" class="col-sm-3 col-form-label">
                                  <div *ngIf="forma.controls['txtrefer'].invalid && 
                                  (forma.controls['txtrefer'].dirty || forma.controls['txtrefer'].touched)" class="text-danger">                                            
                                  <div *ngIf="forma.controls['txtrefer'].errors.pattern  ">
                                    Referencia* sin caracteres extraños
                                  </div>
                                </div>
                                <div *ngIf="forma.controls['txtrefer'].valid || (!forma.controls['txtrefer'].dirty && !forma.controls['txtrefer'].touched)"  >
                                  Referencia</div>
                                </label>
                                <div class="col-sm-9">                                         
                                    <input type="text" 
                                    [(ngModel)]="txtrefer"
                                          class="form-control"
                                          maxlength="100"
                                          formControlName ="txtrefer"                           
                                          id="inputedo2" >                     
                                </div>
                          </div>
                </div>
              </div>
          </div>

      </div>

      <div class="row">
        <div class="col-md-6 mb-3">
           
        </div>
        <div class="col-md-6 mb-3">
          <button type="submit" class="btn btn-primary btn-block"  *ngIf="(flagprinter == false)" 
          [disabled]="(!forma.valid  )"  
          (click)="onClickOK()" >Enviar</button>
        </div>
      </div>

    </div>
  </div>
 

</form>
 

<div class="fondo-negro animated fadeIn" [ngStyle]="{'display':display}">
  <div class="modal bd-example-modal-lg"   tabindex="-1" role="dialog"   style="display:block;"  >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
              <button type="button" class="btn btn-primary" (click)="cerrarModal()" >Cerrar</button>
               
            </div>
          <div class="modal-body">
            
             
                 
                <div class="container">
                  <div class="row">
                    <div class="col-3">
                     
                        <select class="form-control" name="bcampo" [(ngModel)] = "dataservice.elcamposel" (change)="resetdata($event)" >                          
                          <option selected value="nombre">nombre</option>
                          <option value="calle">calle</option>
                          <option value="col">colonia</option>
                          <option value="del_mun">alcaldía</option>
                          <option value="area">área</option>
                        </select>
                      </div>

                     
                      
                      
                    <div class="col-8">
                        <input type="text" 
                        class="form-control"                         
                        placeholder="Captura... despues Enter" 
                        #txttermino
                        (keyup.enter) ="buscarbd(txttermino.value)"
                        pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9 ,.#_/-]*" />  
                    </div>
                    <div class="col-1">
                      <i *ngIf="iconbusc != ''" class="fa fa-circle-o-notch fa-spin fa-1x fa-fw"></i>
                      <label *ngIf="iconbusc == ''"  >{{dataservice.lasfilasBlosomtermino.length }}</label>
                    </div>
                  
                </div>
                <div class="row"><br></div>
                  <div class="row align-items-center">
                    <div class="col-12">
                                <input type="text" class="form-control" 
                                name="filterPost" 
                                placeholder="Filtrar..."   [(ngModel)] = "filterPost">
                    </div>
                  </div>
                  <div class="row">
                      <div class="col-12">
                        <div id="div1580px"> 
                              <table  class="table table-striped table-bordered" >

                                  <thead class="thead-light">
                                  
                                  <tr>      
                                    <th scope="col"></th>  
                                          <th scope="col">Nombre</th>
                                          <th scope="col">Calle</th>            
                                          <th scope="col">Col</th>
                                          <th scope="col">Alcaldía</th>
                                          <th scope="col">Área</th>
                                    </tr>
                                  </thead>
                                              <tbody> 
                                                      <!-- si marca errro add import { CommonModule } from '@angular/common'; -->
                                                      <tr *ngFor="let item of dataservice.lasfilasBlosomtermino | filtername: filterPost : compofilter">
                                                        <!-- <tr *ngFor="let item of dataservice.lasfilasBlosomtermino  ">    -->
                                                           <td>
                                                            <button type="button" (click)="onClickzoom( item )" class="btn btn-link"><i class="fa fa-chevron-right fa-lg" aria-hidden="true"></i></button>
                                                          </td>  
                                                            <td>
                                                           {{ item.nombreD}} 
                                                        </td>                         
                                                        <td>
                                                            {{ item.calleD}}
                                                        </td>
                                                        <td>
                                                            {{ item.colD}}
                                                        </td> 
                                                        <td>
                                                          {{ item.DelD}}
                                                      </td> 
                                                      <td>
                                                        {{ item.areaD}}
                                                    </td>   
                                                    
                                                    </tr>  
                                              </tbody>
                              </table>
                            </div>
                    </div>
                </div>
              
         
          </div>
      </div>
  </div>
  </div>