import { Pipe, PipeTransform } from '@angular/core';
// https://www.youtube.com/watch?v=NiI3LYlEpQs
@Pipe({
  name: 'filtername'
})
export class FilternamePipe implements PipeTransform {

  transform(value: any[], arg: string, columna: string): any {
    const resultPost = [];
    for (const post of value) {
      if (post[columna].toLowerCase().indexOf(arg.toLowerCase()) > -1 ) {
        resultPost.push(post);
      }


    }
    return resultPost;
  }

}
