import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { NopagefoundComponent } from './shared/nopagefound/nopagefound.component';
import { BuscarComponent } from './pages/buscar/buscar.component';



const appRoutes: Routes = [
  {path: 'login', component: LoginComponent, data: { titulo: 'Login'}},
  {path: 'buscar', component: BuscarComponent, data: { titulo: 'Buscar'}},
  {path: '**', component: NopagefoundComponent, data: { titulo: 'No existe'}}
];


export const APP_ROUTES = RouterModule.forRoot( appRoutes, { useHash: true } );
