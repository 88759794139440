<app-modallogin></app-modallogin>  
<div class="limiter">
        <div class="container-login100">
            <form >
                <div class="form-row">     
                            
                        <img  class="wrap-login100 img-fluid" src="assets/images/filename.png"  >                                  
                     
                </div>

                <br>
                <div class="parent">                    
                          <div class = "container-login100-form-btn">    
                                <div class="form-row">   
                                 
                                <div class="col">
                                <button class="login100-form-btn"  (click)="modallogin()"  >
                                    Ingresar
                                </button>
                            </div>
                        </div>
                    </div>      
                </div>
            </form>
           
 
        </div>

 
    </div>
   
     

 
  
