import { Component,  OnInit ,  ViewChild, ElementRef } from '@angular/core';
import {  ImportfileService } from './../../services/service.index';
import { FormBuilder, FormGroup } from '@angular/forms';
// file_put_contents($payload['avatar']['filename'], base64_decode($payload['avatar']['value']));
// https://www.techiediaries.com/php-file-upload-tutorial/
import Swal from 'sweetalert2';
import * as xlsx from 'xlsx';
import * as moment from 'moment';


@Component({
  selector: 'app-importar',
  templateUrl: './importar.component.html',
  styles: []
})
export class ImportarComponent implements OnInit  {
  @ViewChild('fileInput', { static: false }) myFileInput: ElementRef;
  form: FormGroup;
  uploadResponse;
  imagenSubir: File = null;
  imagenTemp: any;
  files: File;
public cifracontorl = '';

  constructor(private formBuilder: FormBuilder,
              private uploadService: ImportfileService) {

}

ngOnInit() {
  this.form = this.formBuilder.group({
    avatar: ['']
  });
}

onFileSelect(event) {
  if (event.target.files.length > 0) {
    const file = event.target.files[0];
    this.form.get('avatar').setValue(file);
  }
}


seleccionImage( archivos: FileList ) {

  if ( !archivos ) {
    this.imagenSubir = null;
    return;
  }
  this.imagenSubir = archivos.item(0);
  this.files = archivos.item(0);
  if ( this.imagenSubir.type.indexOf('ms-excel') < 0 ) {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'error',
      title: 'Oops...',
      text: 'solo archivos de tipo csv , texto separado por comas'
    });
    this.cifracontorl = '';
    this.imagenSubir = null;
    return;
  }



}

async  onSubmit() {

  Swal.fire({
    allowOutsideClick: false,
    icon: 'info',
    text: 'Espere por favor...'
  });
  Swal.showLoading();
  const valido = await this.uploadService.uploadFile(this.imagenSubir);
  Swal.close();
  if (!valido === true) {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'error',
      title: 'Oops...',
      text: this.uploadService.respuesta
    });
    return; } else     {
      this.cifracontorl = this.uploadService.respuesta;
    }
}

delete() {
  this.imagenSubir = null;
  this.files.slice(0, 1);
  this.form.reset();
  this.cifracontorl = '';
  this.myFileInput.nativeElement.value = '';

  this.form.get('avatar').setValue('');
}


layot() {
  Swal.fire({
    title: 'Configuración sin encabezado tipo csv ',
    icon: 'info',
    html: '<ul>   ' +
      '<li type="circle"><strong>Folio SIMAN</strong> varchar(20) </li> ' +
      '<li type="circle"><strong>Fecha</strong> date DD/MM/YYYY </li> ' +
      '<li type="circle"><strong>Hora</strong> varchar(10) </li> ' +
      '<li type="circle"><strong>Detalle</strong> varchar(50) </li> ' +
      '</ul>' ,
    showCloseButton: false,
    showCancelButton: false,
    focusConfirm: false
  });
}






async cargar() {


  const confirmar = await Swal.fire({
    title: 'Continuar',
    icon: 'info',
    confirmButtonText: 'Si',
    cancelButtonText: 'No',
    showConfirmButton: true,
    showCancelButton: true
  });


  if (confirmar.dismiss === Swal.DismissReason.cancel) { return; }

  const valido = await this.uploadService.insertdb();

  if (!valido) {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'error',
      title: 'Oops...',
      text: this.uploadService.respuesta
    });
    return;
  } else {

    const ws: xlsx.WorkSheet =
    // xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    xlsx.utils.json_to_sheet(this.uploadService.lasfilafile);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'Actualizacion' + moment(new Date()).format('YYYYMMDDhhmmss') + '.xlsx');
  }

  this.delete();

  Swal.fire({
    allowOutsideClick: false,
    icon: 'success',
    title: 'Finalizado',
    text: 'Información ingresada, se importo resultado'
  });

}


}
