



<div class="col-md-12 table-responsive" #epltable>
    <div class="row"  *ngIf="dataservice.lasfilasbucafolio.length>0">
   
        <div class="col-6">        
                <input type="text"  
                    class="form-control" 
                    name="filterPost" 
                    placeholder="filtrar destinatario..."   
                    [(ngModel)] = "filterPost">
        </div>
        <div class="col-2">
            <label >{{ dataservice.lasfilasbucafolio.length }}</label>
        </div>
         <div class="col-4">
                <button (click)="exportToExcel()" class="btn btn-success">Exportar a Excel</button> 
         </div>
         
    </div>
    <div id="div1600px"> 
<table  class="table table-striped table-bordered" >
             
        <thead class="thead-light">
        
        <tr>
            <th scope="col">Folio</th>
                <th scope="col">Remitente</th>
                <th scope="col">Destinatario</th>
                <th scope="col">Solicitud</th>
                <th scope="col">Estatus</th>
                <th scope="col">Entrega con</th>
                <th scope="col">Fecha Respuesta</th>

          </tr>
        </thead>
                    <tbody> 
                            <!-- si marca errro add import { CommonModule } from '@angular/common'; -->
                         <tr *ngFor="let item of dataservice.lasfilasbucafolio | filterfactu: filterPost">
                             
                            <td>                                
                                <button type="button" (click)="onClickzoom( item.id ,  item.folio)" class="btn btn-link">{{ item.folio}}
                                </button>
                            </td>
                            <td>
                                {{ item.remitente }}
                            </td>
                            <td>
                                {{ item.destinatario }}
                            </td>
                            <td>
                                {{ item.fsolicitud }}
                            </td>
                            <td>
                                {{ item.descripcion}}
                            </td>
                            
                            <td>
                                {{ item.firma }}
                            </td>
                            <td>
                                {{ item.frespuesta }}
                            </td>
                            
                        </tr>  
                    </tbody>
                </table>
            </div>                
</div>