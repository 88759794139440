import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment.prod';
import { FilatrabajoHIS , Camposedi, camposcontable } from '../../interfaces/interfasefilas';
import {Campos, Camposestatus  } from './../../interfaces/campos';
import { HttpClient } from '@angular/common/http';
import { Qusuario, Losroles, Criteriosprogress, Criteriosestatus, Catempresas, Catiddescrip, catdescripcion } from '../../model/usuario.model';
import { AuthService } from './auth.service';
import Swal from 'sweetalert2';
import { Catuserasignacion } from '../../interfaces/userasignacion';
import { Camposingreso, Camposingresocv, Camposcaptura } from '../../interfaces/campos';
import * as moment from 'moment';
const URL = environment.sitiores;

@Injectable({
  providedIn: 'root'
})
export class ServicedataService {
  lafecha: string = moment(new Date()).format('YYYY-MM-DD');
  loscritestatus: Criteriosestatus = new Criteriosestatus(this.lafecha, this.lafecha,  false, false);
  campobusqueda: string;
  folioasignado: string;
  foliocaptu: string;
  foliocaptucorresp: string;
  lasfilacaptura: Camposcaptura[] = [];
  // lasfilas: Filatrabajo[] = [];
  ArrayLositems: FilatrabajoHIS[] = [];
  lasfilasprogress: Campos[] = [];
  lasfilasbucafolio: Campos[] = [];
  lasfilasBlosomtermino: Camposedi[] = [];
  selidempresa ='0';
  selidempresa1 ='0';
  selidempresa2 ='0';
  //   Elid: Filatrabajo = {};
  Elid: Camposedi = {};
  parapdf: camposcontable ={};
  lasdescripciones: Catiddescrip[] = [];
  lasdescripcionesparapdf: Catiddescrip[] = [];
  ElidRemitente: Camposedi = {};
  userasug: Catuserasignacion[] = [];
  losusuarios: Qusuario[] = [];
  losroles: Losroles[] = [];
  numregisUsers: number;
  lasfilaslestatus: Camposestatus[] = [];
  ResultINgresoBlosom: Camposingreso[] = [];
  ResultINgresoBlosomcv: Camposingresocv[] = [];
  elcamposel = 'nombre';
  elcamposeltipo = 'descripcion';
  
  catalogoFullFilment:Catiddescrip[] = []; 
  catalogoplaza:Catiddescrip[] = []; 
  // preopectoselec: Filatrabajo  = {};

  txtformproyecto:string;
  txtformfullfilment:string;
  txtformfplaza:string;
  txtformimportefactura = 0;
  txtprefijo: string;
  nombreempresa: string;


  numid = null;
  bfolio: string;
  loscrit: Criteriosprogress = new Criteriosprogress( 'folio', this.lafecha, this.lafecha, '');

  constructor(private http: HttpClient,
              private USERSERVICE: AuthService) { }


  buscarfullfilmentContable(idproyecto: string, elitem: string) {
    const data = { idusuario: this.USERSERVICE.idusuario , campos:  idproyecto , items: elitem} ;
 
  
    return new Promise( resolve => {
                this.http.post<any>(`${ URL }/Filat/elfullfilment/${ this.USERSERVICE.token }`, data)
                  .subscribe( async resp => {

                   if ( resp.error === false && resp.respuesta.estatus === 'data' && resp.respuesta.filas.length > 0) {
                        if (elitem ==='Fullfilment') {
                          this.catalogoFullFilment = resp.respuesta.filas;
                        } else {
                          this.catalogoplaza = resp.respuesta.filas;
                        }
                        
                        resolve(true);
                      } else if (resp.error === false && resp.respuesta.filas.length === 0 ) {
                        if (elitem ==='Fullfilment') {
                          this.catalogoFullFilment = [];
                        } else {
                          this.catalogoplaza = [];
                        }
                        resolve('No hay datos');
                    } else {
                      if (elitem ==='Fullfilment') {
                        this.catalogoFullFilment = [];
                      } else {
                        this.catalogoplaza = [];
                      }
                        resolve( resp.respuesta.mensaje );
                    }
                  }, error => {
                    // this.iuservce.alertaInformativa('Existe un error: ' + error, 'Falla de aplicacion servicio filatrabajo');
                    resolve(JSON.parse (error));
                  });

  });
  }

   verregistro(btermino: string   ) {
    const data = { idusuario: this.USERSERVICE.idusuario , campos: this.campobusqueda, items: btermino } ;

    return new Promise( resolve => {
                this.http.post<any>(`${ URL }/Filat/buscarweb/${ this.USERSERVICE.token }`, data)
                  .subscribe( async resp => {

                   if ( resp.error === false && resp.respuesta.estatus === 'data' && resp.respuesta.filas.length > 0) {
                        this.lasfilasBlosomtermino = resp.respuesta.filas;
                        resolve(true);
                      } else if (resp.error === false && resp.respuesta.filas.length === 0 ) {
                        this.lasfilasBlosomtermino = [];
                        resolve('No hay datos');
                    } else {
                        this.lasfilasBlosomtermino = [];
                        resolve( resp.respuesta.mensaje );
                    }
                  }, error => {
                    // this.iuservce.alertaInformativa('Existe un error: ' + error, 'Falla de aplicacion servicio filatrabajo');
                    resolve(JSON.parse (error));
                  });
  });
  }

  buscarfolio(btermino: string   ) {
    const data = { idusuario: this.USERSERVICE.idusuario , campos: this.campobusqueda, items: btermino } ;

    return new Promise( resolve => {
                this.http.post<any>(`${ URL }/Filat/buscarweb/${ this.USERSERVICE.token }`, data)
                  .subscribe( async resp => {

                   if ( resp.error === false && resp.respuesta.estatus === 'data' && resp.respuesta.filas.length > 0) {
                        this.lasfilasbucafolio = resp.respuesta.filas;
                        resolve(true);
                      } else if (resp.error === false && resp.respuesta.filas.length === 0 ) {
                        this.lasfilasbucafolio = [];
                        resolve('No hay datos');
                    } else {
                        this.lasfilasbucafolio = [];
                        resolve( resp.respuesta.mensaje );
                    }
                  }, error => {
                    // this.iuservce.alertaInformativa('Existe un error: ' + error, 'Falla de aplicacion servicio filatrabajo');
                    resolve(JSON.parse (error));
                  });
  });
  }

  verstatus( lasvariables: string) {
    const data = { idusuario: this.USERSERVICE.idusuario ,   items: lasvariables } ;

    return new Promise( resolve => {
                this.http.post<any>(`${ URL }/Filat/estatus/${ this.USERSERVICE.token }`, data)
                  .subscribe( async resp => {

                   if ( resp.error === false && resp.respuesta.estatus === 'data' && resp.respuesta.filas.length > 0) {
                        this.lasfilaslestatus = resp.respuesta.filas;
                        resolve(true);
                      } else if (resp.error === false && resp.respuesta.filas.length === 0 ) {
                        this.lasfilaslestatus = [];
                        resolve('No hay datos');
                    } else {
                        this.lasfilaslestatus = [];
                        resolve( resp.respuesta.mensaje );
                    }
                  }, error => {
                    // this.iuservce.alertaInformativa('Existe un error: ' + error, 'Falla de aplicacion servicio filatrabajo');
                    resolve(JSON.parse (error));
                  });

  });
  }


  verstatusdetalle( lasvariables: string) {
    const data = { idusuario: this.USERSERVICE.idusuario ,   items: lasvariables } ;

    return new Promise( resolve => {
                this.http.post<any>(`${ URL }/Filat/estatusdetalle/${ this.USERSERVICE.token }`, data)
                  .subscribe( async resp => {

                   if ( resp.error === false && resp.respuesta.estatus === 'data' && resp.respuesta.filas.length > 0) {
                        this.lasfilasbucafolio = resp.respuesta.filas;
                        resolve(true);
                      } else if (resp.error === false && resp.respuesta.filas.length === 0 ) {
                        this.lasfilasbucafolio = [];
                        resolve('No hay datos');
                    } else {
                        this.lasfilasbucafolio = [];
                        resolve( resp.respuesta.mensaje );
                    }
                  }, error => {
                    // this.iuservce.alertaInformativa('Existe un error: ' + error, 'Falla de aplicacion servicio filatrabajo');
                    resolve(JSON.parse (error));
                  });

  });
  }

  buscartermino(btermino: string ,  campobusqueda: string, tabla : string ) {
    const data = { idusuario: this.USERSERVICE.idusuario , campos:  campobusqueda, items: btermino + '|' +  tabla} ;
 
    return new Promise( resolve => {
                this.http.post<any>(`${ URL }/Filat/buscartermino/${ this.USERSERVICE.token }`, data)
                  .subscribe( async resp => {

                   if ( resp.error === false && resp.respuesta.estatus === 'data' && resp.respuesta.filas.length > 0) {
                  
                        this.lasfilasBlosomtermino = resp.respuesta.filas;
                        resolve(true);
                      } else if (resp.error === false && resp.respuesta.filas.length === 0 ) {
                        this.lasfilasBlosomtermino = [];
                        resolve('No hay datos');
                    } else {
                        this.lasfilasBlosomtermino = [];
                        resolve( resp.respuesta.mensaje );
                    }
                  }, error => {
                    // this.iuservce.alertaInformativa('Existe un error: ' + error, 'Falla de aplicacion servicio filatrabajo');
                    resolve(JSON.parse (error));
                  });

  });
  }

  seguimientobuscar(lositems: string ) {
    const data = { idusuario: this.USERSERVICE.idusuario ,   items: lositems} ;

    return new Promise( resolve => {
                this.http.post<any>(`${ URL }/captu/seguibusfol/${ this.USERSERVICE.token }`, data)
                  .subscribe( async resp => {
                   if ( resp.error === false && resp.respuesta.estatus === 'data' && resp.respuesta.filas.length > 0) {
                        this.lasfilacaptura = resp.respuesta.filas;
                        resolve(true);
                      } else if (resp.error === false && resp.respuesta.filas.length === 0 ) {
                        this.lasfilacaptura = [];
                        resolve('No hay datos');
                    } else {
                        this.lasfilacaptura = [];
                        resolve( resp.respuesta.mensaje );
                    }
                  }, error => {
                    // this.iuservce.alertaInformativa('Existe un error: ' + error, 'Falla de aplicacion servicio filatrabajo');
                    resolve(JSON.parse (error));
                  });

  });
  }

  seguimientoupdate(lositems: string ) {
    const data = { idusuario: this.USERSERVICE.idusuario ,   items: lositems} ;

    return new Promise( resolve => {
                this.http.post<any>(`${ URL }/captu/seguibusfolupdate/${ this.USERSERVICE.token }`, data)
                  .subscribe( async resp => {
                    if ( resp.error === false && resp.filasafectadas === 1) {
                      resolve(true);
                    } else if ( resp.error === false && resp.filasafectadas === 0 ) {
                      resolve(true);
                  } else {
                    resolve( resp.respuesta.mensaje );
                  }
                }, error => {
                 resolve(JSON.parse (error));
                });

  });
  }

  verdetallestatus(lositems: string , tipoconsulta: string ) {
    const data = { idusuario: this.USERSERVICE.idusuario , campos: tipoconsulta, items: lositems} ;

    return new Promise( resolve => {
                this.http.post<any>(`${ URL }/Filat/detalle/${ this.USERSERVICE.token }`, data)
                  .subscribe( async resp => {
                   if ( resp.error === false && resp.respuesta.estatus === 'data' && resp.respuesta.filas.length > 0) {
                        this.lasfilasprogress = resp.respuesta.filas;
                        resolve(true);
                      } else if (resp.error === false && resp.respuesta.filas.length === 0 ) {
                        this.lasfilasprogress = [];
                        resolve('No hay datos');
                    } else {
                        this.lasfilasprogress = [];
                        resolve( resp.respuesta.mensaje );
                    }
                  }, error => {
                    // this.iuservce.alertaInformativa('Existe un error: ' + error, 'Falla de aplicacion servicio filatrabajo');
                    resolve(JSON.parse (error));
                  });

  });
  }


  BuscarXid(id: string , tipoconsulta: string ) {
    const data = { idusuario: this.USERSERVICE.idusuario , campos: tipoconsulta, items: id} ;
    return new Promise( resolve => {
                this.http.post<any>(`${ URL }/Filat/buscarweb/${ this.USERSERVICE.token }`, data)
                  .subscribe( async resp => {
                   console.log(resp.respuesta.filas);
                   if ( resp.error === false && resp.respuesta.estatus === 'data' && resp.respuesta.filas.length > 0) {
                        this.Elid = resp.respuesta.filas[0];
                        resolve(true);
                      } else if (resp.error === false && resp.respuesta.filas.length === 0 ) {
                        this.Elid = {};
                        resolve('No hay datos');
                    } else {
                        this.Elid = {};
                    }
                  }, error => {
                    resolve(JSON.parse (error));
                  });

  });
  }


  verdetallhistoria(id: string   ) {
    const data = { idusuario: this.USERSERVICE.idusuario ,   items: id} ;
    return new Promise( resolve => {
                this.http.post<any>(`${ URL }/Filat/buscarhisto/${ this.USERSERVICE.token }`, data)
                  .subscribe( async resp => {
                   if ( resp.error === false && resp.respuesta.estatus === 'data' && resp.respuesta.filas.length > 0) {
                        this.ArrayLositems = resp.respuesta.filas;
                        resolve(true);
                      } else if (resp.error === false && resp.respuesta.filas.length === 0 ) {
                        this.ArrayLositems = [];
                        resolve('No hay datos');
                    } else {
                        this.ArrayLositems = [];
                    }
                  }, error => {
                    resolve(JSON.parse (error));
                  });

  });
  }


mostrarUsuarios(desde: number, termino: string) {
  const data = { idusuario: this.USERSERVICE.idusuario , items : termino} ;
  // console.log(data);
  return new Promise( resolve => {
    this.http.post<any>(`${ URL }/ctrolusers/mostarUsers/${ this.USERSERVICE.token }/${ desde }`, data)
      .subscribe( async resp => {

       if ( resp.error === false && resp.respuesta.filas.length > 0) {
            this.losusuarios = resp.respuesta.filas;
            this.numregisUsers = resp.Numfilas;
            resolve(true);
        } else if (resp.error === false && resp.respuesta.filas.length === 0 ) {
            this.losusuarios = [];
            resolve('No hay datos');
        } else {
            this.losusuarios = [];
            resolve( resp.respuesta.mensaje );
        }
      }, error => {
        resolve(JSON.parse (error));
      });
});
}

catalogoweb(catalogo: string) {
  const data = { idusuario: this.USERSERVICE.idusuario , tipo : catalogo} ;
  return new Promise( resolve => {
    this.http.post<any>(`${ URL }/Selectweb/catalogo/${ this.USERSERVICE.token }`, data)
      .subscribe( async resp => {
       if ( resp.error === false && resp.respuesta.filas.length > 0) {
            this.losroles = resp.respuesta.filas;
            resolve(true);
        } else if (resp.error === false && resp.respuesta.filas.length === 0 ) {
            this.losroles = [];
            resolve('No hay datos');
        } else {
            this.losroles = [];
            resolve( resp.respuesta.mensaje );
        }
      }, error => {
        resolve(JSON.parse (error));
      });
});
}


catalogowebarray(catalogo: string) {
  const data = { idusuario: this.USERSERVICE.idusuario , tipo : catalogo} ;
  return new Promise( resolve => {
    this.http.post<any>(`${ URL }/Selectweb/catalogo/${ this.USERSERVICE.token }`, data)
      .subscribe( async resp => {
       if ( resp.error === false && resp.respuesta.filas.length > 0) {
            this.userasug = resp.respuesta.filas;
            resolve(true);
        } else if (resp.error === false && resp.respuesta.filas.length === 0 ) {
            this.userasug = [];
            resolve(false);
        } else {
            this.userasug = [];
            resolve( resp.respuesta.mensaje );
        }
      }, error => {
        resolve(JSON.parse (error));
      });
});
}

async updatesol(lositems: string, paracampos: string) {
  const data = {items: lositems, idusuario: this.USERSERVICE.idusuario, version: '0' , campos: paracampos};
  return new Promise( resolve => {
    this.http.post<any>(`${ URL }/captuprosp/ProcesarUpdate/${ this.USERSERVICE.token }`, data)
      .subscribe( async resp => {

       if ( resp.error === false && resp.filasafectadas === 1) {
            resolve(true);
          } else if ( resp.error === false && resp.filasafectadas === 0 ) {
            resolve(true);
        } else {
          resolve( resp.respuesta.mensaje );
        }
      }, error => {
       resolve(JSON.parse (error));
      });
});
 }


 //// validdo para contable ini
 async insertservicio(lositems: string, lacantidad: string, elprecio:string, elimporte:string, ladescripcion:string) {
  const data = {items: lositems, 
                idusuario: this.USERSERVICE.idusuario, 
                prefijo: this.txtprefijo,
                cantidad: lacantidad,
                precio: elprecio,
                importe: elimporte,
                descripcion: ladescripcion};
   // console.log(data);
   // console.log(`${ URL }/captu/Insertservicio/${ this.USERSERVICE.token }`);
  return new Promise( resolve => {
    this.http.post<any>(`${ URL }/captu/Insertservicio/${ this.USERSERVICE.token }`, data)
      .subscribe( async resp => {

       if ( resp.error === false && resp.filasafectadas === 1) {
            this.folioasignado = resp.folio;
            resolve(true);
          } else if ( resp.error === false && resp.filasafectadas === 0 ) {
            resolve(true);
        } else {
          resolve( resp.respuesta.mensaje );
        }
      }, error => {
       resolve(JSON.parse (error));
      });
    });
 }


 async  datosfactu(fatura: string) {
  const data = { idusuario: this.USERSERVICE.idusuario , prefijo : fatura} ;
  // console.log(data);
  // console.log(`${ URL }/captu/datosfactura/${ this.USERSERVICE.token }`);
  return new Promise( resolve => {
    this.http.post<any>(`${ URL }/captu/datosfactura/${ this.USERSERVICE.token }`, data)
      .subscribe( async resp => {
       if ( resp.error === false && resp.respuesta.fila.length === 1) {
            this.parapdf = resp.respuesta.fila[0];
            this.lasdescripcionesparapdf = resp.respuesta.detalle;
            resolve(true);
        } else if (resp.error === false && resp.respuesta.fila.length === 0 ) {
            this.parapdf = {};
            this.lasdescripcionesparapdf = [];
            resolve(false);
        } else {
            this.parapdf = {};
            this.lasdescripcionesparapdf = [];
            resolve( resp.respuesta.mensaje );
        }
      }, error => {
        resolve(JSON.parse (error));
      });
});
}


 //// validdo para contable fin

 editsol(lositems: string, ejecutar: string) {
  const data = { idusuario: this.USERSERVICE.idusuario , campos: ejecutar, items: lositems + '|' + this.USERSERVICE.idusuario };

  Swal.fire({
    allowOutsideClick: false,
    icon: 'info',
    text: 'Espere por favor...'
  });
  Swal.showLoading();

  return new Promise( resolve => {
    this.http.post<any>(`${ URL }/Filat/editar/${ this.USERSERVICE.token }`, data)
      .subscribe( async resp => {
       Swal.close();
       console.log(resp);
       if ( resp.error === false && resp.filasafectadas === 1) {
            resolve(true);
          } else if ( resp.error === false && resp.filasafectadas === 0 ) {
            Swal.fire({
              allowOutsideClick: true,
              icon: 'error',
              title: 'Aviso',
              text: 'No hubo cambios en los datos'
            });
            resolve(false);
        } else {
            Swal.fire({
              allowOutsideClick: true,
              icon: 'error',
              title: 'Error',
              text: resp.mensaje
            });
            resolve(null);
        }
      }, error => {
        Swal.fire({
          allowOutsideClick: true,
          icon: 'error',
          title: 'Oops...',
          text: 'Falla de aplicacion servicio Filat/editar' + JSON.stringify(error)
        });
        resolve(null);
      });
});
 }

 asigmensacv(lositems: string,  elidcurier: string) {
  const data = { idusuario: this.USERSERVICE.idusuario , numcurier: elidcurier, items: lositems , };

  Swal.fire({
    allowOutsideClick: false,
    icon: 'info',
    text: 'Asignado, espere por favor...'
  });
  Swal.showLoading();

  return new Promise( resolve => {
    this.http.post<any>(`${ URL }/Filat/insertcuriercv/${ this.USERSERVICE.token }`, data)
      .subscribe( async resp => {
       Swal.close();
       if ( resp.error === false  && resp.respuesta.filas.length > 0) {
            this.ResultINgresoBlosomcv = resp.respuesta.filas;
            resolve(true);
          } else if ( resp.error === false  && resp.respuesta.filas.length  === 0 ) {
            this.ResultINgresoBlosomcv = [];
            Swal.fire({
              allowOutsideClick: true,
              icon: 'error',
              title: 'Aviso',
              text: 'No hubo cambios en los datos'
            });
            resolve(false);
        } else {
            this.ResultINgresoBlosomcv = [];
            Swal.fire({
              allowOutsideClick: true,
              icon: 'error',
              title: 'Error',
              text: resp.mensaje
            });
            resolve(null);
        }
      }, error => {
        this.ResultINgresoBlosomcv = [];
        Swal.fire({
          allowOutsideClick: true,
          icon: 'error',
          title: 'Oops...',
          text: 'Falla de aplicacion servicio asigcurier' + JSON.stringify(error)
        });
        resolve(null);
      });
});
 }

 asigmensa(lositems: string, lafecha: string, elstatus: string) {
  const data = { idusuario: this.USERSERVICE.idusuario , fecha: lafecha, items: lositems , estatus: elstatus   };

  Swal.fire({
    allowOutsideClick: false,
    icon: 'info',
    text: 'Actualizado, espere por favor...'
  });
  Swal.showLoading();

  return new Promise( resolve => {
    this.http.post<any>(`${ URL }/Filat/insertcurier/${ this.USERSERVICE.token }`, data)
      .subscribe( async resp => {
       Swal.close();
       if ( resp.error === false  && resp.respuesta.filas.length > 0) {
            this.ResultINgresoBlosom = resp.respuesta.filas;
            resolve(true);
          } else if ( resp.error === false  && resp.respuesta.filas.length  === 0 ) {
            this.ResultINgresoBlosom = [];
            Swal.fire({
              allowOutsideClick: true,
              icon: 'error',
              title: 'Aviso',
              text: 'No hubo cambios en los datos'
            });
            resolve(false);
        } else {
            this.ResultINgresoBlosom = [];
            Swal.fire({
              allowOutsideClick: true,
              icon: 'error',
              title: 'Error',
              text: resp.mensaje
            });
            resolve(null);
        }
      }, error => {
        this.ResultINgresoBlosom = [];
        Swal.fire({
          allowOutsideClick: true,
          icon: 'error',
          title: 'Oops...',
          text: 'Falla de aplicacion servicio asigcurier' + JSON.stringify(error)
        });
        resolve(null);
      });
});
 }



}
