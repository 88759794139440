import { Injectable, EventEmitter } from '@angular/core';
import { UsuariointerfaseUNO} from '../../interfaces/intersaseusuario';
import { Losroles } from '../../model/usuario.model';

@Injectable({
  providedIn: 'root'
})
export class ModalUuserService {
  public ocultoMUser = 'oculto';
  public Arrayroles: Losroles[] = [
    {id: 1, descripcion: 'admin'},
    {id: 2, descripcion: 'remitente'},
    {id: 4, descripcion: 'captura'},
    {id: 10, descripcion: 'Reporte'}
  ];

    // formul
    public username: string;
    public iduser: string;
    public nombre: string;
    public rol: string;
    public vigente: string;
    public tel: string;
    public extencion: string;
    public tel1: string;
    public calle: string;
    public confirmado: string;
    public col: string;
    public delmun: string;
    public edo: string;
    public cp: string;
    public area: string;
    public activo: boolean;
    public referencia: string;
    public llave: string;
    // public roles: Losroles =  new Losroles('');

    // notificacion
     public notificaion: EventEmitter<any> = new EventEmitter<any>();


  constructor() {

  }



 MostrarModal(unUsuario: UsuariointerfaseUNO= {}) {
  this.iduser = unUsuario.iduser;
  this.username = unUsuario.username;
  this.nombre = unUsuario.nombre;
  this.rol = unUsuario.rol;
  this.activo = (unUsuario.vigente === '1') ? true : false;
  this.tel = unUsuario.tel;
  this.extencion = unUsuario.extencion;
  this.confirmado = unUsuario.confirmado;
  this.tel1 = unUsuario.tel1;
  this.calle = unUsuario.calle;
  this.col = unUsuario.col;
  this.delmun = unUsuario.delmun;
  this.edo = unUsuario.edo;
  this.cp = unUsuario.cp;
  this.area = unUsuario.area;
  // this.rol = unUsuario.rol;
  this.referencia = unUsuario.referencia;


  this.ocultoMUser = '';
  }

  verultimomodal() {
    this.ocultoMUser = '';
  }

  Precerrarmomodal() {
    this.ocultoMUser = 'oculto';
  }

  MostrarModalNuevo() {
    this.username =  null ;
    this.iduser = '';
    this.nombre = null;
    this.tel = null;
    this.extencion = null;
    this.tel1 = null;
    this.calle = null;
    this.col = null;
    this.delmun = null;
    this.edo = null;
    this.cp = null;
    this.area = null;
    this.rol = null;
    this.vigente = null;
    this.referencia = null;
    this.activo = true;
    this.ocultoMUser = '';
    }


  cerrarModal() {
    this.ocultoMUser = 'oculto';
    this.username = null;
    this.iduser = null;
    this.nombre = null;
    this.tel = null;
    this.extencion = null;
    this.tel1 = null;
    this.calle = null;
    this.col = null;
    this.delmun = null;
    this.edo = null;
    this.cp = null;
    this.area = null;
    this.rol = null;
    this.vigente = null;
    this.referencia = null;
    this.activo = null;
  }


}
