import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { APP_ROUTES } from './app.routes';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import {HttpClientModule} from '@angular/common/http';

// servicios
import { ServiceModule } from './services/service.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

// modulos
import { PagesModule } from './pages/pages.module';
import { ModalloginComponent } from './components/modallogin/modallogin.component';
import { BuscarComponent } from './pages/buscar/buscar.component';

// exportar a excel:
// npm install xlsx --save
// añadir 2 valores , ViewChild, ElementRef en @angular/core
// añadir  @ViewChild('epltable', { static: false }) epltable: ElementRef; despues del oninit{
// añadir div en la tabla  <div class="col-md-12 table-responsive" #epltable>

// npm install moment --save
// import * as moment from 'moment';


// mapbox ---
// user usermapsiman
// correo gabriel.estrada@gruposiman.mx
// pass el default de rs + año YYYY de enero 1er
// installacion https://www.youtube.com/watch?v=gdKWeboPxgY
// abrir mapbox seleccionar JS web
// seleccionar NPM, instalar y pegar los estios en index.html en head


// pdf maker impriomir
//  npm install pdfmake --save


import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ModalloginComponent,
    BuscarComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    APP_ROUTES,
    HttpClientModule,
    ServiceModule,
    PagesModule,
    NgbModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
