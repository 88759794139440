<div class="fondo-negro animated fadeIn" [ngClass]="servmodaluser.ocultoMUser">
<div class="modal"  style="display:block;" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered  modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Registro: {{  servmodaluser.username }}</h5>
          <!-- <button  (click)="servmodaluser.cerrarModal()" type="button" class="close pointer"  aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button> -->
        </div>
        <div class="modal-body">
                <form ngNativeValidate #f="ngForm"   >
                  
                    <div class="form-group" *ngIf="(servmodaluser.iduser=='XXXXX')">
                        <label for="inputNombre">Usuario</label>
                        <input type="text" [(ngModel)]="servmodaluser.username" 
                               class="form-control" 
                               name="inputusername"
                               maxlength="15"
                               minlength="3"
                               id="inputusername" 
                               required>
                      </div>

                      <div class="form-group">
                        
                        <label for="inputNombre">
                          <div *ngIf="inputNombre.invalid && (inputNombre.dirty || inputNombre.touched) ">
                            <div *ngIf="inputNombre.errors.pattern || inputNombre.errors.required" class="text-danger">
                              Nombre* (requerido y sin caracteres extraños)
                            </div>                                                                                            
                          </div>
                          <div *ngIf="inputNombre.valid || (!inputNombre.dirty && !inputNombre.touched)"  >Nombre</div>
                      </label>
                        <input type="text" [(ngModel)]="servmodaluser.nombre" 
                               class="form-control" 
                               #inputNombre = "ngModel"
                               pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ ]*"
                               name="inputNombre"
                               id="inputNombre" 
                               maxlength="200"
                               required>
                               
                      </div>

                        <div class="form-row">
                          <div class="form-group col-md-4">
                            <label for="inputtelefono">
                              
                              <div *ngIf="inputtelefono.invalid && (inputtelefono.dirty || inputtelefono.touched) ">
                                <div *ngIf="inputtelefono.errors.pattern || inputtelefono.errors.required" class="text-danger">
                                  Teléfono* (sin caracteres extraños)
                                </div>                                                                                            
                              </div>
                              <div *ngIf="inputtelefono.valid || (!inputtelefono.dirty && !inputtelefono.touched)"  >Teléfono</div>
                            </label>
                            <input type="text"  
                                  #inputtelefono = "ngModel"
                                   [(ngModel)]="servmodaluser.tel" 
                                   class="form-control" 
                                   pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9 ,.#_/-]*"
                                   name="inputtelefono" 
                                   maxlength="100"
                                   id="inputtelefono" required>
                          </div>


                          <div class="form-group col-md-4">
                            <label for="inputext">
                              <div *ngIf="inputext.invalid   ">
                                <div *ngIf="inputext.errors.pattern " class="text-danger">
                                  Extención* (sin caracteres extraños)
                                </div>                                                                                            
                              </div>
                              <div *ngIf="inputext.valid"  >Extención</div>
                            </label>
                            <input type="text"  
                                   [(ngModel)]="servmodaluser.extencion"  
                                   class="form-control" 
                                   #inputext = "ngModel"
                                   pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9 ,.#_/-]*"
                                   id="inputext"  
                                   maxlength="100"
                                   name="inputext" >
                          </div>
                          <div class="form-group col-md-4">
                            <label for="inputtelefono1"> 
                              <div *ngIf="inputtelefono1.invalid   ">
                                <div *ngIf="inputtelefono1.errors.pattern " class="text-danger">
                                  Teléfono 2* (sin caracteres extraños)
                                </div>                                                                                            
                              </div>
                              <div *ngIf="inputtelefono1.valid"  >Teléfono 2</div>

                            </label>
                            <input type="text"  
                                   [(ngModel)]="servmodaluser.tel1" 
                                   #inputtelefono1 = "ngModel"
                                   pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9 ,.#_/-]*"
                                   class="form-control" 
                                   name="inputtelefono1" 
                                   maxlength="100"
                                   id="inputtelefono1" >
                          </div>
                        </div>
                     

                        <div class="form-row">
                          <div class="form-group col-md-6">
                            <label for="inputcalle">
                              <div *ngIf="inputcalle.invalid && (inputcalle.dirty || inputcalle.touched) ">
                                <div *ngIf="inputcalle.errors.pattern || inputcalle.errors.required" class="text-danger">
                                  Calle* (requerido sin caracteres extraños)
                                </div>                                                                                            
                              </div>
                              <div *ngIf="inputcalle.valid || (!inputcalle.dirty && !inputcalle.touched)"  >Calle</div>
                            </label>
                            <input type="text"  
                                   [(ngModel)]="servmodaluser.calle" 
                                   class="form-control" 
                                   #inputcalle = "ngModel"
                                   pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9 ,.#_/-]*"
                                   name="inputcalle" 
                                   maxlength="100"
                                   id="inputcalle" required>
                          </div> 
                          <div class="form-group col-md-6">
                            <label for="inputcol">
                              <div *ngIf="inputcol.invalid && (inputcol.dirty || inputcol.touched) ">
                                <div *ngIf="inputcol.errors.pattern || inputcol.errors.required" class="text-danger">
                                  Col* (requerido sin caracteres extraños)
                                </div>                                                                                            
                              </div>
                              <div *ngIf="inputcol.valid || (!inputcol.dirty && !inputcol.touched)"  >Col</div>
                            </label>
                            
                            <input type="text"  
                                   [(ngModel)]="servmodaluser.col" 
                                   class="form-control" 
                                   #inputcol = "ngModel"
                                   pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9 ,.#_/-]*"
                                   name="inputcol" 
                                   maxlength="100"
                                   id="inputcol" required>
                          </div>
                        </div>

                      

                        <div class="form-row">
                          <div class="form-group col-md-4">
                            <label for="inputdelmun">
                              <div *ngIf="inputdelmun.invalid && (inputdelmun.dirty || inputdelmun.touched) ">
                                <div *ngIf="inputdelmun.errors.pattern || inputdelmun.errors.required" class="text-danger">
                                  AlcMun* (requerido sin caracteres extraños)
                                </div>                                                                                            
                              </div>
                              <div *ngIf="inputdelmun.valid || (!inputdelmun.dirty && !inputdelmun.touched)"  >AlcMun</div>

                            </label>
                            <input type="text"  
                                   [(ngModel)]="servmodaluser.delmun" 
                                   class="form-control" 
                                   name="inputdelmun" 
                                   #inputdelmun = "ngModel"
                                   pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9 ,.#_/-]*"
                                   maxlength="100"
                                   id="inputdelmun" required>
                          </div> 
                          <div class="form-group col-md-4">
                            <label for="inputedo">
                              <div *ngIf="inputedo.invalid && (inputedo.dirty || inputedo.touched) ">
                                <div *ngIf="inputedo.errors.pattern || inputedo.errors.required" class="text-danger">
                                  Edo* (requerido sin caracteres extraños)
                                </div>                                                                                            
                              </div>
                              <div *ngIf="inputedo.valid || (!inputedo.dirty && !inputedo.touched)"  >Edo</div>

                            </label>
                            <input type="text"  
                                   [(ngModel)]="servmodaluser.edo" 
                                   class="form-control" 
                                   name="inputedo" 
                                   #inputedo = "ngModel"
                                   pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9 ,.#_/-]*"
                                   maxlength="100"
                                   id="inputedo" required>
                            </div> 
                            <div class="form-group col-md-4">
                              <label for="inputcp">
                                <div *ngIf="inputcp.invalid && (inputcp.dirty || inputcp.touched) ">
                                  <div *ngIf="inputcp.errors.pattern || inputcp.errors.required" class="text-danger">
                                    CP* (requerido solo número)
                                  </div>                                                                                            
                                </div>
                                <div *ngIf="inputcp.valid || (!inputcp.dirty && !inputcp.touched)"  >CP</div>
                              </label>
                              <input type="text"  
                                     [(ngModel)]="servmodaluser.cp" 
                                     class="form-control" 
                                     #inputcp = "ngModel"
                                     pattern="[0-9]*"
                                     name="inputcp" 
                                     maxlength="5"
                                     id="inputcp" required>
                              </div> 
                        </div>
 


                        <div class="form-row">
                          <div class="form-group col-md-6">
                            <label for="inputarea">
                              <div *ngIf="inputarea.invalid && (inputarea.dirty || inputarea.touched) ">
                                <div *ngIf="inputarea.errors.pattern || inputarea.errors.required" class="text-danger">
                                  Area* (requerido sin caracteres extraños)
                                </div>                                                                                            
                              </div>
                              <div *ngIf="inputarea.valid || (!inputarea.dirty && !inputarea.touched)"  >Area</div>

                            </label>
                            <input type="text"  
                                   [(ngModel)]="servmodaluser.area" 
                                   class="form-control" 
                                   name="inputarea" 
                                   #inputarea = "ngModel"
                                   pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9 ,.#_/-]*"
                                   maxlength="100"
                                   id="inputarea" required>
                          </div> 
                          <div class="form-group col-md-6">
                            <label for="inputreferencia">
                              <div *ngIf="inputreferencia.invalid && (inputreferencia.dirty || inputreferencia.touched) ">
                                <div *ngIf="inputreferencia.errors.pattern || inputreferencia.errors.required" class="text-danger">
                                  Referencia* (requerido sin caracteres extraños)
                                </div>                                                                                            
                              </div>
                              <div *ngIf="inputreferencia.valid || (!inputreferencia.dirty && !inputreferencia.touched)"  >Referencia</div>

                            </label>
                            <input type="text"  
                                   [(ngModel)]="servmodaluser.referencia" 
                                   class="form-control" 
                                   name="inputreferencia" 
                                   #inputreferencia = "ngModel"
                                   pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9 ,.#_/-]*"
                                   maxlength="100"
                                   id="inputreferencia" required >
                          </div> 
                        </div>

 

                        <div class="form-group"  *ngIf="(servmodaluser.confirmado!='0' && ususervrce.usuario.rol == '1' && servmodaluser.iduser!='')"> 
                        <div class="form-row">                         
                          <div class="form-group col-md-4">
                            <label for="inputState">Rol</label>
                            
                            <select  [(ngModel)]="servmodaluser.rol" name="elrol" class="form-control" >
                              <option value="">Seleccione Rol</option>
                              <option *ngFor="let rol of ususervrce.catrol" [value]="rol.id">
                                    {{ rol.descripcion }}
                              </option>
                            </select>


                          </div>
                          
                       
                          <div class="form-group col-md-4" *ngIf="(servmodaluser.iduser!='')"  >
                              <label for="inputState">Estatus</label>
                              <div class="form-check">
                                <input  name="activo" 
                                        id="activo" 
                                        [(ngModel)]="servmodaluser.activo" 
                                        class="form-check-input" 
                                        type="checkbox" 
                                        id="gridCheck" 
                                        [checked]="servmodaluser.activo">
                                <label class="form-check-label" for="gridCheck">
                                  Activo
                                </label>
                              </div>
                          </div>

                          <div class="form-group col-md-4" *ngIf="(servmodaluser.iduser!='')" >    
                            <label for="inputState">Contraseña</label>                      
                            <div class="form-check">
                              <input name ="reset" 
                                     id="reset"  
                                     [(ngModel)]="elreset" 
                                     class="form-check-input" 
                                     type="checkbox" 
                                     id="gridCheckReset"  >
                              <label class="form-check-label" for="gridCheckReset">
                                Reset
                              </label>
                            </div>
                        </div>

                        </div>
                        
                        
                     </div>
 

                     <div class="form-group"  *ngIf="(servmodaluser.confirmado =='0' )"> 
                      <div class="form-row">                         
                        <div class="form-group col-md-8">
                          <label for="inputllave">
                            <div *ngIf="inputllave.invalid && (inputllave.dirty || inputllave.touched) ">
                              <div *ngIf="inputllave.errors.required" class="text-danger">
                                Llave enviada a tu correo* (requerido)
                              </div>                                                                                            
                            </div>
                            <div *ngIf="inputllave.valid || (!inputllave.dirty && !inputllave.touched)"  >Llave enviada a tu correo</div>
                          </label>
                          <input type="text"  
                          #inputllave = "ngModel"
                          [(ngModel)]="servmodaluser.llave" 
                          class="form-control"                           
                          name="inputllave" 
                          maxlength="50"
                          id="inputllave" 
                          required>
                           
                        </div>
                      </div>
                   </div>

                     
                      </form>

         
        </div>
        <div class="modal-footer">
          <button (click)="cerrarm(f)" type="button" class="btn btn-secondary"  >Cerrar</button>
          <button type="button" [disabled]="!f.valid" (click)="onClickOK(f )" class="btn btn-primary" >Aceptar</button>
        </div>
      </div>
    </div>
  </div>
</div>