import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
// import { Observable } from 'rxjs';
import { AuthService } from '../apirest/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

constructor( public userser: AuthService,
             public router: Router ) {
}

  canActivate() {
     switch (this.userser.usuario.rol) {
       case '1':
          return true;
          break;
       default:
        this.router.navigate(['/login']);
        return false;
        break;
     }
  }

}
