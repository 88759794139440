
<div class="row animated fadeIn">
    <div class="col-12">
        <div class="card">
 
            <div class="card-body text-center">
                
                
                  <form ngNativeValidate [formGroup]="forma"   id="loginformBuscar" >
                    <div class="form-row align-items-center">

                        <div class="col-auto">
                            
                            <select formControlName="festatus" name="festatus" [(ngModel)]="txtstatus" class="form-control" id="st"  required>
                               
                              <option *ngFor="let rol of Arraystatus" [value]="rol.id">
                                {{ rol.descripcion }} 
                              </option>
 
                            </select>
                        </div>
 
                        <div class="col-md-auto" *ngIf ="txtstatus == 'folio'">
                            <input type="text" formControlName="fprefijo"  [(ngModel)]="txtprefijo"  name="fprefijo" class="form-control" id="fprefijo"  placeholder="folio..."   >
                        </div>

                        <div class="col-auto" *ngIf ="txtstatus != 'folio'">                        
                            <input type="date" formControlName="ffini"  [(ngModel)]="txtfini"  name="ffini" class="form-control" id="f1"    >
                          </div>
                          <div class="col-auto" *ngIf ="txtstatus != 'folio'">                                                    
                            <input type="date" formControlName="fffin" [(ngModel)]="txtffin"  name="fffin" class="form-control" id="f2"  >                            
                          </div>                         
                          <div class="col-auto">
                            <button type="submit" [disabled]="!forma.valid" class="btn btn-primary mb-2" (click)="onClickdetalle2()">Mostrar</button>
                          </div>
                          <div class="col-auto">
                            <label >{{ dataservice.lasfilasprogress.length }}</label>
                        </div>
                    </div>
                </form>                
            </div>
        </div>
    </div>
</div>

<div class="col-md-12 table-responsive" #epltable>
    <div class="row" *ngIf="dataservice.lasfilasprogress.length>0">
        <div class="col-md-8">        
                <input type="text"  
                    class="form-control" 
                    name="filterPost" 
                    placeholder="filtrar destinatario..."   
                    [(ngModel)] = "filterPost">
        </div>
    
         <div class="col-md-4">
                <button (click)="exportToExcel()" class="btn btn-success">Exportar a Excel</button> 
         </div>
        
       </div>

       <div id="div1600px">    
<table  class="table table-striped table-bordered" >
             
    <thead class="thead-light">
    
    <tr>
        <th scope="col">ID</th>
        <th scope="col">Folio</th>
        <th scope="col">Remitente</th>
        <th scope="col">Destinatario</th>
        <th scope="col">Fecha Solicitud</th>
        <th scope="col">Referencia</th>
        <th scope="col">Comentarios</th>
        <th scope="col">Respuesta</th>
        <th scope="col">Descripción</th>
        <!-- <th scope="col">calle</th>
        <th scope="col">col</th>
        <th scope="col">delmun</th>
        <th scope="col">edo</th>
        <th scope="col">cp</th>
        <th scope="col">recibio</th>
        <th scope="col">frespuesta</th>
        <th scope="col">observacion</th>
        <th scope="col">recepCorresp</th>
        <th scope="col">calleR</th>
        <th scope="col">ColR</th>
        <th scope="col">DelR</th>
        <th scope="col">edoR</th>
        <th scope="col">CpR</th> -->
                    
      </tr>
    </thead>
                <tbody> 
                        <!-- si marca errro add import { CommonModule } from '@angular/common'; -->
                        <tr *ngFor="let item of dataservice.lasfilasprogress | filterfactu: filterPost">
                            <td>
                                {{ item.id }}
                            </td>
                          <td>                                
                              <button type="button" (click)="onClickzoom( item.id , item.folio)" class="btn btn-link">{{ item.folio}}</button>
                          </td>
                          
                        <td>
                            {{ item.remitente }}
                        </td>
                        <td>
                            {{ item.destinatario }}
                        </td>
                        <td>
                            {{ item.fsolicitud }}
                        </td>
                        <td>
                            {{ item.referencia }}
                        </td>
                        <td>
                            {{ item.comentarios }}
                        </td>
                        <td>
                            {{ item.resp }}
                        </td>
                        <td>
                            {{ item.descripcion }}
                        </td>
                        <!-- <td>
                            {{ item.calle }}
                        </td>
                        <td>
                            {{ item.col }}
                        </td>
                        <td>
                            {{ item.delmun }}
                        </td>
                        <td>
                            {{ item.edo }}
                        </td>
                        <td>
                            {{ item.cp }}
                        </td>
                        <td>
                            {{ item.recibio }}
                        </td>
                        <td>
                            {{ item.frespuesta }}
                        </td>
                        <td>
                            {{ item.observacion }}
                        </td>
                        <td>
                            {{ item.recepCorresp }}
                        </td>
                        <td>
                            {{ item.calleR }}
                        </td>
                        <td>
                            {{ item.ColR }}
                        </td>
                        <td>
                            {{ item.DelR }}
                        </td>
                        <td>
                            {{ item.edoR }}
                        </td>
                        <td>
                            {{ item.CpR }}
                        </td> -->

                      </tr>  
                </tbody>
            </table>
        </div>
    </div>