<div class="row animated fadeIn">
  <div class="col-12">
      <div class="card">

          <div class="card-body text-center">
              
              
                <form ngNativeValidate [formGroup]="formacorresp"   id="loginformBuscar8" >
                  <div class="form-row align-items-center">
  

                      <div class="col-auto">                        
                          <input  type="text"  
                                  formControlName="frefcorresp" 
                                  maxlength="150"  
                                  [(ngModel)]="txtrefcorresp"  
                                  name="frefcorresp" 
                                  class="form-control" 
                                  placeholder="Folio..."
                                  id="frefcorresp"  required >
                       </div>
                       <div class="col-auto">
                        <button   type="submit" 
                                  [disabled]="!formacorresp.valid" 
                                  class="btn btn-primary" 
                                  (click)="onClickdetalle2()">Mostrar</button>
                      </div>
                    
                       <div  *ngIf="formacorresp.controls['frefcorresp'].invalid  && (formacorresp.controls['frefcorresp'].dirty || formacorresp.controls['frefcorresp'].touched)" class="text-danger">
                        <div *ngIf="formacorresp.controls['frefcorresp'].errors.pattern || formacorresp.controls['frefcorresp'].errors.minlength ">
                            sin caracteres extraños y min 5 caracteres
                        </div>
                      <div *ngIf="formacorresp.controls['frefcorresp'].errors.required">
                        requerido
                    </div>
                    </div>       
                  </div>
              </form>                
          </div>
      </div>
  </div>
</div>
 
