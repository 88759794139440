import { Component, OnInit } from '@angular/core';
import { ActivatedRoute  } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ServicedataService } from './../../services/service.index';
import Swal from 'sweetalert2';
import {Camposingresocv } from './../../interfaces/campos';
import { Catuserasignacion } from '../../interfaces/userasignacion';
import { AuthService } from '../../services/apirest/auth.service';

@Component({
  selector: 'app-asignarcv',
  templateUrl: './asignarcv.component.html',
  styleUrls: ['./asignarcv.component.css']
})
export class AsignarcvComponent implements OnInit {
  formacv: FormGroup;
  INgresoBlosomcv: Array<Camposingresocv> = [];
  display = 'none';
  filterPost = '';
  constructor(public activatedRoute: ActivatedRoute,
              public dataservice: ServicedataService,
              private dtauser: AuthService
    ) {
      this.formacv = new FormGroup({
        ffactucv: new FormControl( null , [Validators.required])
      }  );
    }

ngOnInit() {
// if (this. dtauser.usuario.rol === '99') {
// this.inicatauser();
// // console.log(this. dtauser.usuario);
// // console.log(this.dataservice.userasug);
// }
}

async inicatauser() {
Swal.fire({
  title: 'cargando catalogo de mensajeros',
  allowOutsideClick: false,
  icon: 'info'
});
Swal.showLoading();

const valido = await this.dataservice.catalogowebarray('userasig');
Swal.close();
switch ( valido ) {
  case true:
  break;
  case 'No hay datos':
      Swal.fire({
      text: 'No hay datos',
      icon: 'warning',
      confirmButtonText: 'Cerrar'
      }).then((result) => {
      });
      break;
      default:
      Swal.fire({
        text: String(valido),
        icon: 'warning',
        confirmButtonText: 'Cerrar'
      }).then((result) => {
      });
      break;
    }
}

ingresar(termino: string) {
if (this.formacv.invalid) { return; }

for (const item of this.INgresoBlosomcv) {
    if (termino === item.cv ) {
Swal.fire({
  text: 'ya existe en la rejilla, verifica',
  icon: 'warning',
  confirmButtonText: 'Cerrar'
}).then((result) => {
  return;
});
return;
}
}



const customObjcv = new Camposingresocv();
customObjcv.cv = termino;
customObjcv.Prefijo = null;
customObjcv.Factura = null;
customObjcv.estatus = null;
customObjcv.idmensajero = null;
customObjcv.descripcion = null;
this.INgresoBlosomcv.push(customObjcv);
this.formacv.get('ffactucv').setValue(null);
}


async onClickzoom(asig: Catuserasignacion) {


this.display = 'none';
// console.log(asig);
// console.log( this.INgresoBlosom);
const confirmar = await Swal.fire({
title: 'Asignar',
text:  this.INgresoBlosomcv.length + ' registros a ' + asig.usuario + '(' + asig.id + ')',
    icon: 'warning',
    confirmButtonText: 'Si',
    cancelButtonText: 'No',
    showConfirmButton: true,
    showCancelButton: true
});

if (confirmar.dismiss === Swal.DismissReason.cancel) {
  this.display = 'block';
  return;
}


const codigoscv: string [] = [];
for (const item of this.INgresoBlosomcv) {
      codigoscv.push(item.cv);
}

// console.log(codigospre.join(','), codigos.join(','));
// return;
const valido = await this.dataservice.asigmensacv(codigoscv.join(',') , asig.id );

if (!valido) {
  return;
}

this.INgresoBlosomcv = this.dataservice.ResultINgresoBlosomcv;
}


btnasig() {
if (this.dataservice.ResultINgresoBlosomcv.length > 0) {
    Swal.fire({
    text: 'Limpia la rejilla del resultado de asignación',
    icon: 'warning',
    confirmButtonText: 'Cerrar'
    }).then((result) => {
    return;
});
    return;
}
this.display = 'block';
}

cerrarModal() {
this.display = 'none';
}



resetgrid() {
    this.INgresoBlosomcv = [];
    this.dataservice.ResultINgresoBlosomcv = [];
}

}
