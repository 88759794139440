export { AuthService} from './apirest/auth.service';
export { ServicedataService } from './apirest/servicedata.service';
export { SharedService } from './shared/shared.service';
export { SidebarService } from './shared/sidebar.service';
export {  ImportfileService } from './import/importfile.service';

// guards
export { AdminGuard } from './guards/admin.guard';
export { LoginGuardGuard } from './guards/login-guard.guard';
export { DepurarGuardGuard} from './guards/depurar-guard.guard';
export { VerifiGuardGuard} from './guards/verifi-guard.guard';



