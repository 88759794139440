<div class="row animated fadeIn">
    <div class="col-12">
        <div class="card">
 
            <div class="card-body text-center">
                
                
                  <form ngNativeValidate [formGroup]="forma"   id="loginformBuscar" >
                    <div class="form-row align-items-center">
    

                        <div class="col-auto">                        
                            <input  type="text" 
                                    formControlName="fref" 
                                    maxlength="150"  
                                    [(ngModel)]="txtref"  
                                    name="fref" 
                                    class="form-control" 
                                    placeholder="Folio..."
                                    id="fref"  required >
                         </div>
                         <div class="col-auto">
                          <button   type="submit" 
                                    [disabled]="!forma.valid" 
                                    class="btn btn-primary" 
                                    (click)="onClickdetalle2()">Mostrar</button>
                        </div>
                      
                         <div  *ngIf="forma.controls['fref'].invalid  && (forma.controls['fref'].dirty || forma.controls['fref'].touched)" class="text-danger">
                          <div *ngIf="forma.controls['fref'].errors.pattern || forma.controls['fref'].errors.minlength ">
                              sin caracteres extraños / min 5 caracteres
                          </div>
                        <div *ngIf="forma.controls['fref'].errors.required">
                          requerido
                      </div>
                      </div>       
                    </div>
                </form>                
            </div>
        </div>
    </div>
</div>

<div class="row animated fadeIn"  *ngIf="(dataservice.lasfilacaptura.length>0)" >
    <div class="col-12">

                            <form ngNativeValidate [formGroup]="formacaptura" id="formacapturaid">

                                <div class="form-group row"   >
                                    <label  class="col-sm-2 col-form-label col-form-label-sm">
                                        Estatus previo</label>                        
                                    <label  class="col-sm-4 col-form-label col-form-label-sm">
                                            {{  dataservice.lasfilacaptura[0].descripcion }}</label>                                                                
                                </div>
                               <div class="form-group row"   >
                                        <label  class="col-sm-2 col-form-label col-form-label-sm">
                                            Respuesta</label>                        
                                            <select  [(ngModel)]="estatussalud" name="estatussalud" class="form-control col-md-4" 
                                            required formControlName="estatussalud" >
                                                <option *ngFor="let rol3 of sercli.catresp" [value]="rol3.clave">
                                                    {{ rol3.descripcion }} 
                                                </option>                                             
                                            </select>
                                </div>

                            <div class="form-group row"   >
                                <label  class="col-sm-2 col-form-label col-form-label-sm">
                                    Fecha</label>                        
                                    <input type="date"   [(ngModel)]="estatusfecha" class="form-control col-md-2" 
                                    formControlName="estatusfecha"   
                                    name="estatusfecha" 
                                    
                                    required >
                            </div>

 
                                    <div class="form-group row"   *ngIf="estatussalud ==  '15'" >
                                        <label  class="col-sm-2 col-form-label col-form-label-sm">
                                            Recibio</label>                        
                                        <input class="form-control col-md-4" [(ngModel)]="textrecibe"  name="textrecibe" formControlName="textrecibe"
                                         maxlength="250"> 
                                    </div>
                                    <div   *ngIf="formacaptura.controls['textrecibe'].invalid  " class="alert-danger">
                                        <div *ngIf="formacaptura.controls['textrecibe'].errors.pattern">
                                            sin caracteres extraños
                                        </div>
                                    </div>

                       

                                    <div class="form-group row"   >
                                        <label  class="col-sm-2 col-form-label col-form-label-sm">
                                            Observación</label>                        
                                        <textarea class="form-control col-md-4" [(ngModel)]="textcomen"  name="textcomen" formControlName="textcomen"
                                        rows="3" maxlength="250"></textarea>
                                    </div>
                                    <div   *ngIf="formacaptura.controls['textcomen'].invalid  " class="alert-danger">
                                        <div *ngIf="formacaptura.controls['textcomen'].errors.pattern">
                                            sin caracteres extraños
                                        </div>
                                    </div>


                                    <div class="row">
                                        <div class="col-md-3 mb-3">
                                           
                                        </div>
                                        <div class="col-md-3 mb-1">      
                                     <button type="button" [disabled]="!formacaptura.valid"  class="btn btn-primary btn-block" (click)="Procesarsalud()">Procesar</button>
                                     </div>
                                    </div>
                                </form>
    </div>
</div>
