import { Component, OnInit } from '@angular/core';
import { ModalSelectuserService } from './modal-selectuser.service';
import { ServicedataService } from '../../services/apirest/servicedata.service';


@Component({
  selector: 'app-modal-selectuser',
  templateUrl: './modal-selectuser.component.html',
  styles: []
})
export class ModalSelectuserComponent implements OnInit {

  constructor(public servselectu: ModalSelectuserService,
              public servData: ServicedataService) { }

  ngOnInit() {
  }

}
