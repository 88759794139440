import { Component, OnInit } from '@angular/core';
import { ActivatedRoute  } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ServicedataService } from './../../services/service.index';
import Swal from 'sweetalert2';
import {Camposingreso } from './../../interfaces/campos';
import { AuthService } from '../../services/apirest/auth.service';
import * as moment from 'moment';

@Component({
  selector: 'app-asignar',
  templateUrl: './asignar.component.html',
  styleUrls: ['./asignar.component.css']
})
export class AsignarComponent implements OnInit {
  forma: FormGroup;
  Ingresomasivo: Array<Camposingreso> = [];
  display = 'none';
  filterPost = '';
  estatusactu = '';
  estatusfecha = '';
  formaactualiza: FormGroup;
  constructor(public activatedRoute: ActivatedRoute,
              public dataservice: ServicedataService,
              public sercli: AuthService
              ) {
                this.forma = new FormGroup({
                  ffactu: new FormControl( null , [Validators.required])
                }  );

                this.formaactualiza = new FormGroup({
                  estatusactu: new FormControl( null , [Validators.required ]),
                  estatusfecha: new FormControl( null , [Validators.required ])
                        });


              }

  ngOnInit() {

  }


  ingresar(termino: string) {
    if (this.forma.invalid) { return; }

    for (const item of this.Ingresomasivo) {
        if (termino === item.folio  ) {
          Swal.fire({
            text: 'ya existe en la rejilla, verifica',
            icon: 'warning',
            confirmButtonText: 'Cerrar'
          }).then((result) => {
            return;
          });
          return;
        }
      }



    const customObj = new Camposingreso();
    customObj.folio = termino;
    customObj.estatus = null;
    customObj.fecha = null;
    customObj.descripcion = null;
    this.Ingresomasivo.push(customObj);
    this.forma.get('ffactu').setValue(null);
  }




  btnasig() {
    if (this.dataservice.ResultINgresoBlosom.length > 0) {
      Swal.fire({
        text: 'Limpia la rejilla del resultado de actualización',
        icon: 'warning',
        confirmButtonText: 'Cerrar'
      }).then((result) => {
        return;
      });
      return;
    }
    this.display = 'block';
   }

   cerrarModal() {
    this.display = 'none';
    this.formaactualiza.reset();
  }



   resetgrid() {
    this.Ingresomasivo = [];
    this.dataservice.ResultINgresoBlosom = [];
   }


   async Procesarsalud() {

    const fecha1 = moment();
    const fecha2 = moment(this.formaactualiza.value.estatusfecha);



    this.display = 'none';
    if (fecha2 > fecha1  ) {
      Swal.fire({
        text: String('Verifica la fecha, no puede ser mayor'),
        icon: 'warning',
        confirmButtonText: 'Cerrar'
      }).then((result) => {
        this.display = 'block';
      });
      return;
    }

    const confirmar = await Swal.fire({
      title: 'Actualizar',
      text:  this.Ingresomasivo.length + ' registros ',
      icon: 'warning',
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      showConfirmButton: true,
      showCancelButton: true
    });

    if (confirmar.dismiss === Swal.DismissReason.cancel) {
      this.display = 'block';

      return;
    }

    const folios: string [] = [];

    for (const item of this.Ingresomasivo) {
        folios.push(item.folio);
    }

    const valido = await this.dataservice.asigmensa(folios.join(','),
                                                    this.formaactualiza.value.estatusfecha,
                                                    this.formaactualiza.value.estatusactu );

    if (!valido) {
      return;
    }
    // console.log(codigos.join( ',' ));
    this.Ingresomasivo = this.dataservice.ResultINgresoBlosom;
    this.formaactualiza.reset();

   }




}
