// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // sitiores: 'https://gestor.gestraro.com/rest/index.php',
  // sitioweb: 'https://gestor.gestraro.com/',
  sitiores: 'https://gestor.operacionpibelogistic.com/rest/index.php',
  sitioweb: 'https://gestor.operacionpibelogistic.com/',
  version: '1.0.1',
  apikeymaps: 'pk.eyJ1IjoidXNlcm1hcHNpbWFuIiwiYSI6ImNrZ25wcHhnajAzbXYyenBnMnYycWpoaDgifQ.QUqL0oSEYLu_rAbCggRSmg',
  defaultpass: 'G3estor%&' 

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
