<div class="row animated fadeIn">
    <div class="col-12">
        <div class="card">            
            <div class="card-body text-center">
                <div class="row">
                    <div class="col">

                        <form ngNativeValidate [formGroup]="forma"   id="loginformCaptu" >
                            <div class="form-row align-items-center">
 
                                <div class="col-8"    >   
                                    <input type="text"   
                                           formControlName="ffactu" 
                                           #input (keyup.enter)="ingresar(input.value)"    
                                           name="ffactu" 
                                           placeholder="Captura folio..."
                                           class="form-control" 
                                           id="f1"  
                                           required >
                                </div>
                                <div class="col-4">
                                    <label >Registros: {{ Ingresomasivo.length }}</label>
                                </div>                    
                                
                            </div>
                        </form>   
                    </div>
                    <div class="col">
                        <div class="col-auto" *ngIf="Ingresomasivo.length>0">
                            <button id="buttonbc2"
                            class="btn btn-primary mb-2 align-self-end" 
                            (click)="btnasig()"
                            >Actualizar</button> 
                        </div>
                    </div>
                    <div class="col">
                        <button id="buttonbc"
                        class="btn btn-primary mb-2 align-self-end" 
                        (click)="resetgrid()"
                        >Limpiar rejilla</button>    
                    </div>
                </div>
            </div> 
        </div>       
    </div>    
</div>
 
<table  class="table table-striped table-bordered" >
             
    <thead class="thead-light">
    
    <tr>    
        
            <th scope="col">Folio</th>
            <th scope="col">EstatusPrevio</th>            
            <th scope="col">Fecha</th>
            <th scope="col">EstatusFinal</th>
      </tr>
    </thead>
                <tbody> 
                        <!-- si marca errro add import { CommonModule } from '@angular/common'; -->
                        <tr *ngFor="let item of Ingresomasivo">
                            <td>
                                {{ item.folio}}
                            </td>
                          <td>
                              {{ item.estatus }}
                          </td>                         
                          <td>
                              {{ item.fecha}}
                          </td>
                          <td>
                              {{ item.descripcion}}
                          </td>      
                      </tr>  
                </tbody>
            </table>


            <div class="fondo-negro animated fadeIn" [ngStyle]="{'display':display}">
                <div class="modal"   tabindex="-1" role="dialog"   style="display:block;" role="dialog">
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                            <strong>Actualizar</strong>
                          </div>
                        <div class="modal-body">
 
                            <form ngNativeValidate [formGroup]="formaactualiza" id="formaactualiza">
                                <div class="form-group row"   >
                                    <label  class="col-md-3 col-form-label col-form-label-sm">
                                        Respuesta</label>                        
                                        <select  [(ngModel)]="estatusactu" name="estatusactu" class="form-control col-md-8" 
                                        required formControlName="estatusactu" >
                                            <option *ngFor="let rol3 of sercli.catresp" [value]="rol3.clave">
                                                {{ rol3.descripcion }} 
                                            </option>                                             
                                        </select>
                                </div>

                                <div class="form-group row"   >
                                    <label  class="col-md-3 col-form-label col-form-label-sm">
                                        Fecha</label>                        
                                        <input type="date"   [(ngModel)]="estatusfecha" class="form-control col-md-8" 
                                        formControlName="estatusfecha"   
                                        name="estatusfecha" 
                                        
                                        required >  
                                </div>

                               
                            </form>

                            
                        </div>
                        <div class="modal-footer">
                            <button type="button" [disabled]="!formaactualiza.valid"  class="btn btn-primary btn-block" (click)="Procesarsalud()">Procesar</button>
                            <button type="button" class="btn btn-primary" (click)="cerrarModal()" >Cerrar</button>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>


