import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators  } from '@angular/forms';
import { ServicedataService , AuthService} from './../../services/service.index';
import Swal from 'sweetalert2';
// import { Crespuestas, Catrespuestas } from '../../model/usuario.model';
import * as moment from 'moment';


@Component({
  selector: 'app-ingreso',
  templateUrl: './ingreso.component.html',
  styleUrls: ['./ingreso.component.css']
})
export class IngresoComponent implements OnInit {
  txtrefcorresp = this.dataservice.foliocaptucorresp ;
  formacorresp: FormGroup;
  estatussalud = '8';
  textcomen = '';
  entrega = '';
  Sinexito = '';
  textrecibe = '';
  estatusfecha = '';
  lositem = '';
  constructor(public activatedRoute: ActivatedRoute,
              public dataservice: ServicedataService,
              public sercli: AuthService,
              private router: Router  ) {

                this.formacorresp = new FormGroup({
                  frefcorresp: new  FormControl( null , [
                    Validators.minLength(5), Validators.pattern('[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9 ,.#]*')])
                   }  );
   }

  ngOnInit() {
  }

  async onClickdetalle2() {
    if (this.formacorresp.invalid) { return; }

    this.dataservice.foliocaptucorresp = this.formacorresp.value.frefcorresp;

    Swal.fire({
      title: 'Espere',
      allowOutsideClick: false,
      icon: 'info'
    });
    Swal.showLoading();

    const valido = await  this.dataservice.seguimientobuscar(this.dataservice.foliocaptucorresp );
    Swal.close();

    switch ( valido ) {
      case true:
        if (this.dataservice.lasfilacaptura[0].macrodescripcion.toLocaleLowerCase() === 'entregada' ) {
          Swal.fire({
            text: 'Entregada, imposible modificar',
            icon: 'warning',
            confirmButtonText: 'Cerrar'
          }).then((result) => {
          });
          this.dataservice.lasfilacaptura = [];
        } else {
          this.updatecorresp();
        }
        break;
      case 'No hay datos':
        Swal.fire({
          text: 'No hay coincidencias',
          icon: 'warning',
          confirmButtonText: 'Cerrar'
        }).then((result) => {
        });
        break;
      default:
          Swal.fire({
            text: String(valido),
            icon: 'warning',
            confirmButtonText: 'Cerrar'
          }).then((result) => {
          });
          break;
    }
  }

  async updatecorresp() {
    let lositemsupdate: string;
    lositemsupdate  =  this.dataservice.lasfilacaptura[0].id + '|' +
      '|' +
      '|' +
      '8|' +
      '|' +
      this.dataservice.lasfilacaptura[0].folio  + '|' +
      this.dataservice.lasfilacaptura[0].remitente  + '|' +
      this.dataservice.lasfilacaptura[0].destinatario + '|' +
      this.dataservice.lasfilacaptura[0].confirmaremail  + '|' +
      this.dataservice.lasfilacaptura[0].correodestinatario  + '|' +
      this.estatussalud ;

    Swal.fire({
        allowOutsideClick: false,
        icon: 'info',
        text: 'Espere por favor...'
      });
    Swal.showLoading();
    const valido = await this.dataservice.seguimientoupdate( lositemsupdate  );
    Swal.close();
    if (!valido) {
        return;
      }
    this.estatussalud = '8';
    this.dataservice.lasfilacaptura = [];
    this.dataservice.foliocaptucorresp = '';
    this.formacorresp.reset();
  }



}
