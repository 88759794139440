export class Campos {
    id: string;
    folio: string;
    remitente: string;
    destinatario: string;
    fsolicitud: string;
    referencia: string;
    comentarios: string;
    resp: string;
    descripcion: string;
    calle: string;
    col: string;
    delmun: string;
    edo: string;
    cp: string;
    recibio: string;
    frespuesta: string;
    observacion: string;
    recepCorresp: string;
    calleR: string;
    ColR: string;
    DelR: string;
    edoR: string;
    CpR: string;
    firma: string;
}


export class Camposestatus {
    fecha: string;
    servicios: string;
    captura: string;
    correspondencia: string;
    entrega: string;
    entregaws: string;
    noentrega: string;
    cancelada: string;
    hallazgo: string;
    mismodia: string;
    dia1: string;
    dia2: string;
    dia3: string;
    dia4: string;
    dia5: string;
    dia5mas: string;
    promexito: string;

}

export class Camposingreso {
    folio?: string;
    estatus?: string;
    fecha?: string;
    descripcion?: string;
}

export class Campofile {
    folio?: string;
    fecha?: string;
    hora?: string;
    estatusfinal?: string;
    detalle?: string;
}

export class Camposingresocv {
    cv?: string;
    Prefijo?: string;
    Factura?: string;
    estatus?: string;
    idmensajero?: string;
    descripcion?: string;
}

export class Camposcaptura {
    id: string;
    folio: string;
    macrodescripcion: string;
    fsolicitud: string;
    confirmaremail: string;
    respuesta: string;
    descripcion: string;
    remitente: string;
    destinatario: string;
    correodestinatario: string;

}
